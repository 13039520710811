/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  useToast
} from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillTags } from 'react-icons/ai'
import { MdNewReleases } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { Input } from '../../components/Form/Input'
import { InputPhone2 } from '../../components/Form/InputPhone2'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalRegister } from '../../components/Modal/modalRegister'
import { CardContainer } from '../../components/molecules/CardContainer'
import { useAuth } from '../../hooks/auth'
import { EquipmentsData } from '../../hooks/equipments/useEquipments'
import { useModal } from '../../hooks/useModal'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { mask } from '../../utils/mask'
import { useLogic } from './logic'
import { TagsData, useService } from './service'

type FormData = {
  observation: string
}

type TagsViewData = TagsData[] | undefined

type ContactsData = {
  contact: string
  ddi_country: string
  id: string
  is_main: boolean
  type_of_contact: string
}

interface TagsModal {
  value: string
  label: string
}

interface State {
  id: string | undefined
  selectedDate: string
  today?: string
  client?: {
    value: number
    label: string
  }
}

interface Indication {
  id: number
  service: {
    id: number
    name: string
    observation: string
    is_active: boolean
    price: string
    days_to_come_back: number
    days_to_maturity: number
    protocol: string
    service_sessions: Array<{
      id: number
      duration: number
      time_to_be_there: number
      session_number: number
      service_id: number
    }>
    service_costs: unknown
    service_documents: unknown
    tag_service: {
      id: number
      name: string
      is_active: boolean
      observation: string
    }
  }
  service_name: string
  observation: string
  status: number
  indication_date: string | null
  specialist_name: string
  medical_record_finish_date: string
  updated_at: string
  updated_by_name: string | null
}

type Indications = Array<{
  label: string
  value: number
  idService: number
  nameService: string
}>

interface Sections {
  id: number
  session_number: number
  status: number
  description: string | null
  attendance_id: number
  service_id: number
  service_name: string
  total_session: number
}

interface SectionsSelect {
  value: number
  label: string
  serviceId: number
  serviceName: string
}

interface Equipments {
  value: number
  label: string
}

type TagsModalData = Array<TagsModal> | []

export const SchedulingCreate: FC = () => {
  let blockButton: boolean = false
  const { decodeToken } = useAuth()
  // hooks
  const { LocaleApp } = useModal()
  const history = useHistory()
  const toast = useToast()
  const services = useService()
  const logic = useLogic()
  // new Date default schedule
  const exactDay = new Date().getDate()
  const exactMonth = new Date().getMonth()
  const exactYear = new Date().getFullYear()
  const exactHours = new Date().getHours()
  const exactMinutes = new Date().getMinutes()
  const { dateScheduling } = useModal()
  const [t] = useTranslation('pageScheduling')
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  // modal state
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [numbers, setNumbers] = useState<ContactsData[]>([
    {
      contact: '',
      ddi_country:
        decodeToken?.clinic?.clinic_contacts[0]?.ddi_country || '+55',
      id: '',
      is_main: true,
      type_of_contact: 'telefone'
    }
  ])
  const [patientsTags, setPatientsTags] = useState<TagsModalData>([])
  const [observation, setObservation] = useState('')
  // tags for view
  const [tagsView, setTagsView] = useState<TagsViewData>()
  // loading tags view
  const [isLoadingTagsView, setIsLoadingTagsView] = useState(false)
  const { state }: any = history.location
  const [isSubmitting, setIsSubmitting] = useState(false)
  // indications state
  const [indicationsOptions, setIndicationsOptions] = useState<Indications>([])
  const [indicationsSelected, setIndicationsSelected] = useState<Indications>(
    []
  )
  const [sectionsOptions, setSectionsOptions] = useState<Sections[]>([])
  const [sectionsSelected, setSelectionsSelect] = useState<SectionsSelect[]>([])
  const [equipmentSelected, setEquipmentSelected] = useState<Equipments[]>([])

  useEffect(() => {
    if (state?.client) {
      logic.setClientSelect(state?.client || null)
    }

    return () => {}
  }, [])

  // logics complement back values or init values
  useEffect(() => {
    const data: State = state

    if (data?.selectedDate === undefined && !data?.today) {
      logic.setAttendanceDate(dateScheduling)
      return
    }

    if (data?.today) {
      logic.setAttendanceDate(data?.today)

      return
    }

    if (data?.selectedDate.length <= 10) {
      logic.setAttendanceDate(data.selectedDate)
    }

    if (data !== undefined) {
      logic.setClientSelect(state?.client || null)
    }

    if (data.selectedDate.length > 10) {
      const dateAttendance = data.selectedDate.substring(0, 10)
      const dateHours = data.selectedDate.substring(11, 16)

      logic.setAttendanceDate(dateAttendance)
      logic.setTimeToBeThere(dateHours)
    }
  }, [state])

  // logics complement back values or init values
  useEffect(() => {
    if (services.specialists.length === 0) {
      return
    }

    const data: State = state

    if (data?.id) {
      services.specialists.forEach((specialist) => {
        if (data.id === String(specialist.id)) {
          logic.setSpecialistSelect({
            value: specialist.id,
            label: specialist.user.name
          })
        }
      })
    }
  }, [services.specialists])

  // get spot default
  useEffect(() => {
    if (services.spots.length === 0) {
      return
    }

    const value = services.spots[0].id
    const label = services.spots[0].name

    const spot = {
      value,
      label
    }

    logic.setSpotSelect(spot)
  }, [services.spots])

  useEffect(() => {
    if (logic.clientSelect !== null) {
      apiAuth
        .get(`clinics/patients/${logic?.clientSelect?.value}/indications/`, {
          params: {
            status: 1
          }
        })
        .then((response) => {
          if (response?.data?.count > 0) {
            const arrayOptionsFormatted: Indications =
              response?.data?.results?.map((ind: Indication) => {
                return {
                  label: `${format(
                    new Date(
                      Number(
                        ind?.indication_date?.substring(0, 4) ||
                          ind?.medical_record_finish_date?.substring(0, 4)
                      ),
                      Number(
                        ind?.indication_date?.substring(5, 7) ||
                          ind?.medical_record_finish_date?.substring(5, 7)
                      ) - 1,
                      Number(
                        ind?.indication_date?.substring(8, 10) ||
                          ind?.medical_record_finish_date?.substring(8, 10)
                      )
                    ),
                    'dd/MM/yyyy',
                    {
                      locale: LocaleApp
                    }
                  )} - ${ind?.specialist_name} - ${ind?.service_name} - ${
                    ind?.observation !== null ? ind?.observation : ''
                  }`,
                  value: ind?.id,
                  idService: ind?.service?.id,
                  nameService: ind?.service_name
                }
              })

            setIndicationsOptions(arrayOptionsFormatted)
          } else {
            setIndicationsOptions([])
          }

          setIndicationsSelected([])
        })

      apiAuth
        .get<Sections[]>(
          `clinics/patients/${logic.clientSelect?.value}/service-sessions/`,
          {
            params: {
              status: '1',
              min_session: '1'
            }
          }
        )
        .then(({ data }) => {
          setSectionsOptions(data)
        })
    }

    return () => {}
  }, [logic.clientSelect])

  // format services by id array for body
  function formatServices() {
    if (logic.servicesSelects === null) {
      return []
    }

    const ArrayId = logic.servicesSelects.map((service) => {
      return {
        service_id: service.value,
        service_name: service.label
      }
    })

    return [...ArrayId]
  }

  // format sections,
  function formatSections() {
    const arraySections = sectionsSelected?.map((section) => {
      return {
        id: section?.value
      }
    })

    return arraySections
  }

  // return array indications

  function formatIndications() {
    const ArrayInd = indicationsSelected?.map((ind) => {
      return {
        id: ind?.value
      }
    })

    return ArrayInd
  }

  function formatEquipments() {
    const ArrayEqp = equipmentSelected?.map((eqp) => {
      return {
        id: eqp?.value
      }
    })

    return ArrayEqp
  }

  // validation hours for body
  function validationHours() {
    let validationReturn = false
    const { timeToBeThere, startHours, endHours } = logic

    const hoursValidate = [timeToBeThere, startHours, endHours]
    hoursValidate.forEach((hour) => {
      if (hour.length !== 5) {
        validationReturn = true
      }

      if (hour.length === 5) {
        const hoursRemask = remaskCaractersAll(hour)
        const minutes = hoursRemask.substring(2, 4)

        if (Number(hoursRemask) > 2359) {
          validationReturn = true
        }

        if (Number(minutes) > 59) {
          validationReturn = true
        }
      }
    })

    return validationReturn
  }

  // validation date and hours pass
  function validationDateAndHoursPass() {
    let validationReturn = false
    const day = Number(logic.attendanceDate.substring(8, 10))
    const month = Number(logic.attendanceDate.substring(5, 7)) - 1
    const year = Number(logic.attendanceDate.substring(0, 4))
    const hours = Number(
      remaskCaractersAll(logic.timeToBeThere).substring(0, 2)
    )
    const minutes = Number(
      remaskCaractersAll(logic.timeToBeThere).substring(2, 4)
    )

    if (year < exactYear) {
      validationReturn = true
    }

    if (year === exactYear) {
      if (month < exactMonth) {
        validationReturn = true
      } else if (month === exactMonth) {
        if (day < exactDay) {
          validationReturn = true
        } else if (day === exactDay) {
          if (hours < exactHours) {
            validationReturn = true
          } else if (hours === exactHours) {
            // minutes default tolerance 10 minutes
            if (minutes < exactMinutes - 10) {
              validationReturn = true
            }
          }
        }
      }
    }

    return validationReturn
  }

  // validation hours start specialist EndAttendance
  function validationHoursArrivedTimeSpecialistEndAttendance() {
    let isError = false

    const start = Number(remaskCaractersAll(logic.timeToBeThere))
    const attendance = Number(remaskCaractersAll(logic.startHours))
    const end = Number(remaskCaractersAll(logic.endHours))

    if (start > attendance) {
      isError = true
      return isError
    }

    if (attendance > end) {
      isError = true
      return isError
    }

    return isError
  }

  // validation exist item for scheduling
  function validationExistItemScheduling() {
    let isNotValid = false

    if (
      logic.servicesSelects?.length === 0 &&
      sectionsSelected?.length === 0 &&
      indicationsSelected?.length === 0
    ) {
      isNotValid = true
    }

    return isNotValid
  }

  const handleCreateScheduling: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    setIsSubmitting(true)
    event?.preventDefault()

    // validates
    if (logic.specialistSelect === null) {
      toast({
        title: 'Aconteceu um error!',
        description: `O especialista é obrigatório para agendar.`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    if (logic.spotSelect === null) {
      toast({
        title: 'Aconteceu um error!',
        description: `O Local de atendimento é obrigatório para agendar.`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    if (logic.clientSelect === null) {
      toast({
        title: 'Aconteceu um error!',
        description: `O Cliente é obrigatório para agendar.`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const returnExistScheduling = validationExistItemScheduling()

    if (returnExistScheduling === true) {
      toast({
        title: 'Aconteceu um error!',
        description: `Selecione ao menos um item para agendamento`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const returnValidationHours = validationHours()

    if (returnValidationHours === true) {
      toast({
        title: 'Aconteceu um error!',
        description: `Revise os horários, para o agendamento!`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const returnValidationDateAndHours = validationDateAndHoursPass()

    if (returnValidationDateAndHours === true) {
      toast({
        title: 'Aconteceu um error!',
        description: `A data e horário de agendamento não pode ser retroativa!`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const returnValidation = validationHoursArrivedTimeSpecialistEndAttendance()

    if (returnValidation === true) {
      toast({
        title: 'Revise seus horários',
        description: `O horário de agenda,
        não pode ser maior que o de atendimento
        e previsão de finalização não pode
        ser menor que a previsão de atendimento`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }
    // end validations

    const attendance_items = formatServices()
    const sessions = formatSections()
    const indications = formatIndications()
    const equipments = formatEquipments()

    const payload = {
      observation: values.observation,
      scheduled_arrival_time: `${logic.attendanceDate}T${logic.timeToBeThere}`,
      scheduled_initial_time: `${logic.attendanceDate}T${logic.startHours}`,
      scheduled_final_time: `${logic.attendanceDate}T${logic.endHours}`,
      patient_id: logic.clientSelect?.value,
      attendance_items,
      sessions,
      indications,
      professional_id: logic.specialistSelect?.value,
      attendance_spot: { id: logic.spotSelect?.value },
      sessions_not_performed: [],
      equipments
    }

    setIsSubmitting(false)
    await services.handlerCreateNewAttendance(payload)
    blockButton = true
  }

  // modal functions
  const onClose = () => {
    setIsOpen(false)
  }

  function handleChangeInputNumbers(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const values = [...numbers]

    if (values[index].ddi_country === '+55') {
      values[index].contact = mask(e.target.value, '(99) 99999-9999')
    } else {
      values[index].contact = mask(e.target.value, '9999999999999999')
    }

    setNumbers(values)
  }

  // format tags
  function formatTags() {
    if (patientsTags.length > 0) {
      const serviceTags = patientsTags.map((tag) => {
        return Number(tag.value)
      })

      return serviceTags
    }

    return patientsTags
  }

  function formatNumbers() {
    if (numbers[0].contact === '') {
      return undefined
    }

    const telephones = numbers.map((item, index) => {
      return {
        ddi_country: item.ddi_country === '' ? null : item.ddi_country,
        number: item.contact === '' ? null : item.contact,
        index,
        description: null
      }
    })

    return telephones
  }

  // create new client modal
  const handlerCreateNewClient = async () => {
    if (name === '') {
      toast({
        title: 'Aconteceu um error!',
        description: `O nome do cliente é obrigatório`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      return
    }

    const payloadTelephones = formatNumbers()

    const patient_tags = formatTags()

    const arrayName = name.split(' ')
    const social_name = arrayName[0]

    const payload = {
      name,
      social_name,
      observation,
      patient_tags,
      document: null
    }

    try {
      const { data } = await apiAuth.post(`clinics/patients/`, payload)

      const client = {
        value: Number(data.id),
        label: String(data.name)
      }

      await apiAuth.post(
        `clinics/patients/${data.id}/telephones/`,
        payloadTelephones
      )

      if (data.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'vamos atualizar a lista para você.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        services.UpdateClients()

        setTimeout(() => {
          onClose()
          setNumbers([
            {
              contact: '',
              ddi_country: '+55',
              id: '',
              is_main: true,
              type_of_contact: 'telefone'
            }
          ])
          setName('')
          setObservation('')
          setPatientsTags([])
          logic.setClientSelect(client)
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Aconteceu um error!',
        description: `${error.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // view Tags Values
  const UpdateTagsHandleChangeClient = () => {
    setIsLoadingTagsView(true)

    const { infoClient } = logic
    const allTags = services.tags
    const tagsClient = infoClient?.patient_tags

    const tagsViewValues: any = tagsClient?.map((id) =>
      allTags?.find((tag) => tag.id === id)
    )

    setTagsView(tagsViewValues)
    setIsLoadingTagsView(false)
  }

  // calculate new hours prevision Start and End
  const handleActionButtonCalculateHours = async () => {
    const validationSelectItem = validationExistItemScheduling()

    // services null
    if (validationSelectItem === true) {
      toast({
        status: 'warning',
        position: 'top',
        description:
          'Para calcular, selecione pelo menos 1 item para agendamento.',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })

      return
    }

    // invalid length hours
    if (logic.timeToBeThere.length !== 5) {
      toast({
        status: 'warning',
        position: 'top',
        description: 'A Hora de chegada não foi informada corretamente!',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })

      return
    }

    const arrival_time = `${logic?.attendanceDate}T${logic?.timeToBeThere}`

    const services_ids =
      logic?.servicesSelects?.map((ser) => {
        return ser?.value
      }) || []

    const sessions_ids = sectionsSelected?.map((s) => {
      return s?.value
    })

    const indications_ids = indicationsSelected?.map((i) => {
      return i?.value
    })

    try {
      const { data } = await apiAuth.post<{
        specialist_time: string
        prevision_finish: string
      }>(`clinics/attendances/calculate-times/`, {
        arrival_time,
        services_ids,
        sessions_ids,
        indications_ids
      })

      if (data?.specialist_time) {
        logic.setStartHours(data.specialist_time?.substring(11, 16))
      }

      if (data?.prevision_finish) {
        logic.setEndHours(data.prevision_finish?.substring(11, 16))
      }
    } catch (error: any) {
      toast({
        status: 'warning',
        position: 'top',
        description: 'Não foi possível calcular horários',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })
    }
  }

  // view Tags Values Effect
  useEffect(() => {
    UpdateTagsHandleChangeClient()
  }, [logic.infoClient])

  // clear Hours case change Services
  useEffect(() => {
    logic.setStartHours('')
    logic.setEndHours('')
  }, [logic.servicesSelects])

  // get values services for calculate hours for input
  useEffect(() => {
    if (logic.servicesSelects !== null) {
      if (logic.servicesSelects?.length > 0) {
        const { servicesSelects } = logic
        const defaultServices = services.services
        const valuesServices = servicesSelects.map((service) =>
          defaultServices.find((ser) => service.value === ser.id)
        )

        logic.setSerInfo(valuesServices)
      } else {
        logic.setSerInfo(null)
      }
    }
  }, [logic.servicesSelects])

  return (
    <>
      <LayoutDefault
        urlBack="/scheduling"
        title={t('create.title')}
        titleBack="Agenda"
        onSubmit={handleSubmit(handleCreateScheduling)}
      >
        <Stack justify="space-evenly" spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              {services.isLoadingSpecialists ? (
                <Flex w="60%" align="center" justify="center">
                  <Spinner color="blue" m="2" />
                </Flex>
              ) : (
                <Box w="60%">
                  <SelectComponent
                    title={t('create.container1.i1')}
                    isRequired
                    placeholder={t('create.container1.placeholder.specialist')}
                    options={services.specialistsEdit.map((specialist) => {
                      return {
                        value: specialist.user.id,
                        label: specialist.social_name
                      }
                    })}
                    onInputChange={(value: string) => {
                      console.log(value)
                    }}
                    value={logic.specialistSelect}
                    onChange={(event: any) =>
                      logic.handleSelectSpecialist(event)
                    }
                    name="specialist"
                  />
                </Box>
              )}
              {services.isLoadingSpots ? (
                <Flex w="40%" align="center" justify="center">
                  <Spinner color="blue" m="2" />
                </Flex>
              ) : (
                <Box w="40%">
                  <SelectComponent
                    title={t('create.container1.i2')}
                    isRequired
                    placeholder={t('create.container1.placeholder.spot')}
                    options={services.spots.map((spot) => {
                      return {
                        value: spot.id,
                        label: spot.name
                      }
                    })}
                    value={logic.spotSelect}
                    onChange={(event: any) => logic.handleSelectSpot(event)}
                    name="spot"
                  />
                </Box>
              )}
            </Stack>
            <Box w="100%">
              <Text my="2" fontSize="sm">
                {t('create.container1.i3')}
              </Text>
              <Textarea
                {...register('observation')}
                placeholder={t('create.container1.placeholder.observation')}
              />
            </Box>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            {services.isLoadingClients ? (
              <>
                <Flex w="100%" align="center" justify="center">
                  <Spinner color="blue" m="2" />
                </Flex>
              </>
            ) : (
              <>
                <Stack w="100%" direction="row" my="2" spacing="6">
                  <Box>
                    <Avatar size="lg" src={logic.clientAvatar || ''} />
                  </Box>
                  <Box w="50%">
                    <SelectComponentAsync
                      title={t('create.container2.i1')}
                      isRequired
                      placeholder={t('create.container2.placeholder.client')}
                      loadOptions={(values: string) =>
                        services.getClients(values)
                      }
                      value={logic.clientSelect}
                      onChange={(e: any) => logic.handleSelectClient(e)}
                      name="client"
                    />
                  </Box>
                  <Box w="40%">
                    <Input
                      label={t('create.container2.i2')}
                      isDisabled
                      value={logic.clientPhone}
                      placeholder={t('create.container2.placeholder.phone')}
                      name="phone"
                    />
                  </Box>
                </Stack>
                <Flex my="2" w="100%">
                  <Box w="50%">
                    <Text fontSize="sm">
                      {t('create.container2.i3')}
                      <Icon mx="2" size={22} as={AiFillTags} />
                    </Text>
                    <SimpleGrid spacing="6px">
                      {logic.clientSelect === null ? (
                        <>
                          <Box p="2">
                            <Text fontSize="sm">
                              {t('create.container2.clientNull')}
                            </Text>
                          </Box>
                        </>
                      ) : (
                        <>
                          {isLoadingTagsView ? (
                            <Flex w="100%" align="center" justify="center">
                              <Spinner color="blue" m="2" />
                            </Flex>
                          ) : (
                            <>
                              <Box p="2">
                                {/* map Array tags */}
                                {tagsView !== undefined &&
                                  tagsView.length > 0 &&
                                  tagsView?.map((tagCard) => {
                                    return (
                                      <Tag
                                        key={tagCard?.id}
                                        variant="solid"
                                        mx="6px"
                                        colorScheme="blue"
                                      >
                                        {tagCard?.name}
                                      </Tag>
                                    )
                                  })}
                                {tagsView !== undefined &&
                                  tagsView.length === 0 && (
                                    <Text fontSize="sm" fontWeight="bold">
                                      {t('create.container2.clientTagsNull')}
                                    </Text>
                                  )}
                              </Box>
                            </>
                          )}
                        </>
                      )}
                    </SimpleGrid>
                  </Box>
                  <Box w="50%">
                    <Text my="2" fontSize="sm">
                      {t('create.container2.i4')}
                      <Icon mx="2" size={22} as={MdNewReleases} />
                    </Text>
                    <Textarea
                      value={logic.clientObservation}
                      name="observation"
                      isDisabled
                    />
                  </Box>
                </Flex>
                <Box mt="5">
                  <Button
                    size="sm"
                    fontSize="sm"
                    bg="blue.300"
                    color="#fff"
                    onClick={() => setIsOpen(true)}
                  >
                    {t('create.container2.btnLabel')}
                  </Button>
                </Box>
              </>
            )}
          </CardContainer>

          <CardContainer title={t('create.container4.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <>
                <SelectComponent
                  title={t('create.container4.i1')}
                  placeholder={t('create.container4.placeholder.sections')}
                  options={sectionsOptions?.map((sec) => {
                    return {
                      value: sec?.id || sec?.attendance_id,
                      label: `${sec?.service_name} - ${sec?.session_number}/${
                        sec?.total_session
                      } - ${sec?.description || ''}`,
                      serviceId: sec?.service_id,
                      serviceName: sec?.service_name
                    }
                  })}
                  isMulti
                  value={sectionsSelected}
                  onChange={(event: SectionsSelect[]) => {
                    setSelectionsSelect(event)
                  }}
                  name="sections"
                />
              </>
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container5.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <>
                <SelectComponent
                  title={t('create.container5.i1')}
                  placeholder={t('create.container5.placeholder.indications')}
                  options={indicationsOptions}
                  value={indicationsSelected}
                  onChange={(event: Indications) => {
                    setIndicationsSelected(event)
                  }}
                  isMulti
                  name="indications"
                />
              </>
            </Stack>
          </CardContainer>

          <CardContainer
            title={t('create.container3.title')}
            isLoading={services.isLoadingService}
          >
            <Stack w="100%" direction="row" my="2" spacing="6">
              {services.isLoadingService ? (
                <>
                  <Flex w="100%" align="center" justify="center">
                    <Spinner color="blue" m="2" />
                  </Flex>
                </>
              ) : (
                <>
                  <SelectComponent
                    title={t('create.container3.i1')}
                    isDisabled={state?.block_guide}
                    placeholder={t('create.container3.placeholder.service')}
                    options={services.services.map((service) => {
                      return {
                        value: service.id,
                        label: service.name
                      }
                    })}
                    value={logic.servicesSelects}
                    onChange={(event: any) => logic.handleSelectServices(event)}
                    isMulti
                    isLoading={services.isLoadingService}
                    name="services"
                  />
                </>
              )}
            </Stack>
          </CardContainer>

          {/* <CardContainer title={t('create.container7.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <>
                <SelectComponent
                  title={t('create.container7.i1')}
                  placeholder={t('create.container7.placeholder.equipments')}
                  options={services?.equipments?.map((equip) => {
                    return {
                      value: equip?.id,
                      label: equip?.name
                    }
                  })}
                  isMulti
                  value={equipmentSelected}
                  onChange={(event: Equipments[]) => {
                    setEquipmentSelected(event)
                  }}
                  name="sections"
                />
              </>
            </Stack>
          </CardContainer> */}

          <CardContainer title={t('create.container6.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <Box w="25%">
                <Input
                  label={t('create.container6.i1')}
                  isRequired
                  type="date"
                  value={logic.attendanceDate}
                  onChange={(event) =>
                    logic.setAttendanceDate(event.target.value)
                  }
                  name="date"
                />
              </Box>
              <Box w="25%">
                <TextFieldControlled
                  label={t('create.container6.i2')}
                  name="timeToBeThere"
                  isRequired
                  mask="time"
                  initialValue={logic.timeToBeThere}
                  onKeyUp={(event) => {
                    logic.handleInputHoursArrivedTime(event)
                  }}
                  placeholder="00:00"
                  textAlign="left"
                />
              </Box>
              <Box w="25%">
                <TextFieldControlled
                  label={t('create.container6.i3')}
                  name="serviceTime"
                  mask="time"
                  initialValue={logic.startHours}
                  onKeyUp={(event) => {
                    logic.handleInputHoursStartAttendanceTime(event)
                  }}
                  placeholder="00:00"
                  textAlign="left"
                />
              </Box>
              <Box w="25%">
                <TextFieldControlled
                  label={t('create.container6.i4')}
                  name="endHours"
                  mask="time"
                  initialValue={logic.endHours}
                  onKeyUp={(event) => {
                    logic.handleInputHoursEndAttendanceTime(event)
                  }}
                  placeholder="00:00"
                  textAlign="left"
                />
              </Box>
            </Stack>

            <Box mt="5">
              {/* hours default */}
              <Button
                size="sm"
                fontSize="sm"
                bg="blue.300"
                color="#fff"
                onClick={() => handleActionButtonCalculateHours()}
              >
                {t('create.container6.btnLabel')}
              </Button>
            </Box>
          </CardContainer>

          <Flex mt="4" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              isDisabled={blockButton}
              color="#fff"
              isLoading={formState.isSubmitting || isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>

          <Stack w="100%" my="2" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalRegister
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={onClose}
        handlerAction={handlerCreateNewClient}
      >
        <Stack w="100%" direction="row" p="2" spacing="6">
          <Box w="50%">
            <TextFieldControlled
              name="name"
              isRequired
              label={t('modal.name')}
              value={name}
              textAlign="left"
              onChange={(event) =>
                setName(event.currentTarget.value.toUpperCase())
              }
              placeholder={t('modal.name')}
            />
          </Box>
          <Box w="60%">
            <InputPhone2
              label={`${t('modal.phone')}`}
              defaultValues={{
                ddi: numbers[0].ddi_country
              }}
              type="text"
              size="md"
              name="number"
              getValues={(value) => {
                const values = [...numbers]
                values[0].ddi_country = value?.ddi_country
                setNumbers(values)
              }}
              value={numbers[0].contact}
              onChange={(e) => handleChangeInputNumbers(0, e)}
            />
          </Box>
        </Stack>
        {/* <Stack w="100%" direction="row" p="2" spacing="6">
          <Box w="100%">
            <SelectComponent
              title={t('modal.tags')}
              options={services.tags.map((tag) => {
                return {
                  value: tag.id,
                  label: tag.name
                }
              })}
              isMulti
              placeholder={t('modal.placeholder.tags')}
              values={patientsTags}
              onChange={(event: any) => handleSelectedTags(event)}
              name="tags"
              noOptionsText="Nenhuma tag encontrada"
            />
          </Box>
        </Stack> */}
        <Stack w="100%" direction="row" p="2" spacing="6">
          <Box w="100%">
            <Text my="2" fontSize="sm">
              {t('modal.observation')}
            </Text>
            <Textarea
              name="observation"
              value={observation}
              onChange={(event) => setObservation(event.currentTarget.value)}
              placeholder={t('modal.placeholder.observation')}
            />
          </Box>
        </Stack>
      </ModalRegister>
    </>
  )
}
