/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type SuppliersData = {
  id: string
  trading_name: string
  company_name: string
  document_number: string
}

export type ResponseGetSuppliers = {
  suppliers: SuppliersData[]
  totalCountOfPage: number
}

export const getSuppliers = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetSuppliers> => {
  try {
    const { data } = await apiAuth.get(`clinics/suppliers/`, {
      params: {
        page,
        keyword: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const suppliers: SuppliersData[] = data.results

    return {
      suppliers,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const suppliers: SuppliersData[] = []
    const totalCountOfPage = 0
    return {
      suppliers,
      totalCountOfPage
    }
  }
}

export function useSuppliers(
  page: number,
  functionLoading: Promise<ResponseGetSuppliers>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['suppliers', page, search, isActive], () => functionLoading)
}
