/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import { useCallback, useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { uniqueId } from 'lodash'
import filesize from 'filesize'
import { useTranslation } from 'react-i18next'
import { useToast } from '@chakra-ui/react'

import { RiAttachmentLine } from 'react-icons/ri'

import format from 'date-fns-tz/format'
import { apiAuth } from '../../../services/apiAuth'

import {
  Container,
  DropzoneStyle,
  ContentLeft,
  ContentDropzone,
  UploadedFile
} from './styles'
import { useAuth } from '../../../hooks/auth'

interface DropzoneProps {
  handleSetImg: any
  getImg?: any
  getDocumentData?: any
  idPost: string
  index: number
}

interface Documents {
  id: number
}

const DropZone = ({
  handleSetImg,
  getImg,
  idPost,
  index,
  getDocumentData
}: DropzoneProps) => {
  const { decodeToken } = useAuth()
  const toast = useToast()
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [imgUp, setImgUp] = useState<any>([] as any)
  const [loading, setLoading] = useState(false)

  const updateFile = useCallback((id, data) => {
    setUploadedFiles((state: any) =>
      state.map((file: any) => (file.id === id ? { ...file, ...data } : file))
    )
  }, [])

  const processUpload = (uploadedFile: any) => {
    if (uploadedFile.name === undefined) {
      return
    }

    // 1242880 1mg
    if (uploadedFile.size > 1242880 * 10) {
      return
    }

    setLoading(true)

    const data = new FormData()
    data.append('name', uploadedFile?.name?.split('.')?.shift())
    data.append(
      'date',
      format(new Date(), 'yyyy-MM-dd', {
        timeZone: decodeToken?.clinic?.timezone
      })
    )
    data.append('description', uploadedFile?.readbleSize)
    data.append('file', uploadedFile.file)

    apiAuth
      .post(`/clinics/gallery-cards/${idPost}/documents/`, data, {
        onUploadProgress: (progressEvent) => {
          const progress: number = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )

          updateFile(uploadedFiles.id, { progress })
        }
      })
      .then((res) => {
        const newData = { ...res.data, card_document: idPost }

        getDocumentData(index, newData)
        // message success
        if (res.data.id !== undefined) {
          toast({
            description: 'Documento enviado com sucesso!',
            isClosable: false,
            title: 'Arquivo enviado',
            status: 'success',
            position: 'top-right',
            duration: 3000
          })
        }
      })
      .catch((error) => {
        if (error) {
          toast({
            description: 'Falha ao enviar documento',
            isClosable: false,
            title: 'Ops! Falha',
            status: 'error',
            position: 'top-right',
            duration: 3000
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpload = (files: any) => {
    const propsUploaded = files.map((file: any) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readbleSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null
    }))

    getImg(propsUploaded)

    // setUploadedFiles(propsUploaded)
    setImgUp(propsUploaded)

    // // funcao que conecta ao servidor (passado em cada arquivo que foi feito upload)
    // propsUploaded.forEach(processUpload);
    // // propsUploaded.map((arquivo: any) => processUpload(arquivo));
  }

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    setImgUp(handleSetImg)
    processUpload(handleSetImg)
  }, [handleSetImg])

  const renderDragMessage = useCallback((isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <>
          <ContentLeft>
            <div>
              <RiAttachmentLine />
              <h4>
                Arraste e solte aqui ou <span>Carregue</span> o arquivo a anexar
              </h4>
            </div>
          </ContentLeft>
        </>
      )
    }

    if (isDragReject) {
      return (
        <ContentDropzone type="error">
          <h1>Arquivo não suportado</h1>
        </ContentDropzone>
      )
    }

    return (
      <ContentDropzone type="sucess">
        <h1>Solte os arquivos aqui</h1>
      </ContentDropzone>
    )
  }, [])

  return (
    <>
      <Dropzone onDropAccepted={(files) => handleUpload(files)}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <Container {...getRootProps()}>
            <DropzoneStyle>
              {' '}
              {/*  todas as propriedades de upload */}
              <input
                {...getInputProps()}
                disabled={imgUp[0]?.file !== undefined && true}
              />
              <>
                {loading ? (
                  <ContentLeft>
                    <p>Carregando...</p>
                  </ContentLeft>
                ) : (
                  <>
                    {imgUp.length > 0 ? (
                      <>
                        <UploadedFile>
                          {imgUp[0].name !== undefined ? (
                            <>
                              <div className="carregado">
                                <RiAttachmentLine />
                              </div>
                              <h4>{imgUp[0]?.name}</h4>
                              <h4
                                style={{
                                  fontSize: '14px',
                                  margin: '0 2rem'
                                }}
                              >
                                {imgUp[0]?.name}
                              </h4>
                            </>
                          ) : (
                            <h4 color="red">Error ao enviar documento</h4>
                          )}
                        </UploadedFile>
                        {imgUp[0].name !== undefined && (
                          <a href={imgUp[0].file} target="_blank">
                            Download
                          </a>
                        )}
                      </>
                    ) : (
                      <>{renderDragMessage(isDragActive, isDragReject)}</>
                    )}
                  </>
                )}
              </>
            </DropzoneStyle>
          </Container>
        )}
      </Dropzone>
    </>
  )
}

export default DropZone
