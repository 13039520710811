/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import React ,{ useCallback, useEffect, useState } from 'react'
import {  FiEdit } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LayoutList } from '../../../layouts/List';
import { NotFound } from '../../../components/molecules/NotFound';
import { Pagination } from '../../../components/PaginationNew';
import { useSuppliers, getSuppliers } from '../../../hooks/suppliers/useSuppliers';

import { useAuth } from '../../../hooks/auth';
import { PermissionComponent } from '../../../components/Permissions';
import { useQueries, useQuery } from 'react-query';


// debounce
let timer: any = null

export function Suppliers() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageSuppliers');
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7];

  const {
    data: getSuppliersData,
    refetch,
    isFetching,
    isLoading
  } = useQuery(['suppliers', currentPage, searchState, isActive], () =>
    getSuppliers(
      String(clinicId),
      currentPage,
      searchState,
      isActive,
    ),
  )

  useEffect(() => {
    refetch()
  }, [])

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer);

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
  }, [])

  const ActiveCheckboxCallbackValue = useCallback((
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsActive(event.target.checked)
  }, [])

  useEffect(() => {
    if (searchState !== '') {
      setCurrentPage(1)
    }
  }, [searchState])


  return (
    <LayoutList
      title={t('title')}
      urlNew="/financial/suppliers/create"
      refetch={refetch}
      isFetching={isFetching}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="ENTITIES"
    >
      <Box >
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <>
                {skeletonRepeat.map(skeleton => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getSuppliersData?.suppliers.map((sup, index: number) => (
                  <Tr key={sup.id} maxHeight="40px">
                    <Td>
                      <Text fontSize="sm">{sup.trading_name}</Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm">{sup.document_number}</Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm">{sup.company_name}</Text>
                    </Td>
                    <Td>
                      <PermissionComponent
                        spiCode="ENTITIES"
                        ipCode="EDIT"
                      >
                        <Link
                          to={{
                            pathname: `/financial/suppliers/update/${sup.id}`,
                            state: sup,
                          }}
                        >
                          <Tooltip
                            label="Editar"
                            aria-label="editar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box ml="1rem" w="24px">
                              <FiEdit
                                color="#9699B0"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </Link>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {(getSuppliersData?.totalCountOfPage === 0 && !isLoading)  && <NotFound />}
      </Box>
      {
      !(getSuppliersData?.totalCountOfPage === 0 && !isLoading) && (
          <Pagination
            totalCountOfRegisters={getSuppliersData?.totalCountOfPage || 0}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )
      }

    </LayoutList>
  );
}
