/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  MenuItem,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast
} from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { FcInfo } from 'react-icons/fc'
import { FiEdit } from 'react-icons/fi'
import { Input } from '../../components/Form/Input'
import { Select } from '../../components/Form/Select'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { PermissionComponent } from '../../components/Permissions'
import { useAuth } from '../../hooks/auth'
import {
  getConciliation,
  useConciliation
} from '../../hooks/conciliation/useConciliation'
import { useModal } from '../../hooks/useModal'
import { WaysData } from '../../hooks/waysReceives/useWays'
import { LayoutBlank } from '../../layouts/blank'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'

// debounce
let timer: any = null

export const Conciliation: FC = () => {
  const [t] = useTranslation('pageBillsToReceive')
  const { currency } = useAuth()
  const toast = useToast()
  const {
    isOpenConciliation,
    handlerCloseModal,
    endAtConciliation,
    statusConciliation,
    startAtConciliation,
    statusReceiveConciliation,
    endAtScheduleConciliation,
    startAtScheduleConciliation,
    waysParam,
    handlerApplyFiltersConciliation,
    handlerRemoveFiltersConciliation
  } = useModal()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchState, setSearchState] = useState('')
  const [checkedItems, setCheckedItems] = useState<Array<boolean>>([])
  const allChecked = checkedItems?.every(Boolean)
  const isIndeterminate = checkedItems?.some(Boolean) && !allChecked
  const [idItem, setIdItem] = useState(0)
  const [status, setStatus] = useState(1)
  const [isChange, setChange] = useState(false)
  // wayToReceive states
  const [selectWaysToReceives, setSelectWaysToReceives] = useState<
    WaysData[] | []
  >([])
  // modal edit
  const [isOpen, setIsOpen] = useState(false)
  const [indexEdit, setIndexEdit] = useState(0)
  const [dueDate, setDueDate] = useState('')
  const [taxAbsolute, setTaxAbsolute] = useState('')
  const [taxPercent, setTaxPercent] = useState('')
  const [interestAbsolute, setInterestAbsolute] = useState('')
  const [interestPercent, setInterestPercent] = useState('')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const statusOptionsDefaultConciliation = [
    { value: 1, label: `${t('pageConciliation.status.1')}` },
    { value: 2, label: `${t('pageConciliation.status.2')}` }
  ]
  const statusOptionsDefaultReceivedConciliation = [
    { value: null, label: `${t('pageConciliation.statusConference.null')}` },
    { value: 2, label: `${t('pageConciliation.statusConference.2')}` },
    { value: 3, label: `${t('pageConciliation.statusConference.3')}` }
  ]
  // state filters local
  const [dateStart, setDateStart] = useState(startAtScheduleConciliation)
  const [dateEnd, setDateEnd] = useState(endAtScheduleConciliation)
  const [schedulingDateStart, setSchedulingDateStart] =
    useState(startAtConciliation)
  const [schedulingDateEnd, setSchedulingDateEnd] = useState(endAtConciliation)
  const [ways, setWays] = useState<any>(waysParam)
  const [receiveConciliation, setReceiveConciliation] = useState<any>(
    statusReceiveConciliation
  )
  const [conciliation, setConciliation] = useState<any>(statusConciliation)

  // get all wayToReceives selected
  useEffect(() => {
    const getWaysToReceives = async () => {
      const { data } = await apiAuth.get('clinics/ways-receives/')

      setSelectWaysToReceives(data.results)
    }

    getWaysToReceives()

    return () => {}
  }, [])

  const {
    data: getConciliationData,
    refetch,
    isLoading,
    isFetching
  } = useQuery(
    [
      'conciliation',
      currentPage,
      searchState,
      startAtConciliation,
      endAtConciliation,
      startAtScheduleConciliation,
      endAtScheduleConciliation,
      waysParam,
      statusConciliation,
      statusReceiveConciliation
    ],
    () =>
      getConciliation(
        currentPage,
        searchState,
        startAtConciliation,
        endAtConciliation,
        startAtScheduleConciliation,
        endAtScheduleConciliation,
        waysParam,
        statusConciliation,
        statusReceiveConciliation
      )
  )

  useEffect(() => {
    refetch()
  }, [])

  // update values in path by idItem
  useEffect(() => {
    if (idItem === 0) {
      return () => {}
    }

    const handlerUpdateConciliation = async () => {
      const payload = {
        status_conciliation: status
      }

      setChange(true)

      try {
        const { data } = await apiAuth.patch(
          `clinics/tickets/conciliation/${idItem}/`,
          payload
        )

        if (data !== undefined) {
          refetch()

          toast({
            status: 'success',
            title: 'Atualização realizada!',
            description: 'O status do item foi atualizado.',
            duration: 2000,
            position: 'top-right'
          })
        }
      } catch (error: any) {
        toast({
          status: 'error',
          title: 'Error!',
          description: 'Não foi possível atualizar o item.',
          duration: 2000,
          position: 'top-right'
        })
      }
    }

    handlerUpdateConciliation()
    return () => {}
  }, [idItem, status])

  useEffect(() => {
    if (isChange === false) {
      return () => {}
    }

    if (isChange) {
      refetch()

      setChange(false)
    }

    return () => {}
  }, [isChange])

  // generate all boolean select array change data
  useEffect(() => {
    const generateArrayCheckBox = () => {
      if (getConciliationData?.conciliation?.length === 0) {
        setCheckedItems([])

        return
      }

      const arrayCheckBox = getConciliationData?.conciliation.map((item) => {
        return false
      })

      setCheckedItems(arrayCheckBox || [])
    }

    generateArrayCheckBox()

    return () => {}
  }, [getConciliationData?.conciliation])

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setSearchState(value)
      }, 500)
    },
    []
  )

  // close filter modal
  const handlerCloseModalBillsToReceive = () => {
    handlerCloseModal('Conciliation')
  }

  // get filters specialist
  const getFiltersSpecialist = (values: String[] | String) => {
    setWays(values)
  }

  // jxs status receive
  const returnJsxElementStatusReceive = (
    statusPayment: number | null | undefined
  ) => {
    if (statusPayment === 2) {
      return (
        <Tooltip
          label={t('pageConciliation.statusConference.2')}
          aria-label={t('pageConciliation.statusConference.2')}
          hasArrow
          placement="top-start"
        >
          <Box>
            <Text color="#56c20ff4" fontSize="xs" style={{ cursor: 'default' }}>
              {t('pageConciliation.statusConference.2')}
            </Text>
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 3) {
      return (
        <Tooltip
          label={t('pageConciliation.statusConference.3')}
          aria-label={t('pageConciliation.statusConference.3')}
          hasArrow
          placement="top-start"
        >
          <Box>
            <Text color="#c71717f4" fontSize="xs" style={{ cursor: 'default' }}>
              {t('pageConciliation.statusConference.3')}
            </Text>
          </Box>
        </Tooltip>
      )
    }

    return (
      <Tooltip
        label={t('pageConciliation.statusConference.null')}
        aria-label={t('pageConciliation.statusConference.null')}
        hasArrow
        placement="top-start"
      >
        <Box>
          <Text color="#9699B0" fontSize="xs" style={{ cursor: 'default' }}>
            {t('pageConciliation.statusConference.null')}
          </Text>
        </Box>
      </Tooltip>
    )
  }

  // jxs status receive
  const returnJsxElementStatusConciliation = (conciliationStatus: number) => {
    if (conciliationStatus === 1) {
      return (
        <Tooltip
          label={t('pageConciliation.status.1')}
          aria-label={t('pageConciliation.status.1')}
          hasArrow
          placement="top-start"
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Box
              ml="1rem"
              w="24px"
              h="24px"
              bg="#c71717f4"
              borderRadius="full"
            />
            <Text
              fontWeight="hairline"
              fontSize="sm"
              _hover={{ cursor: 'pointer' }}
            >
              {t('pageConciliation.status.1')}
            </Text>
          </Stack>
        </Tooltip>
      )
    }

    if (conciliationStatus === 2) {
      return (
        <Tooltip
          label={t('pageConciliation.status.2')}
          aria-label={t('pageConciliation.status.2')}
          hasArrow
          placement="top-start"
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Box
              ml="1rem"
              w="24px"
              h="24px"
              bg="#56c20ff4"
              borderRadius="full"
            />
            <Text
              fontWeight="hairline"
              fontSize="sm"
              _hover={{ cursor: 'pointer' }}
            >
              {t('pageConciliation.status.2')}
            </Text>
          </Stack>
        </Tooltip>
      )
    }

    return null
  }

  // handler select item
  const handlerChangeCheckBoxValue = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const values = [...checkedItems]
    values[index] = event.target?.checked

    setCheckedItems(values)
  }

  // handler select all items or unselect
  const handlerChangeCheckBoxValueAllItems = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const values = [...checkedItems]
    const newValues = values.map((booleanValue) => {
      return event.target?.checked
    })

    setCheckedItems(newValues)
  }

  // handler action confirmation
  const handlerActionConfirmation = async () => {
    const idsSelected = getConciliationData?.conciliation.map((item, index) => {
      return checkedItems?.[index] === true ? item.id : undefined
    })

    const ids = idsSelected?.filter(Boolean)

    if (ids?.length === 0) {
      toast({
        status: 'info',
        title: 'Selecione ao menos 1 item!',
        description: 'para realizar a ação selecione um ou mais items',
        duration: 2000,
        position: 'top-right'
      })
    }

    if (ids?.length !== undefined) {
      if (ids?.length > 0) {
        const payload = {
          ids,
          status_conciliation: 2
        }

        try {
          const { data } = await apiAuth.patch(
            `clinics/tickets/conciliation/${ids[0]}/`,
            payload
          )

          if (data !== undefined) {
            toast({
              status: 'success',
              title: 'Atualização realizada!',
              description: 'ação em massa realizada com sucesso.',
              duration: 2000,
              position: 'top-right'
            })

            setChange(true)

            const generateArrayCheckBox = () => {
              if (getConciliationData?.conciliation?.length === 0) {
                setCheckedItems([])

                return
              }

              const arrayCheckBox = getConciliationData?.conciliation.map(
                (item) => {
                  return false
                }
              )

              setCheckedItems(arrayCheckBox || [])
            }

            generateArrayCheckBox()

            refetch()
          }
        } catch (error: any) {
          toast({
            status: 'error',
            title: 'Ação, não realizada!',
            duration: 2000,
            position: 'top-right'
          })
        }
      }
    }
  }

  const handlerEditByPatch = async () => {
    const payload = {
      due_date: `${dueDate}${getConciliationData?.conciliation?.[
        indexEdit
      ].due_date?.substring(10, 27)}`,
      tax_absolute:
        taxAbsolute === ''
          ? null
          : Number(remaskCaractersAll(taxAbsolute)) / 100,
      tax_percentage:
        taxPercent === '' ? null : Number(remaskCaractersAll(taxPercent)) / 100,
      interest_rate_absolule:
        interestAbsolute === ''
          ? null
          : Number(remaskCaractersAll(interestAbsolute)) / 100,
      interest_rate_percentage:
        interestPercent === ''
          ? null
          : Number(remaskCaractersAll(interestPercent))
    }

    try {
      const { data } = await apiAuth.patch(
        `clinics/tickets/conciliation/${getConciliationData?.conciliation?.[indexEdit]?.id}/`,
        payload
      )

      if (data !== undefined) {
        refetch()
        setChange(true)

        toast({
          status: 'success',
          title: 'Atualização realizada!',
          description: 'O item foi atualizado.',
          duration: 2000,
          position: 'top-right'
        })
      }
    } catch (error: any) {
      toast({
        status: 'error',
        title: 'Error!',
        description: 'Não foi possível atualizar o item.',
        duration: 2000,
        position: 'top-right'
      })
    }
  }

  // menuItems options
  const menuOptionsActionsPage = () => {
    return (
      <MenuItem onClick={() => handlerActionConfirmation()}>
        {t('pageConciliation.menu.allConciliation')}
      </MenuItem>
    )
  }

  // return tax information
  const taxValues = (valueParcel: number, netValue: number) => {
    const value = Number(valueParcel - netValue)

    const formatValue = formatBrOrDollarDefault(value.toFixed(2))

    if (formatValue === '') {
      return <Text _hover={{ cursor: 'default' }}>---</Text>
    }

    return (
      <Text fontWeight="hairline" fontSize="xs" _hover={{ cursor: 'default' }}>
        {formatValue}
      </Text>
    )
  }

  return (
    <>
      <LayoutBlank
        title={t('pageConciliation.title')}
        placeholder={t('pageConciliation.search')}
        isHaveFilter
        nameFilter="Conciliation"
        capturaChange={(event) => SearchInputCallbackValue(event)}
        noRenderIsActive
        menuItems={menuOptionsActionsPage()}
        refetch={refetch}
        isFetching={isFetching}
        isActions
      >
        <Stack w="100%" spacing="4" direction={['row', 'column']}>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isChecked={
                      checkedItems !== undefined && checkedItems?.length > 0
                        ? allChecked
                        : undefined
                    }
                    isIndeterminate={
                      checkedItems !== undefined && checkedItems?.length > 0
                        ? isIndeterminate
                        : undefined
                    }
                    onChange={(event) =>
                      handlerChangeCheckBoxValueAllItems(event)
                    }
                  />
                </Th>
                <Th>{t('pageConciliation.t1')}</Th>
                <Th>{t('pageConciliation.t2')}</Th>
                <Th>{t('pageConciliation.t3')}</Th>
                <Th>{t('pageConciliation.t4')}</Th>
                <Th>{t('pageConciliation.t5')}</Th>
                <Th>{t('pageConciliation.t6')}</Th>
                <Th>{t('pageConciliation.t7')}</Th>
                <Th>{t('pageConciliation.t8')}</Th>
                <Th>{t('pageConciliation.t9')}</Th>
                <Th>{t('pageConciliation.t10')}</Th>
                <Th>{t('pageConciliation.t11')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton) => (
                    <Tr key={skeleton}>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <>
                  {getConciliationData?.conciliation?.map((item, index) => (
                    <Tr key={`${item.id}${index}${item.way_to_receive?.name}`}>
                      <Td>
                        <Checkbox
                          isChecked={checkedItems?.[index]}
                          onChange={(event) =>
                            handlerChangeCheckBoxValue(index, event)
                          }
                        />
                      </Td>
                      <Td>{format(new Date(item.due_date), 'dd/MM/yyyy')}</Td>
                      <Td>{item?.ticket?.ticket_number}</Td>
                      <Td>
                        <Tooltip
                          label={item?.client?.map((patient) => (
                            <Heading
                              key={patient.id}
                              fontSize="xs"
                              fontWeight="hairline"
                              noOfLines={1}
                              _hover={{
                                cursor: 'default'
                              }}
                            >
                              {patient.name}
                            </Heading>
                          ))}
                          hasArrow
                          placement="top-start"
                        >
                          <Box w="36px">
                            <FcInfo size={26} />
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td>
                        <Tooltip
                          label={item?.way_to_receive?.name}
                          aria-label={item?.way_to_receive?.name}
                          hasArrow
                          placement="top-start"
                        >
                          <Heading
                            fontSize="xs"
                            fontWeight="hairline"
                            noOfLines={1}
                            _hover={{
                              cursor: 'default'
                            }}
                          >
                            {item?.way_to_receive?.name}
                          </Heading>
                        </Tooltip>
                      </Td>
                      <Td>
                        <Box>
                          <Heading
                            fontSize="xs"
                            fontWeight="hairline"
                            noOfLines={1}
                            _hover={{
                              cursor: 'default'
                            }}
                          >
                            {`${item.parcel_number} ${t('of')} ${
                              item?.total_parcels
                            }`}
                          </Heading>
                        </Box>
                      </Td>
                      <Td textAlign="right">
                        <Heading
                          fontSize="xs"
                          fontWeight="hairline"
                          noOfLines={1}
                          _hover={{
                            cursor: 'default'
                          }}
                        >
                          {/* trocar para total parcel */}
                          {formatBrOrDollarDefault((item?.value).toFixed(2))}
                        </Heading>
                      </Td>
                      <Td>
                        <Tooltip
                          label={
                            <Box p="2">
                              <Heading
                                fontSize="sm"
                                fontWeight="hairline"
                                noOfLines={1}
                                _hover={{
                                  cursor: 'default'
                                }}
                              >
                                {`${t(
                                  'pageConciliation.taxes.1'
                                )} ${formatBrOrDollarDefault(
                                  item?.tax_percentage?.toFixed(2) || ''
                                )} `}
                                {!item?.tax_percentage && '-'}
                                {item?.value_tax_percentage !== 0 &&
                                  `| (${formatBrOrDollarDefault(
                                    item?.value_tax_percentage?.toFixed(2) || ''
                                  )})`}
                              </Heading>
                              <Heading
                                fontSize="sm"
                                fontWeight="hairline"
                                noOfLines={1}
                                _hover={{
                                  cursor: 'default'
                                }}
                              >
                                {`${t(
                                  'pageConciliation.taxes.2'
                                )}${formatBrOrDollarDefault(
                                  item?.tax_absolute?.toFixed(2) || ''
                                )}`}
                                {!item?.tax_absolute && '-'}
                              </Heading>
                            </Box>
                          }
                          hasArrow
                          placement="top-start"
                        >
                          <Box w="36px">
                            {taxValues(item.value, item.net_value)}
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td textAlign="right">
                        <Heading
                          fontSize="xs"
                          fontWeight="hairline"
                          noOfLines={1}
                          _hover={{
                            cursor: 'default'
                          }}
                        >
                          {formatBrOrDollarDefault(item.net_value.toFixed(2))}
                        </Heading>
                      </Td>
                      <Td>
                        {returnJsxElementStatusReceive(
                          item?.ticket?.result_status
                        )}
                      </Td>
                      <Td>
                        {returnJsxElementStatusConciliation(
                          item.status_conciliation
                        )}
                      </Td>
                      <Td>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <PermissionComponent
                            ipCode="EDIT"
                            spiCode="CONCILIATION"
                          >
                            <Button
                              size="xs"
                              bg="transparent"
                              _hover={{ bg: 'transparent' }}
                              onClick={() => {
                                setIndexEdit(index)
                                setDueDate(
                                  item?.due_date?.substring(0, 10) || ''
                                )

                                setTaxAbsolute(
                                  formatBrOrDollarDefault(
                                    item.tax_absolute?.toFixed(2) || ''
                                  )
                                )
                                setInterestAbsolute(
                                  formatBrOrDollarDefault(
                                    item.interest_rate_absolule?.toFixed(2) ||
                                      ''
                                  )
                                )
                                setTaxPercent(
                                  item?.tax_percentage !== null
                                    ? String(item.tax_percentage)
                                    : ''
                                )
                                setInterestPercent(
                                  item?.interest_rate_percentage !== null
                                    ? String(item.interest_rate_percentage)
                                    : ''
                                )

                                setIsOpen(true)
                              }}
                            >
                              <FiEdit />
                            </Button>
                          </PermissionComponent>
                          <PermissionComponent
                            ipCode="APPROVE"
                            spiCode="CONCILIATION"
                          >
                            <Box p="2" w="150px">
                              <Select
                                size="xs"
                                my="0"
                                name="status"
                                options={statusOptionsDefaultConciliation}
                                value={item.status_conciliation}
                                onChange={(event) => {
                                  setStatus(Number(event.target.value))
                                  setIdItem(item.id)
                                }}
                              />
                            </Box>
                          </PermissionComponent>
                        </Stack>
                      </Td>
                    </Tr>
                  ))}
                </>
              )}
            </Tbody>
          </Table>
          {getConciliationData?.totalCountOfPage === 0 && !isLoading && (
            <NotFound />
          )}
        </Stack>
      </LayoutBlank>
      <ModalArrivedTime
        title={t('pageConciliation.filters.title')}
        isOpen={isOpenConciliation}
        onClose={handlerCloseModalBillsToReceive}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Stack w="100%" p="4" dir="column" h="500px">
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('pageConciliation.filters.selects')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="flex-start">
            <MultiSelectMenuFilter
              label={t('pageConciliation.filters.ways')}
              options={selectWaysToReceives.map((way) => {
                return {
                  id: String(way.id),
                  name: way?.name
                }
              })}
              backChange={(values) => {
                getFiltersSpecialist(values)
              }}
              render={() => {
                return ways
              }}
            />

            <MultiSelectMenuFilter
              label={t('pageConciliation.filters.statusReceive')}
              options={statusOptionsDefaultReceivedConciliation.map(
                (itemStatus) => {
                  return {
                    id: String(itemStatus.value),
                    name: itemStatus.label
                  }
                }
              )}
              backChange={(values) => {
                setReceiveConciliation(values)
              }}
              render={() => {
                const statusRender: any = receiveConciliation
                return statusRender
              }}
            />

            <MultiSelectMenuFilter
              label={t('pageConciliation.filters.statusConciliation')}
              options={statusOptionsDefaultConciliation.map((itemStatus) => {
                return {
                  id: String(itemStatus.value),
                  name: itemStatus.label
                }
              })}
              backChange={(values) => {
                setConciliation(values)
              }}
              render={() => {
                const statusRender: any = conciliation
                return statusRender
              }}
            />
          </Flex>
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('pageConciliation.filters.rangeDate')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="space-between" gap={2}>
            <Input
              name="dateInitial"
              type="date"
              isRequired
              label={t('pageConciliation.filters.dateInitial')}
              value={schedulingDateStart.substring(0, 10)}
              onChange={(event) =>
                setSchedulingDateStart(`${event.target.value}T00:00`)
              }
            />
            <Input
              name="dateFinal"
              type="date"
              isRequired
              label={t('pageConciliation.filters.dateFinal')}
              value={schedulingDateEnd.substring(0, 10)}
              onChange={(event) =>
                setSchedulingDateEnd(`${event.target.value}T23:59`)
              }
            />
          </Flex>
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('pageConciliation.filters.rangeDateSchedule')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="space-between" gap={2}>
            <Input
              name="dateInitialSchedule"
              type="date"
              label={t('pageConciliation.filters.dateInitial')}
              value={dateStart}
              onChange={(event) => setDateStart(event.target.value)}
            />
            <Input
              name="dateFinalSchedule"
              type="date"
              label={t('pageConciliation.filters.dateFinal')}
              value={dateEnd}
              onChange={(event) => setDateEnd(event.target.value)}
            />
          </Flex>
          <Flex
            position="absolute"
            bottom="2"
            right="2"
            justifyContent="flex-end"
            alignItems="flex-end"
            flexDir="row"
          >
            <Button
              variant="outline"
              mx="2"
              onClick={() => handlerRemoveFiltersConciliation()}
            >
              Limpar filtro
            </Button>
            <Button
              bg="blue.300"
              color="white"
              onClick={() => {
                const filters = {
                  startAt: schedulingDateStart,
                  endAt: schedulingDateEnd,
                  endAtConciliation: dateEnd,
                  startAtConciliation: dateStart,
                  meansToReceiveIds: ways,
                  statusReceive: receiveConciliation,
                  statusConciliation: conciliation
                }

                handlerApplyFiltersConciliation(filters)
              }}
            >
              Aplicar filtro
            </Button>
          </Flex>
        </Stack>
      </ModalArrivedTime>
      <ModalArrivedTime
        title="Edição/Ajuste de Conciliação"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onUpdate={() => {
          handlerEditByPatch()
          setIsOpen(false)
        }}
      >
        <Stack w="100%" p="4" dir="column" h="300px">
          <Stack direction="row" spacing="6">
            <Input
              name="dueDateEdit"
              label="Data de vencimento (Recebimento)"
              value={dueDate}
              isRequired
              onChange={(event) => setDueDate(event.target.value)}
              type="date"
            />
          </Stack>
          <Stack direction="row" spacing="6">
            <TextFieldControlled
              name="taxAbsolute"
              label="Taxa em moeda"
              initialValue={taxAbsolute}
              inputOnChange={(event) =>
                setTaxAbsolute(event.currentTarget.value)
              }
              mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
            />
            <TextFieldControlled
              name="taxPercent"
              label="Taxa em porcentagem"
              initialValue={taxPercent}
              inputOnChange={(event) =>
                setTaxPercent(event.currentTarget.value)
              }
              mask="porcentagem"
            />
          </Stack>
          <Stack direction="row" spacing="6">
            <TextFieldControlled
              name="interestAbsolute"
              label="Juros em moeda"
              initialValue={interestAbsolute}
              inputOnChange={(event) =>
                setInterestAbsolute(event.currentTarget.value)
              }
              mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
            />
            <TextFieldControlled
              name="interestPercent"
              label="Juros em porcentagem"
              initialValue={interestPercent}
              inputOnChange={(event) =>
                setInterestPercent(event.currentTarget.value)
              }
              mask="porcentagem"
            />
          </Stack>
        </Stack>
      </ModalArrivedTime>
    </>
  )
}
