import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'
import { Profile } from '../atoms/Profile'
import { SidebarNav } from './SidebarNav'

export function Sidebar() {
  const { isOpen, handlerCloseSideBarDrawer } = useSidebarDrawer()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const [isMouseHover, setIsMouseHover] = useState(false)
  const hover = {
    w: '280px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)'
  }

  const HandlerHoverMouseSidebar = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setTimeout(() => {
      setIsMouseHover(true)
    }, 500)
  }

  const HandlerOutMouseSidebar = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsMouseHover(false)
  }

  if (!isWideVersion) {
    return (
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={() => handlerCloseSideBarDrawer()}
      >
        <DrawerOverlay>
          <DrawerContent bg="Menu" p="2">
            <DrawerCloseButton mt="4" />

            <DrawerHeader>MENU</DrawerHeader>

            <DrawerBody>
              <Flex flexDirection="column" position="relative">
                <SidebarNav isMouseHover />
                <Flex position="absolute" bottom="-20" left="-4">
                  <Profile showProfileData />
                </Flex>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }

  return (
    <Box
      h="100%"
      as="aside"
      top="51px"
      borderRightRadius={8}
      width="53px"
      d="flex"
      flexDirection="column"
      justifyContent="space-between"
      mr="8"
      py="6"
      px="4"
      pl="6"
      bg="#fff"
      position="fixed"
      zIndex="99"
      transition=".1s ease-in-out"
      overflowY="auto"
      _hover={isMouseHover ? hover : undefined}
      onMouseEnter={(event) => HandlerHoverMouseSidebar(event)}
      onMouseLeave={(event) => HandlerOutMouseSidebar(event)}
    >
      <Flex flexDirection="column" position="relative">
        <SidebarNav isMouseHover={isMouseHover} />
        <Flex position="absolute" bottom="-20" left="-4">
          <Profile showProfileData={isMouseHover} />
        </Flex>
      </Flex>
    </Box>
  )
}
