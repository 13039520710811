interface PropsArray {
  id?: number
  value?: number
  label?: string
}

export function diffArrays<T extends keyof PropsArray>(
  array1: PropsArray[],
  array2: PropsArray[],
  prop: T
) {
  // Cria um mapa para armazenar os elementos do array2 usando a propriedade específica como chave
  const map = new Map<number | string | undefined, PropsArray>()
  array2.forEach((item) => map.set(item[prop], item))

  // Filtra os elementos do array1 que não estão presentes no mapa com base na propriedade específica
  const difference = array1.filter((item) => !map.has(item[prop]))

  return difference
}

export function diffArraysSub<T extends keyof PropsArray>(
  array1: PropsArray[],
  array2: PropsArray[],
  prop: T
) {
  // Cria um mapa para armazenar os elementos do array2 usando a propriedade específica como chave
  const map = new Map<number | string | undefined, PropsArray>()
  array2.forEach((item) => map.set(item[prop], item))

  // Filtra os elementos do array1 que não estão presentes no mapa com base na propriedade específica
  const difference = array1.filter((item) => map.has(item[prop]))

  return difference
}
