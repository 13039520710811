import {
  Box,
  Flex,
  Heading,
  Text,
  Icon,
  Center,
  useBreakpointValue,
  VStack
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiCamera, FiCloudOff, FiFolder, FiDownload } from 'react-icons/fi'
import { BiCameraMovie } from 'react-icons/bi'

type EventMouse = React.MouseEvent<HTMLDivElement, MouseEvent>

interface CardsProps {
  photosQuantity?: number
  mediaQuantity?: number
  documentsQuantity?: number
  date: string
  isMedia?: boolean
  title: string
  onClick: () => void
}

export const Card = ({
  photosQuantity,
  mediaQuantity,
  documentsQuantity,
  date,
  title,
  isMedia,
  onClick
}: CardsProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const [color, setColor] = useState('#fff')

  const handlerHover = (event: EventMouse) => {
    if (event) {
      setColor('#f0f0f0')
    }
  }

  const handlerLeave = (event: EventMouse) => {
    if (event) {
      setColor('#fff')
    }
  }

  if (!isWideVersion) {
    return (
      <Box
        py="2"
        borderRadius="16px"
        alignContent="center"
        justifyContent="center"
        position="relative"
        bg={color}
        onMouseEnter={handlerHover}
        onMouseLeave={handlerLeave}
        onClick={onClick}
        _hover={{
          cursor: 'pointer'
        }}
        w="100%"
      >
        <Flex align="center" justify="space-between" mx="2" p="2" w="100%">
          <Flex
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.16)"
            w="80px"
            h="80px"
            borderRadius="full"
            align="center"
            justify="center"
          >
            {!isMedia && 22}
            {photosQuantity === 0 &&
              mediaQuantity === 0 &&
              documentsQuantity === 0 && (
                <Icon mx="1px" as={FiCloudOff} fontSize={16} />
              )}
            {photosQuantity !== undefined && photosQuantity > 0 && (
              <Icon mx="1px" as={FiCamera} fontSize={16} />
            )}
            {mediaQuantity !== undefined && mediaQuantity > 0 && (
              <Icon mx="1px" as={BiCameraMovie} fontSize={16} />
            )}
            {documentsQuantity !== undefined && documentsQuantity > 0 && (
              <Icon mx="1px" as={FiDownload} fontSize={16} />
            )}
          </Flex>
          <Flex>
            <VStack spacing="6">
              <Text fontWeight="hairline">{date}</Text>
              <Flex
                p="2"
                flexDirection="column"
                align="flex-start"
                justify="center"
              >
                <Heading size="md" my="2">
                  {title}
                </Heading>
                <Flex w="80px">
                  {isMedia && (
                    <Text
                      fontSize="sm"
                      fontWeight="hairline"
                      alignContent="center"
                      justifyContent="center"
                      flexDirection="row"
                    >
                      <Icon as={FiCamera} /> {photosQuantity} -{' '}
                      <Icon as={BiCameraMovie} /> {mediaQuantity} -{' '}
                      <Icon as={FiDownload} /> {documentsQuantity}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </VStack>
          </Flex>
        </Flex>
      </Box>
    )
  }

  return (
    <Box
      py="2"
      borderRadius="16px"
      alignContent="center"
      justifyContent="center"
      position="relative"
      bg={color}
      onMouseEnter={handlerHover}
      onMouseLeave={handlerLeave}
      _hover={{
        cursor: 'pointer'
      }}
      onClick={onClick}
      w="100%"
      h="100px"
    >
      <Center mx="auto" flex="1" flexDirection="row">
        <Flex mx="10" flexDirection="row" align="center" justify="center">
          <Text fontWeight="hairline">{date}</Text>
        </Flex>
        <Flex mx="10" flexDirection="row" align="center" justify="center">
          <Flex
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.16)"
            w="80px"
            h="80px"
            borderRadius="full"
            align="center"
            justify="center"
          >
            {!isMedia && <Icon mx="1px" as={FiFolder} fontSize={22} />}
            {photosQuantity === 0 &&
              mediaQuantity === 0 &&
              documentsQuantity === 0 && (
                <Icon mx="1px" as={FiCloudOff} fontSize={16} />
              )}
            {photosQuantity !== undefined && photosQuantity > 0 && (
              <Icon mx="1px" as={FiCamera} fontSize={16} />
            )}
            {mediaQuantity !== undefined && mediaQuantity > 0 && (
              <Icon mx="1px" as={BiCameraMovie} fontSize={16} />
            )}
            {documentsQuantity !== undefined && documentsQuantity > 0 && (
              <Icon mx="1px" as={FiDownload} fontSize={16} />
            )}
          </Flex>
        </Flex>
        <Flex
          mx="10"
          flexDirection="column"
          align="flex-start"
          justify="center"
        >
          <Heading size="md" my="2">
            {title}
          </Heading>
          <Flex w="120px">
            {isMedia && (
              <Text fontSize="sm" fontWeight="hairline" flexDirection="row">
                <Icon as={FiCamera} /> {photosQuantity} -{' '}
                <Icon as={BiCameraMovie} /> {mediaQuantity} -{' '}
                <Icon as={FiDownload} /> {documentsQuantity}
              </Text>
            )}
          </Flex>
        </Flex>
      </Center>
    </Box>
  )
}
