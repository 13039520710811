/* eslint-disable camelcase */
import { FC, useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Textarea,
  useBreakpointValue,
  Spinner,
  useToast,
  List,
  ListItem,
  Divider,
  Checkbox
} from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FiFolderPlus, FiPrinter } from 'react-icons/fi'
import { apiAuth } from '../../services/apiAuth'
import { LayoutDefault } from '../../layouts/Default'
import { ListItemComparisons } from '../../components/Board/boardCardComparisons'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { useDnd } from '../../hooks/useDnd'

type FormData = {
  description: string
}

type ParamsProps = {
  id: string
}

interface GalleryCard {
  date: string
  description: string | null
  gallery_patient: number
  id: string
  name: string
  patient_name: string
  quantity_images: number
  quantity_videos: number
}

type GalleryCardData = GalleryCard | null

export const CardComparisons: FC = () => {
  const [t] = useTranslation('pageComparisons')
  const toast = useToast()
  const { id } = useParams<ParamsProps>()
  const { setList, list } = useDnd()
  const history = useHistory()
  const data: any = history.location
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState

  // state cardComparisons info
  const [cardInfo, setCardInfo] = useState<GalleryCardData>(null)

  // state update Card Name and Date
  const [cardName, setCardName] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cardDescription, setCardDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // state checked items values for Actions
  const [checkedItems, setCheckedItems] = useState([false, false])

  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked

  // modalExclude state
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {}, [id])

  // function open Modal exclude
  function onOpenModalExclude() {
    setIsOpen(true)
  }

  // functions close MOdal exclude
  function onCloseModalExclude() {
    setIsOpen(false)
  }

  // update info Cards
  const handlerUpdateCard: SubmitHandler<FormData> = async (values, event) => {
    event?.preventDefault()

    const payload = {
      name: cardName,
      date: cardDate,
      description: cardDescription,
      gallery_patient: data.state.id
    }

    try {
      const { data: objectData } = await apiAuth.put(
        `clinics/comparisons/${id}/`,
        payload
      )

      setCardName(objectData.name || '')
      setCardDate(objectData.date || '')
      setCardDescription(objectData.description || '')

      toast({
        duration: 3000,
        status: 'success',
        description: `${t('toastUpdate.success')}`,
        position: 'top-right'
      })
    } catch (error: any) {
      toast({
        duration: 3000,
        status: 'error',
        title: `${t('toastUpdate.error')}`,
        position: 'top-right',
        description: `${error.message}`
      })
    }
  }

  return (
    <>
      <LayoutDefault
        title={isWideVersion ? t('title') : t('mobileTitle')}
        urlBack={`/clients/update/${data.state.id}`}
        onSubmit={handleSubmit(handlerUpdateCard)}
      >
        <Stack w="100%" spacing="4" direction="column">
          <CardContainer title={t('container0.title')}>
            {isLoading ? (
              <Flex p="2" w="100" justifyContent="center" alignItems="center">
                <Spinner color="blue" />
              </Flex>
            ) : (
              <Flex
                flexDirection={['column', 'row']}
                alignItems="flex-end"
                justifyContent="center"
                w="100%"
              >
                <Flex direction="column" w={['100%', '50%']}>
                  <Flex
                    mb="2"
                    align="flex-start"
                    justify="flex-start"
                    flexDirection={['column', 'row']}
                  >
                    <Text
                      mx="2"
                      my={!isWideVersion ? '2' : ''}
                      fontWeight="hairline"
                    >
                      {t('container0.clientName')}
                    </Text>
                    <Text mx="2" fontWeight="hairline">
                      {cardInfo ? cardInfo?.patient_name : null}
                    </Text>
                  </Flex>
                  <Flex
                    align="flex-start"
                    justify="flex-start"
                    flexDirection={['column', 'row']}
                  >
                    <Box mb="2" mx="2" w={['280px', '300px']}>
                      <Input
                        name="cardName"
                        label={t('container0.cardName')}
                        value={cardName}
                        isRequired
                        placeholder={t('container0.placeholder.cardName')}
                        onChange={(event) =>
                          setCardName(event.target.value.toUpperCase())
                        }
                        maxLength={15}
                      />
                    </Box>
                    <Box mb="2" mx="2" w={['280px', '300px']}>
                      <Input
                        name="cardDate"
                        isRequired
                        type="date"
                        label={t('container0.cardDate')}
                        value={cardDate}
                        onChange={(event) => setCardDate(event.target.value)}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  mx="4"
                  my="2"
                  pl={!isWideVersion ? '6' : undefined}
                  w={['100%', '50%']}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                >
                  <Flex>
                    <Text fontSize="14px" mb="2">
                      {t('container0.description')}
                    </Text>
                  </Flex>
                  <Textarea
                    name="description"
                    maxLength={200}
                    value={cardDescription}
                    onChange={(event) => setCardDescription(event.target.value)}
                    placeholder={t('container0.placeholder.description')}
                  />
                </Flex>
              </Flex>
            )}
          </CardContainer>

          <CardContainer title={t('container1.title')}>
            <List mt="4" spacing={2}>
              {/* header */}
              <ListItem mb="2">
                <Flex
                  w="100%"
                  flexDirection={['column', 'row']}
                  justifyContent="space-around"
                  mx="auto"
                  position="relative"
                >
                  <Checkbox
                    colorScheme="blue"
                    position="absolute"
                    left="2"
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={(e) =>
                      setCheckedItems([e.target.checked, e.target.checked])
                    }
                  />
                  <Text fontSize="14" fontWeight="bold">
                    {t('container1.column.1')}
                  </Text>
                  <Text fontSize="14" fontWeight="bold">
                    {t('container1.column.2')}
                  </Text>
                  <Text fontSize="14" fontWeight="bold">
                    {t('container1.column.3')}
                  </Text>
                  <Text fontSize="14" fontWeight="bold">
                    {t('container1.column.4')}
                  </Text>
                </Flex>
              </ListItem>
              <Divider />
              {list &&
                list.map((item, index) => (
                  <ListItemComparisons
                    key={item.id}
                    isChecked={checkedItems[index]}
                    data={item}
                    index={index}
                    onCheckedChange={(e) => e.target.checked}
                    t={t}
                  />
                ))}
            </List>
            <Flex gap={4} flexDirection={['column', 'row']} my="2">
              <Button size={isWideVersion ? 'sm' : 'xs'} colorScheme="blue">
                {isWideVersion ? t('container1.printImage') : <FiPrinter />}
              </Button>
              <Button size={isWideVersion ? 'sm' : 'xs'} colorScheme="blue">
                {isWideVersion ? (
                  t('container1.addComparison')
                ) : (
                  <FiFolderPlus />
                )}
              </Button>
            </Flex>
          </CardContainer>
          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('btnUpdate')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalConfirmExclude
        isOpen={isOpen}
        title={t('modalExclude.title')}
        onConfirmExclude={async () => {}}
        onClose={() => {
          onCloseModalExclude()
        }}
      >
        <Text>{t('modalExclude.message')}</Text>
      </ModalConfirmExclude>
    </>
  )
}
