/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type ProductsItemsData = {
  name: string
  stock_type: {
    id: number
    type: 'Insumo' | 'Revenda' | 'Consumo'
  }
  description: string
  balance_units: number
  balance_value: number
  total_units_in: number
  total_units_out: number
  minimun_quantity: number
  stock_unit: {
    id: number
    unit_ptbr: string
    unit_en: string
    unit_es: string
    symbol: string
  }
  stock_groups: Array<{
    id: number
    name: string
  }>
  id: number
}

export type ResponseGetProductsGroup = {
  products: ProductsItemsData[]
  totalCountOfPage: number
}

export const getProductsItems = async (
  page: number,
  search: string = '',
  isActive: boolean = true,
  groups: String[] | any = [],
  types: String[] | any = []
): Promise<ResponseGetProductsGroup> => {
  try {
    const { data } = await apiAuth.get(`clinics/stock-items/`, {
      params: {
        page,
        name: search,
        is_active: isActive,
        type: types?.join(','),
        group: groups?.join(',')
      }
    })

    const totalCountOfPage = data.count
    const products: ProductsItemsData[] = data.results

    return {
      products,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const products: ProductsItemsData[] = []
    const totalCountOfPage = 0
    return {
      products,
      totalCountOfPage
    }
  }
}

export function useProductsItems(
  page: number,
  functionLoading: Promise<ResponseGetProductsGroup>,
  search: string,
  isActive: boolean,
  groups: String[] | any = [],
  types: String[] | any = [],
  options?: {}
) {
  return useQuery(
    ['productsItems', page, search, isActive, groups, types],
    () => functionLoading
  )
}
