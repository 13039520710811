/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type TagsData = {
  id: string
  name: string
  observation: string
  transfers: [
    {
      id: number
      rule_name: string
      observation: string | null
      users: [
        {
          id: number
          name: string
        }
      ]
      is_active: boolean
    }
  ]
}

export type ResponseGetTags = {
  tags: TagsData[]
  totalCountOfPage: number
}

export const getTags = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetTags> => {
  try {
    const { data } = await apiAuth.get(`clinics/${clinicsId}/tags-services/`, {
      params: {
        page,
        name__icontains: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const tags: TagsData[] = data.results
    console.log(data.results)

    return {
      tags,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const tags: TagsData[] = []
    const totalCountOfPage = 0
    return {
      tags,
      totalCountOfPage
    }
  }
}

export function useTags(
  page: number,
  functionLoading: Promise<ResponseGetTags>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['tagsServices', page, search, isActive],
    () => functionLoading
  )
}
