/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Textarea,
  useToast,
  SimpleGrid,
  useBreakpointValue,
  Tooltip,
  Spinner
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { format } from 'date-fns'
import { useHistory, Link, useParams } from 'react-router-dom'
import { FiEdit, FiFile, FiUploadCloud } from 'react-icons/fi'
import { AiOutlineDollarCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import { useUpdate } from '../../hooks/useUpdate'

import { SuppliersValue, useLogicBillsToPay } from './logic'
import { useService } from './service'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Select } from '../../components/Form/Select'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { queryClient } from '../../services/reactQuery'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { PermissionComponent } from '../../components/Permissions'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'

type FormData = {
  name: string
  category: string
  cost_center: string
  observation: string
}

export type SuppliersData = {
  id: string
  trading_name: string
}

interface ParamsProps {
  id: string
}

export function UpdateBillsToPay() {
  const [t] = useTranslation('pageBillsToPay')
  const { currency, decodeToken } = useAuth()
  const logic = useLogicBillsToPay()
  const { UpdatePosting } = useUpdate()
  const history = useHistory()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { id } = useParams<ParamsProps>()
  // const { state }: any = history.location
  const [state, setState] = useState<any>(null)
  const service = useService()
  const [redirect, setRedirect] = useState(false)
  const toast = useToast()
  const updatePosting = useMutation(
    async (payload: any) => {
      await UpdatePosting(payload, state.id, redirect)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('billsToPay')
      },
      onSuccess: () => {
        queryClient.invalidateQueries('billsToPay')
      },
      onError: () => {
        // Invalidate Cache
        queryClient.invalidateQueries('billsToPay')

        // Message error
        toast({
          title: 'Cadastro não realizado',
          description: 'Ouve um erro de comunicação com o servidor',
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }
    }
  )
  const [supplierPosting, setSupplierPosting] = useState<SuppliersValue>({
    value: '',
    label: ''
  })
  const [cost_center, setCostCenter] = useState<SuppliersValue>({
    value: '',
    label: ''
  })
  const [category, setCategory] = useState<SuppliersValue>({
    value: '',
    label: ''
  })
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState

  useEffect(() => {
    // format data for state value
    const formatData = (myBills: any) => {
      const newCompetenceString = remaskCaractersAll(myBills?.competence_date)
      const newDueDateString = remaskCaractersAll(myBills?.due_date)

      // date invalid for app
      const newPaymentDate = remaskCaractersAll(
        myBills?.payment_date || '11112000'
      )
      // logic Get Format Date for body
      const vc = newCompetenceString.split('')
      const dayVc = Number(`${0}${5}`)
      const monthVc = Number(`${vc[4]}${vc[5]}`) - 1
      const yearVc = Number(`${vc[0]}${vc[1]}${vc[2]}${vc[3]}`)
      const dateVc = new Date(yearVc, monthVc, dayVc)
      const FormatMonthVc = String(format(dateVc, 'MM/yyyy'))

      // logic Get Format Date for body
      const vd = newDueDateString.split('')
      const dayVd = Number(`${vd[6]}${vd[7]}`)
      const monthVd = Number(`${vd[4]}${vd[5]}`) - 1
      const yearVd = Number(`${vd[0]}${vd[1]}${vd[2]}${vd[3]}`)
      const dateVd = new Date(yearVd, monthVd, dayVd)
      const FormatMonthVd = String(format(dateVd, 'dd/MM/yyyy'))
      // logic Get Format Date for body format
      const vp = newPaymentDate.split('')
      const dayVp = Number(`${vp[6]}${vp[7]}`)
      const monthVp = Number(`${vp[4]}${vp[5]}`) - 1
      const yearVp = Number(`${vp[0]}${vp[1]}${vp[2]}${vp[3]}`)
      const dateVp = new Date(yearVp, monthVp, dayVp)
      const FormatMonthVp = String(format(dateVp, 'dd/MM/yyyy'))

      const posting_methods = myBills?.posting_methods.map(
        (posting_method: any) => {
          const newPaymentDateMethod = remaskCaractersAll(
            posting_method.payment_date || '11112000'
          )
          // logic Get Format Date for body format
          const vpm = newPaymentDateMethod.split('')
          const dayVpm = Number(`${vpm[6]}${vpm[7]}`)
          const monthVpm = Number(`${vpm[4]}${vpm[5]}`) - 1
          const yearVpm = Number(`${vpm[0]}${vpm[1]}${vpm[2]}${vpm[3]}`)
          const dateVpm = new Date(yearVpm, monthVpm, dayVpm)
          const FormatMonthVpm = String(format(dateVpm, 'yyyy-MM-dd'))

          return {
            paymentDate: FormatMonthVpm,
            amount: formatBrOrDollarDefault(posting_method.price || ''),
            paymentMethod: String(posting_method.payment_source),
            balance: formatBrOrDollarDefault(posting_method.debt || '')
          }
        }
      )

      const attachments = myBills?.attachments.map((attachment: any) => {
        return {
          id: String(attachment.id),
          document: {
            file: attachment.file,
            file_format: attachment.file_format,
            description: attachment.description,
            alt: attachment.alt,
            posting_document: attachment.posting_document
          }
        }
      })

      return {
        id: myBills?.id,
        name: myBills?.name,
        code: myBills?.code,
        price: formatBrOrDollarDefault(myBills?.price),
        nominal_price: formatBrOrDollarDefault(myBills?.nominal_price),
        observation: myBills?.observation,
        pos: String(
          `${myBills?.share_number} de ${myBills?.total_of_postings}`
        ),
        competence_date: FormatMonthVc,
        due_date: myBills?.due_date,
        due_date_view: FormatMonthVd,
        payment_date: FormatMonthVp,
        share_number: myBills?.share_number,
        total_of_postings: myBills?.total_of_postings,
        addition_price: formatBrOrDollarDefault(myBills?.addition_price || ''),
        discount_price: formatBrOrDollarDefault(myBills?.discount_price || ''),
        status: myBills?.status,
        posting_methods,
        category: myBills?.category,
        supplier_posting: myBills?.supplier_posting,
        cost_center: myBills?.cost_center,
        postings: myBills?.total_of_postings,
        posting_clinic: myBills?.posting_clinic,
        posting_group: myBills?.posting_group,
        attachments,
        created_at: myBills?.created_at,
        updated_at: myBills?.updated_at,
        created_by: myBills?.created_by,
        updated_by: myBills?.updated_by
      }
    }

    const getPosting = async () => {
      const { data } = await apiAuth.get(`clinics/postings/${id}/`)
      const dataState = formatData(data)

      setState(dataState)
    }
    getPosting()

    return () => {}
  }, [id])

  useEffect(() => {
    setCostCenter({
      value: state?.cost_center?.id || '',
      label: state?.cost_center?.name || ''
    })
    setSupplierPosting({
      value: state?.supplier_posting?.id || '',
      label: state?.supplier_posting?.trading_name || ''
    })

    switch (decodeToken.clinic_language) {
      case 'en':
        setCategory({
          value: state?.category?.id || '',
          label: state?.category?.name_en || ''
        })
        break
      case 'es':
        setCategory({
          value: state?.category?.id || '',
          label: state?.category?.name_es || ''
        })

        break
      default:
        setCategory({
          value: state?.category?.id || '',
          label: state?.category?.name_ptbr || ''
        })
        break
    }

    logic.setNominalPrice(state?.nominal_price)
    logic.setCompetenceDateState(state?.competence_date)
    logic.setNewDueDateState(state?.due_date)
    logic.setName(state?.name)
  }, [state])

  const handleUpdateBillsToPay: SubmitHandler<FormData> = async ({
    observation
  }) => {
    // Format dates
    const formatCompetence_date = remaskCaractersAll(logic.competenceDateState)
    // logic Get Format Date for body
    const vc = formatCompetence_date.split('')
    const dayVc = Number(`${0}${5}`)
    const monthVc = Number(`${vc[0]}${vc[1]}`) - 1
    const yearVc = Number(`${vc[2]}${vc[3]}${vc[4]}${vc[5]}`)
    const dateVc = new Date(yearVc, monthVc, dayVc)
    const FormatCompetence = String(format(dateVc, 'yyyy-MM-dd'))

    const data = {
      name: logic.name,
      code: state.code,
      price: Number(remaskCaractersAll(logic.nominalPrice)) / 100,
      nominal_price: Number(remaskCaractersAll(logic.nominalPrice)) / 100,
      observation,
      status: Number(state.status),
      competence_date: FormatCompetence,
      due_date: logic.dueDateState,
      share_number: state.share_number,
      total_of_postings: state.total_of_postings,
      category: {
        id: Number(category.value)
      },
      supplier_posting: {
        id: Number(supplierPosting.value)
      },
      cost_center: {
        id: Number(cost_center.value)
      },
      posting_clinic: state.posting_clinic,
      posting_group: state.posting_group
    }

    updatePosting.mutateAsync(data)
  }

  if (state === null) {
    return (
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('update.title')}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/finances/bills-to-pay">
                <Text fontSize="md" fontWeight="bold">
                  Voltar
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handleUpdateBillsToPay)}
        >
          <Flex w="100vw" h="100vh" align="center" justify="center">
            <Spinner color="blue" />
          </Flex>
        </Flex>
      </Box>
    )
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('update.title')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/finances/bills-to-pay">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleUpdateBillsToPay)}
      >
        <Stack spacing="4" flex="1">
          <CardContainer title={t('update.container1.title')}>
            <Stack position="absolute" direction="row" right="4" top="4">
              <PermissionComponent spiCode="BILLS_TO_PAY" ipCode="EDIT">
                <Flex flexDirection="row" align="center" justify="center">
                  {/*                   <Link
                    to={{
                      pathname: `/finances/bills-to-pay/update/${state.id}`,
                      state
                    }}
                  >
                    <Tooltip
                      label={t('updateTitle')}
                      aria-label={t('updateTitle')}
                      hasArrow
                      placement="top-start"
                    >
                      <Box ml="1rem" w="24px">
                        <FiEdit
                          color="#9699B0"
                          size={16}
                          style={{ cursor: 'pointer' }}
                        />
                      </Box>
                    </Tooltip>
                  </Link> */}
                  <Link
                    to={{
                      pathname: `/finances/bills-to-pay/files/${state.id}`,
                      state
                    }}
                  >
                    <Tooltip
                      label={t('file')}
                      aria-label={t('updateTitle')}
                      hasArrow
                      placement="top-start"
                    >
                      <Box ml="1rem" w="24px">
                        <FiFile
                          color="#9699B0"
                          size={16}
                          style={{ cursor: 'pointer' }}
                        />
                      </Box>
                    </Tooltip>
                  </Link>
                  <Link
                    to={{
                      pathname: `/finances/bills-to-pay/payment/${state.id}`,
                      state
                    }}
                  >
                    <Tooltip
                      label={t('payment')}
                      aria-label={t('payment')}
                      hasArrow
                      placement="top-start"
                    >
                      <Box ml="1rem" w="24px">
                        <AiOutlineDollarCircle
                          color="#9699B0"
                          size={20}
                          style={{ cursor: 'pointer' }}
                        />
                      </Box>
                    </Tooltip>
                  </Link>
                </Flex>
              </PermissionComponent>
            </Stack>
            <SimpleGrid minChildWidth="320px">
              <Stack direction="row" my="2" spacing="6">
                <Box w="320px">
                  <Input
                    label={t('update.container1.i1')}
                    placeholder={t('update.container1.placeholder.name')}
                    isRequired
                    defaultValue={state?.name}
                    value={logic.name}
                    onChange={(event) => {
                      logic.setName(event.target.value.toUpperCase())
                    }}
                    name="name"
                    error={errors.name}
                  />
                </Box>
                <Box w="320px">
                  <SelectComponent
                    title={t('update.container1.i2')}
                    isRequired
                    options={service.newSuppliersFormats}
                    value={supplierPosting}
                    name="supplier_posting"
                    placeholder={t('update.container1.placeholder.suppliers')}
                    onChange={(event: any) => setSupplierPosting(event)}
                  />
                </Box>
                <Box w="320px">
                  <SelectComponent
                    isRequired
                    options={service.newCategoriesFormats}
                    name="category"
                    onChange={(event: any) => setCategory(event)}
                    value={category}
                    title={t('update.container1.i3')}
                    placeholder={t('update.container1.placeholder.category')}
                    error={errors.category}
                  />
                </Box>
                <Box w="320px">
                  <SelectComponent
                    isRequired
                    options={service.newCostsFormats}
                    value={cost_center}
                    name="cost_center"
                    onChange={(event: any) => setCostCenter(event)}
                    placeholder={t('update.container1.placeholder.cost_center')}
                    title={t('update.container1.i4')}
                    error={errors.cost_center}
                  />
                </Box>
              </Stack>
            </SimpleGrid>
            <Flex />
          </CardContainer>

          <CardContainer title={t('update.container2.title')}>
            <SimpleGrid minChildWidth="320px">
              <Stack direction="row" my="2" spacing="6">
                <Box w="100px">
                  <Input
                    w="100px"
                    label={t('update.container2.i1')}
                    defaultValue={state?.code}
                    isDisabled
                    name="code"
                  />
                </Box>
                <Box w="100px">
                  <Input
                    w="100px"
                    label={t('update.container2.i2')}
                    defaultValue={state?.pos}
                    isDisabled
                    name="share_number"
                  />
                </Box>
                <Box>
                  <Input
                    name="competence_date"
                    defaultValue={state?.competence_date}
                    value={logic.competenceDateState}
                    onChange={(event: any) => logic.changeCompetenceDate(event)}
                    label={t('update.container2.i3')}
                  />
                </Box>
                <Box>
                  <Input
                    defaultValue={state?.due_date}
                    name="due_date"
                    type="date"
                    label={t('update.container2.i4')}
                    value={logic.dueDateState}
                    onChange={(event) => logic.changeDueDate(event)}
                  />
                </Box>
                <Box w="320px">
                  <TextFieldControlled
                    name="nominal_price"
                    label={t('update.container2.i6')}
                    mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                    defaultValue={state?.nominal_price}
                    initialValue={logic.nominalPrice}
                    inputOnChange={(event) =>
                      logic.setNominalPrice(event.currentTarget.value)
                    }
                  />
                </Box>
              </Stack>
            </SimpleGrid>
            <Flex flexDirection="column" mx="auto" w="100%">
              <Text my="2" fontSize="sm">
                {t('update.container2.i10')}
              </Text>
              <Textarea
                px="6"
                height="40px"
                placeholder={t('update.container2.observation')}
                {...register('observation')}
                defaultValue={state?.observation}
              />
            </Flex>
          </CardContainer>

          <Flex
            mt="6"
            w="100%"
            align="flex-end"
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
              onClick={() => {
                setRedirect(false)
              }}
            >
              {t('update.buttonNoRedirect')}
            </Button>
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
              onClick={() => {
                setRedirect(true)
              }}
            >
              {t('update.buttonRedirect')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
