import { apiAuth } from '../../services/apiAuth'

export type EquipmentsData = {
  id: string
  name: string
}

export type ResponseGetEquipments = {
  equipments: EquipmentsData[]
  totalCountOfPage: number
}

export const getEquipments = async (
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetEquipments> => {
  try {
    const { data } = await apiAuth.get(`clinics/equipments/`, {
      params: {
        page,
        name: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data?.count
    const equipments: EquipmentsData[] = data?.results

    return {
      equipments,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const equipments: EquipmentsData[] = []
    const totalCountOfPage = 0
    return {
      equipments,
      totalCountOfPage
    }
  }
}
