/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type ProductsData = {
  name: string
  sale_price: number
  id: number
  brand: string
}

export type ResponseGetProducts = {
  products: ProductsData[]
  totalCountOfPage: number
}

export const getProducts = async (
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetProducts> => {
  try {
    const { data } = await apiAuth.get(`clinics/groups-product/`, {
      params: {
        page,
        name__icontains: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const products: ProductsData[] = data.results

    return {
      products,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const products: ProductsData[] = []
    const totalCountOfPage = 0
    return {
      products,
      totalCountOfPage
    }
  }
}

export function useProducts(
  page: number,
  functionLoading: Promise<ResponseGetProducts>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['products', page, search, isActive], () => functionLoading)
}
