/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type RealizedCashFlowData = {}

export type ResponseGetCashFlow = {
  products: RealizedCashFlowData[]
  totalCountOfPage: number
}

export const getRealizedCashFlow = async (
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetCashFlow> => {
  try {
    const { data } = await apiAuth.get(``, {
      params: {
        page,
        name__icontains: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const products: RealizedCashFlowData[] = data.results

    return {
      products,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const products: RealizedCashFlowData[] = []
    const totalCountOfPage = 0
    return {
      products,
      totalCountOfPage
    }
  }
}

export function useRealizedCashFlow(
  page: number,
  functionLoading: Promise<ResponseGetCashFlow>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['realizedCashFlow', page, search, isActive],
    () => functionLoading
  )
}
