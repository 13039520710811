import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 10, // 10 minutes
      staleTime: 1000 * 60 * 4, // 4 minutes
      optimisticResults: true,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      refetchInterval: 1000 * 60 * 4, // 4 minutes
      retryOnMount: false
    }
  }
})
