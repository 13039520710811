/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { remaskCaractersAll } from '../fns/removeCaracters'

export default (mon: any): string => {
  mon = mon.replace('%', '')

  mon = mon.replace(/[^\d]/g, '')
  mon = mon.substring(0, 5)

  if (mon.length > 3) {
    mon = mon.replace(/^(\d*)(\d{2})$/, '$1.$2')
  } else if (mon.length > 0) {
    mon = (mon.length === 1 ? '00' : '0') + mon
    mon = mon.replace(/^(\d*)(\d{2})$/, '$1.$2')
  }
  while (mon[0] === '0' && mon[1] !== '.') {
    mon = mon.substring(1, mon.length)
  }

  mon = mon.replace(/(\d)(?=(\d{3})+,)/g, '$1,')

  if (mon === '' || mon === '0.00' || mon === '') {
    return ''
  }

  const number = Number(remaskCaractersAll(mon)) / 100
  if (number <= 100) {
    return `${mon}`
  }

  return ''
}
