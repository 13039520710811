import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Flex,
  Button,
  Stack,
  Text,
  Link as LinkChakra,
  useToast
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '../../components/Form/Input'
import { useAuth } from '../../hooks/auth'
import { Logo } from '../../components/atoms/Logo'
import { api } from '../../services/api'
import { ArrowBack } from '../../components/atoms/arrowBack'

type ForgotPasswordData = {
  email: string
}

const forgotPasswordFormSchema = yup.object().shape({
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido')
})

export const ForgotPassword = () => {
  const navigation = useHistory()
  const toast = useToast()
  const { register, handleSubmit, formState } = useForm<ForgotPasswordData>({
    resolver: yupResolver(forgotPasswordFormSchema)
  })
  const { errors } = formState

  const handleForgotPassword: SubmitHandler<ForgotPasswordData> = useCallback(
    async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 2000))

      const { email } = values

      try {
        const response = await api.post('reset-password/', { email })

        console.log(response)

        // navigation.push('/');
      } catch (err: any) {
        if (err.message.indexOf('500') === -1) {
          toast({
            title: 'Credenciais inválidas!',
            description: 'Tente novamente mais tarde.',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top'
          })
        }
      }
    },
    [toast]
  )

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex direction="column" width="100%" maxWidth={480} my="4">
        <Flex marginBottom="4" p={[8, 0]} paddingBottom="0">
          <ArrowBack href="/" />
        </Flex>
        <Flex
          as="form"
          width="100%"
          maxWidth={480}
          p="8"
          border={['none', '1px']}
          borderColor={['none', 'gray.100']}
          borderRadius={[0, 8]}
          flexDir="column"
          onSubmit={handleSubmit(handleForgotPassword)}
        >
          <Logo />
          <Stack>
            <Input
              label="E-mail"
              type="email"
              error={errors.email}
              {...register('email')}
            />
          </Stack>

          <Button
            type="submit"
            mt="6"
            colorScheme="blue"
            size="lg"
            isLoading={formState.isSubmitting}
          >
            Recuperar senha
          </Button>
          {/* <Flex my="8" align="center" justify="center">
            <Text>
              Ainda não tem uma conta?{' '}
              <LinkChakra as={Link} to="/register" color="pink.500">
                Criar conta
              </LinkChakra>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  )
}
