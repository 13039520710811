import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  ListItem,
  Spinner,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  useToast,
  VStack
} from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiReset } from 'react-icons/bi'
import { FiEye, FiEyeOff, FiUser } from 'react-icons/fi'
import { Link, useHistory, useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import { Element } from '../../components/DynamicForms/Element'
import { Input } from '../../components/Form/Input'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { MasksTypes } from '../../components/Form/TextFieldControlled'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { ModalContentComponent } from '../../components/Modal/modalContent'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Draft } from '../../components/molecules/Draft'
import { useAuth } from '../../hooks/auth'
import { useModal } from '../../hooks/useModal'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { useLogic } from './logic'
import { useService } from './services'

interface ParamsProps {
  id: string
}

interface Questions {
  question: string
  options: string | undefined
  is_required: boolean
  is_multiple: boolean
  answer: string | undefined
}

type QuestionsTokens = Array<Questions>

type ExcludeType = 'token' | 'exam' | 'prescription'

type Template = {
  id: number
  name: string
}

type Documents = {
  id: number
  name_ptbr: string
  name_en: string
  acronym: string
  index: Number
  regex: MasksTypes
}

interface Client {
  id: string
  name: string
  is_active: boolean
  image: string
  birth_date: string
  social_name: string
  sex: number
  document: Documents
  document_number: string
  observation: string
  alerts: string
  patient_tags: Array<number>
  origins: Array<number>
  role: string
  address: {
    zip_code: string
    street: string
    district: string
    city: string
    country: string
    state: string
    number: string
    observation: string
  }
  created_by: {}
  update_by: {}
}

type ClientData = Client | null

export const RegisterClinic: React.FC = () => {
  const [t] = useTranslation('pageRegisterClinic')
  const { decodeToken } = useAuth()
  const toast = useToast()
  const router = useHistory()
  const { id } = useParams<ParamsProps>()
  const { setTabIndexClient } = useModal()
  const services = useService()
  const logic = useLogic()
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { state }: any = router.location
  const { errors } = formState
  const [isShow, setIsShow] = useState(true)

  // modal finished
  const [isFinished, setIsFinished] = useState(false)

  // modal exclude itens
  const [isExcluded, setIsExcluded] = useState(false)
  const [indexExclude, setIndexExclude] = useState(0)
  const [typeExclude, setTypeExclude] = useState<ExcludeType>('token')
  const [client, setClient] = useState<ClientData | null>(null)
  const [ageUser, setAgeUser] = useState('')

  async function getClientById(id: string) {
    try {
      const { data } = await apiAuth.get(`clinics/patients/${id}/`)

      return data
    } catch (error: any) {
      return {
        error: true,
        errorMessage: error.response?.data?.message
      }
    }
  }

  // calculateAge functions
  function fragmentDate(data: string) {
    const dia = Number(data.substring(0, 2))
    const mes = Number(data.substring(3, 5))
    const ano = Number(data.substring(6, 12))

    return {
      dia,
      mes,
      ano
    }
  }

  function calculateAge(data: string) {
    // data atual
    const dateCurrent = new Date()
    const yearCurrent = dateCurrent.getFullYear()
    const mothCurrent = dateCurrent.getMonth() + 1
    const dayCurrent = dateCurrent.getDate()

    // data nascimento
    const { dia, mes, ano } = fragmentDate(data)

    let years = Number(yearCurrent) - Number(ano)

    if (mothCurrent < mes || (mothCurrent === mes && dayCurrent < dia)) {
      years -= 1
    }

    return years < 0 ? 0 : years
  }

  useEffect(() => {
    // function getInfo for view client
    const GetClient = async () => {
      const data: ClientData = await getClientById(id)

      if (data?.birth_date) {
        const date = format(
          new Date(
            Number(data?.birth_date?.substring(0, 4)),
            Number(data?.birth_date?.substring(5, 7)) - 1,
            Number(data?.birth_date?.substring(8, 10))
          ),
          'dd/MM/yyyy'
        )

        const age = calculateAge(date)
        setAgeUser(String(age))
      }

      setClient(data)
    }

    GetClient()
  }, [])

  // modal functions
  function openModalExclude() {
    setIsExcluded(true)
  }

  function closeModalExclude() {
    setIsExcluded(false)
  }

  function openModalFinished() {
    setIsFinished(true)
  }

  function closeModalFinished() {
    setIsFinished(false)
  }

  function countTokens(isNumber?: boolean) {
    let count = 0

    const allTokens = logic.tokens.map((token, index) => {
      let questions: QuestionsTokens = []

      if (token?.selected !== null) {
        count += 1
      }

      token?.form?.groups?.forEach((group) => {
        group.fields.forEach((field) => {
          const choices = field.choices?.map((choice) => {
            return choice.label
          })

          const options = choices?.join(',') || undefined

          questions = [
            ...questions,
            {
              question: field.label,
              is_required: field.is_required,
              options,
              is_multiple: field.type === 'radio',
              answer: field.value || undefined
            }
          ]
        })
      })

      return {
        name: token.form?.name || 'Blank',
        information: logic.contentsToken[index]?.content || null,
        questions
      }
    })

    allTokens.forEach((token) => {
      if (token?.information !== null) {
        if (token?.information !== '<p></p>') {
          if (token?.information !== '<p></p>\n') {
            count += 1
          }
        }
      }
    })

    if (count === 0 && !isNumber) {
      return ''
    }

    return count
  }

  function countPrescriptions(isNumber?: boolean) {
    let count = 0

    const allFormatPrescriptions = logic.prescriptions.map(
      (prescription, index) => {
        return {
          name: prescription.selected?.label || 'Blank',
          information: logic.contentsPrescriptions[index]?.content || null
        }
      }
    )

    allFormatPrescriptions.forEach((token) => {
      if (token?.information !== null) {
        if (token?.information !== '<p></p>') {
          if (token?.information !== '<p></p>\n') {
            count += 1
          }
        }
      }
    })

    if (count === 0 && !isNumber) {
      return ''
    }

    return count
  }

  function countExams(isNumber?: boolean) {
    let count = 0

    const allFormatExams = logic.examsRequest.map((exam, index) => {
      return {
        name: exam.selected?.label || 'Blank',
        information: logic.contentsExams[index]?.content || null
      }
    })

    allFormatExams.forEach((token) => {
      if (token?.information !== null) {
        if (token?.information !== '<p></p>') {
          if (token?.information !== '<p></p>\n') {
            count += 1
          }
        }
      }
    })

    if (count === 0 && !isNumber) {
      return ''
    }

    return count
  }

  function countIndications(isNumber?: boolean) {
    let count = 0

    const allIndications = logic.indications

    allIndications.forEach((token) => {
      if (token?.service?.value) {
        count += 1
      }
    })

    if (count === 0 && !isNumber) {
      return ''
    }

    return count
  }

  function ableButton() {
    let isDisabled = false

    if (countExams(true) === 0) {
      if (countPrescriptions(true) === 0) {
        if (countTokens(true) === 0) {
          if (countIndications(true) === 0) {
            isDisabled = true
          }
        }
      }
    }

    return isDisabled
  }

  // format For finalizers MedicalRecords and isDraft = false
  // tokens
  function formatTokens() {
    const allTokens = logic.tokens.map((token, index) => {
      let questions: QuestionsTokens = []

      token?.form?.groups?.forEach((group) => {
        group.fields.forEach((field) => {
          const choices = field.choices?.map((choice) => {
            return choice.label
          })

          const options = choices?.join(',') || undefined

          questions = [
            ...questions,
            {
              question: field.label,
              is_required: field.is_required,
              options,
              is_multiple: field.type === 'radio',
              answer: field.value || undefined
            }
          ]
        })
      })

      return {
        name: token.form?.name || 'Blank',
        information: logic.contentsToken[index].content || null,
        questions
      }
    })

    const validationTokens = allTokens?.map((token) => {
      if (token?.questions?.length > 0) {
        return token
      }

      if (token?.questions?.length <= 0 && token?.information === null) {
        return false
      }

      if (token?.questions?.length <= 0 && token?.information === '<p></p>\n') {
        return false
      }

      if (token?.questions?.length <= 0 && token?.information === '<p></p>') {
        return false
      }

      if (token?.information === null) {
        return false
      }

      if (token?.information === '<p></p>\n') {
        return false
      }

      return token
    })

    const returnToken = validationTokens?.filter(Boolean)

    return returnToken
  }

  // prescriptions
  function formatPrescriptions() {
    const allFormatPrescriptions = logic.prescriptions.map(
      (prescription, index) => {
        return {
          name: prescription.selected?.label || 'Blank',
          information: logic.contentsPrescriptions[index].content || null
        }
      }
    )

    const validationPrescriptions = allFormatPrescriptions?.map((token) => {
      if (token?.information === null) {
        return false
      }

      if (token?.information === '<p></p>\n') {
        return false
      }

      if (token?.information === '<p></p>') {
        return false
      }

      return token
    })

    const returnPrescriptions = validationPrescriptions?.filter(Boolean)

    return returnPrescriptions
  }

  // exams
  function formatExams() {
    const allFormatExams = logic.examsRequest.map((exam, index) => {
      return {
        name: exam.selected?.label || 'Blank',
        information: logic.contentsExams[index].content || null
      }
    })

    const validationExams = allFormatExams?.map((token) => {
      if (token?.information === null) {
        return false
      }

      if (token?.information === '<p></p>\n') {
        return false
      }

      if (token?.information === '<p></p>') {
        return false
      }

      return token
    })

    const returnExams = validationExams?.filter(Boolean)

    return returnExams
  }

  // indications
  function formatIndications() {
    const allFormatIndications = logic.indications.map((indication) => {
      if (indication.service?.value) {
        return {
          service_name: indication.service?.label || undefined,
          observation: indication.observation || null,
          indication_date: `${indication?.indicationDate}T00:00:00`,
          status: 1,
          service: {
            id: indication.service?.value || undefined
          }
        }
      }
      return undefined
    })

    // remove arrays invalides
    const filterIndications = allFormatIndications.filter(Boolean)

    return filterIndications || []
  }

  const handlerFinalizeRegister = async () => {
    const prescriptions = formatPrescriptions()
    const exams = formatExams()
    const indicationsValue = formatIndications()
    const forms = formatTokens()

    const payloadMedicalRecords = {
      finish_date: format(new Date(), "yyyy-MM-dd'T'k':'mm':'ss"),
      start_date: format(new Date(), "yyyy-MM-dd'T'k':'mm':'ss"),
      is_draft: false,
      prescriptions,
      patient: {
        id
      },
      specialist: {
        id: decodeToken?.user_id
      },
      exams,
      forms,
      indications: indicationsValue
    }

    try {
      await apiAuth.post(`clinics/records/`, payloadMedicalRecords)

      toast({
        description: `Registro clínico,
        finalizados e salvos com sucesso, estamos lhe direcionando!`,
        duration: 3000,
        status: 'success',
        title: 'Registro Finalizado!'
      })

      closeModalFinished()

      setTimeout(() => {
        router.push(`/clients/update/${id}`)
      }, 2000)
    } catch (error: any) {
      toast({
        description: `${error.response?.data?.detail}`,
        duration: 3000,
        status: 'error',
        title: 'Aconteceu um erro!'
      })
    }
  }

  const handlerChangeFormValues = (idField: number, event: any) => {
    const newTokens = [...logic.tokens]
    newTokens.forEach((token) => {
      token.form?.groups.forEach((group) => {
        group.fields.forEach((field) => {
          if (field.id === idField) {
            if (field.type === 'radio') {
              field['default_value'] = event
              field['value'] = event.join(',')
            } else {
              field['value'] = event?.target.value
            }
          }
        })
      })
    })

    logic.setTokens(newTokens)
  }

  return (
    <>
      <LayoutDefault
        title={`${t('title')} - ${client?.name || ''}`}
        IconHeader={<FiUser />}
        onSubmit={undefined}
        urlBack={`/clients/update/${id}`}
      >
        <Stack justify="space-evenly" spacing="4" w="100%">
          {client === null ? (
            <Flex w="100%" alignItems="center" justifyContent="center">
              <Spinner color="blue.500" />
            </Flex>
          ) : (
            <CardContainer title={t('container1.title')}>
              <Stack
                position="absolute"
                alignItems="center"
                justifyContent="center"
                direction="row"
                spacing={4}
                top="2"
                right="4"
              >
                {/* <Link
                    to={{
                      pathname: `/clients/update/${id}`,
                      state: {
                        isBackAttendance: true
                      }
                    }}
                    onClick={() => setTabIndexClient(2)}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      _hover={{ cursor: 'pointer' }}
                    >
                      {t('links.record')}
                    </Text>
                </Link>
                <Link
                    to={{
                      pathname: `/clients/update/${id}`,
                      state: {
                        isBackAttendance: true
                      }
                    }}
                    onClick={() => setTabIndexClient(3)}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      _hover={{ cursor: 'pointer' }}
                    >
                      {t('links.indications')}
                    </Text>
                </Link>
                <Link
                    to={{
                      pathname: `/clients/update/${id}`,
                      state: {
                        isBackAttendance: true
                      }
                    }}
                    onClick={() => setTabIndexClient(5)}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      _hover={{ cursor: 'pointer' }}
                    >
                      {t('links.gallery')}
                    </Text>
                </Link> */}
                <Flex
                  bg={isShow ? 'green.500' : 'red.500'}
                  w="20px"
                  h="20px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="full"
                >
                  <Icon
                    color="white"
                    as={isShow ? FiEye : FiEyeOff}
                    onClick={() => setIsShow(!isShow)}
                  />
                </Flex>
              </Stack>
              {isShow && (
                <VStack w="100%" spacing="4">
                  <HStack w="100%" spacing="4">
                    <Text fontWeight="hairline">{t('container1.name')}</Text>
                    <Text fontWeight="hairline">{client?.name}</Text>
                    <Text fontWeight="hairline">
                      {t('container1.birthDate')}
                    </Text>
                    {client?.birth_date ? (
                      <Text fontWeight="hairline">
                        {format(
                          new Date(
                            Number(client?.birth_date.substring(0, 4)),
                            Number(client?.birth_date.substring(5, 7)) - 1,
                            Number(client?.birth_date.substring(8, 10))
                          ),
                          'dd/MM/yyyy'
                        )}
                      </Text>
                    ) : (
                      <Text fontWeight="hairline">---</Text>
                    )}
                    <Text fontWeight="hairline">{t('container1.age')}</Text>
                    <Text fontWeight="hairline">{ageUser}</Text>
                  </HStack>
                  <HStack w="100%" spacing="4">
                    {client?.alerts && (
                      <>
                        <Text fontWeight="hairline">
                          {t('container1.observationClient')}
                        </Text>
                        <Text fontWeight="hairline">{client?.alerts}</Text>
                      </>
                    )}
                  </HStack>
                </VStack>
              )}
            </CardContainer>
          )}
          {/* tabs */}
          <Tabs
            isFitted
            defaultIndex={0}
            variant="soft-rounded"
            colorScheme="blue"
            w="100%"
          >
            <TabList>
              <Tab>
                Fichas{' '}
                {countTokens() > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {countTokens()}
                  </Flex>
                )}
              </Tab>
              <Tab>
                Receitas{' '}
                {countPrescriptions() > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {countPrescriptions()}
                  </Flex>
                )}
              </Tab>
              <Tab>
                Solicitações de exames{' '}
                {countExams() > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {countExams()}
                  </Flex>
                )}
              </Tab>
              {/* <Tab>
                Indicações{' '}
                {countIndications() > 0 && (
                  <Flex
                    p="2px"
                    w="20px"
                    h="20px"
                    alignItems="center"
                    justifyContent="center"
                    bg="red.500"
                    color="white"
                    mx="2"
                    borderRadius="full"
                  >
                    {countIndications()}
                  </Flex>
                )}
              </Tab> */}
            </TabList>

            <TabPanels>
              <TabPanel>
                <CardContainer title={t('container3.title')}>
                  <VStack spacing="4" w="100%" mb="2">
                    {logic.tokens.length > 0 &&
                      logic.tokens.map((item, index: number) => (
                        <>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            justifyContent="flex-start"
                            key={item.id}
                          >
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={4}
                              mb="6px"
                            >
                              <SelectComponent
                                name="tokenSelected"
                                placeholder={t(
                                  'container3.placeholder.content'
                                )}
                                options={
                                  item.isPreference === true
                                    ? services.formsSelectPreference
                                    : services.formsSelect
                                }
                                value={item.selected}
                                onChange={(event: any) =>
                                  logic.handlerSelectFormToken(item.id, event)
                                }
                              />
                              <Tooltip
                                label={t('preference')}
                                aria-label="preference"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Switch
                                    name="tokensIsPreference"
                                    isChecked={item.isPreference}
                                    onChange={(event) =>
                                      logic.handlerClickIsPreferenceToken(
                                        item.id,
                                        item.isPreference
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              {item.isVisible ? (
                                <Icon
                                  as={FiEye}
                                  _hover={{ color: 'red', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleToken(
                                      item.id,
                                      item.isVisible
                                    )
                                  }}
                                />
                              ) : (
                                <Icon
                                  as={FiEyeOff}
                                  _hover={{ color: 'green', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleToken(
                                      item.id,
                                      item.isVisible
                                    )
                                  }}
                                />
                              )}
                              <Tooltip
                                label={t('container3.blank')}
                                aria-label="reset"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Icon
                                    as={BiReset}
                                    fontSize="20px"
                                    onClick={() =>
                                      logic.handlerClearSelectAndBackToDraftToken(
                                        item.id
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              <CloseButton
                                _hover={{ bg: 'red', color: 'white' }}
                                onClick={() => {
                                  setTypeExclude('token')
                                  setIndexExclude(index)
                                  openModalExclude()
                                }}
                              />
                            </Flex>
                            {item.isVisible && !item.form && (
                              <Draft
                                defaultValue={
                                  logic.contentsToken[index]?.content
                                }
                                getValue={(value: any) => {
                                  logic.getValueDraftTokenById(value, index)
                                }}
                              />
                            )}
                            {item.isVisible && item.form && (
                              <Flex w="100%" p="2" gap={2} flexDir="column">
                                <Divider />
                                {item.form.groups.map((group, indexGroup) => (
                                  <Flex
                                    flexDir="column"
                                    w="100%"
                                    key={group.id}
                                  >
                                    <Heading as="h1" size="sm" noOfLines={1}>
                                      {group.name}
                                    </Heading>
                                    {group.fields.map((field, indexField) => (
                                      <Element
                                        key={field.name || field.id}
                                        field={field}
                                        handlerChangeFormValues={
                                          handlerChangeFormValues
                                        }
                                      />
                                    ))}
                                  </Flex>
                                ))}
                              </Flex>
                            )}
                          </Flex>
                        </>
                      ))}
                  </VStack>
                  <Flex w="100%" justifyContent="flex-start">
                    <Button
                      size="sm"
                      bg="blue.300"
                      color="white"
                      onClick={() => logic.handlerAddNewToken()}
                    >
                      {t('container3.btnAdd')}
                    </Button>
                  </Flex>
                </CardContainer>
              </TabPanel>
              <TabPanel>
                <CardContainer title={t('container4.title')}>
                  <VStack spacing="4" w="100%" mb="2">
                    {logic.prescriptions.length > 0 &&
                      logic.prescriptions.map((pres, index: number) => (
                        <>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            justifyContent="flex-start"
                            key={pres.id}
                          >
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={4}
                              mb="6px"
                            >
                              <SelectComponent
                                name="prescriptionSelected"
                                placeholder={t(
                                  'container4.placeholder.content'
                                )}
                                options={
                                  pres.isPreference === true
                                    ? services.prescriptionsSelectPreference
                                    : services.prescriptionsSelect
                                }
                                value={pres.selected}
                                onChange={(event: any) =>
                                  logic.handlerSelectPrescription(
                                    pres.id,
                                    index,
                                    event
                                  )
                                }
                              />
                              {pres.isChange && (
                                <Text fontWeight="hairline" fontSize="12px">
                                  {t('container4.isChange')}
                                </Text>
                              )}
                              <Tooltip
                                label={t('preference')}
                                aria-label="preference"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Switch
                                    name="prescriptionsIsPreference"
                                    isChecked={pres.isPreference}
                                    onChange={(event) =>
                                      logic.handlerClickIsPreferencePrescription(
                                        pres.id,
                                        pres.isPreference
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              {pres.isVisible ? (
                                <Icon
                                  as={FiEye}
                                  _hover={{ color: 'red', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() =>
                                    logic.handlerClickIsVisiblePrescription(
                                      pres.id,
                                      pres.isVisible
                                    )
                                  }
                                />
                              ) : (
                                <Icon
                                  as={FiEyeOff}
                                  _hover={{ color: 'green', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() =>
                                    logic.handlerClickIsVisiblePrescription(
                                      pres.id,
                                      pres.isVisible
                                    )
                                  }
                                />
                              )}
                              <CloseButton
                                _hover={{ bg: 'red', color: 'white' }}
                                onClick={() => {
                                  setTypeExclude('prescription')
                                  setIndexExclude(index)

                                  openModalExclude()
                                }}
                              />
                            </Flex>
                            {pres.isVisible && (
                              <Draft
                                defaultValue={
                                  logic.contentsPrescriptions[index]?.content
                                }
                                getValue={(value: any) => {
                                  logic.getValueDraftPrescriptionById(
                                    value,
                                    index
                                  )
                                }}
                                backContentRender={
                                  logic.contentsPrescriptions[index]
                                    ?.backContentRender
                                }
                              />
                            )}
                          </Flex>
                        </>
                      ))}
                  </VStack>
                  <Flex w="100%" justifyContent="flex-start">
                    <Button
                      size="sm"
                      bg="blue.300"
                      color="white"
                      onClick={() => {
                        logic.handlerAddNewPrescription()
                      }}
                    >
                      {t('container4.btnAdd')}
                    </Button>
                  </Flex>
                </CardContainer>
              </TabPanel>
              <TabPanel>
                <CardContainer title={t('container5.title')}>
                  <VStack spacing="4" w="100%" mb="2">
                    {logic.examsRequest.length > 0 &&
                      logic.examsRequest.map((exam, index: number) => (
                        <>
                          <Flex
                            flexDirection="column"
                            w="100%"
                            justifyContent="flex-start"
                            key={exam.id}
                          >
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={4}
                              mb="6px"
                            >
                              <SelectComponent
                                name="examSelected"
                                placeholder={t(
                                  'container5.placeholder.content'
                                )}
                                options={
                                  exam.isPreference === true
                                    ? services.examsSelectPreference
                                    : services.examsSelect
                                }
                                value={exam.selected}
                                onChange={(event: any) =>
                                  logic.handlerSelectExamRequest(
                                    exam.id,
                                    index,
                                    event
                                  )
                                }
                              />
                              {exam.isChange && (
                                <Text fontWeight="hairline" fontSize="12px">
                                  {t('container5.isChange')}
                                </Text>
                              )}
                              <Tooltip
                                label={t('preference')}
                                aria-label="preference"
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <Switch
                                    name="prescriptionsIsPreference"
                                    isChecked={exam.isPreference}
                                    onChange={(event) =>
                                      logic.handlerClickIsPreferenceExamRequest(
                                        exam.id,
                                        exam.isPreference
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                              {exam.isVisible ? (
                                <Icon
                                  as={FiEye}
                                  _hover={{ color: 'red', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleExamRequest(
                                      exam.id,
                                      exam.isVisible
                                    )
                                  }}
                                />
                              ) : (
                                <Icon
                                  as={FiEyeOff}
                                  _hover={{ color: 'green', cursor: 'pointer' }}
                                  fontSize="20px"
                                  onClick={() => {
                                    logic.handlerClickIsVisibleExamRequest(
                                      exam.id,
                                      exam.isVisible
                                    )
                                  }}
                                />
                              )}
                              <CloseButton
                                _hover={{ bg: 'red', color: 'white' }}
                                onClick={() => {
                                  setTypeExclude('exam')
                                  setIndexExclude(index)

                                  openModalExclude()
                                }}
                              />
                            </Flex>
                            {exam.isVisible && (
                              <Draft
                                defaultValue={
                                  logic.contentsExams[index]?.content
                                }
                                getValue={(value: any) => {
                                  logic.getValueDraftExamRequestById(
                                    value,
                                    index
                                  )
                                }}
                                backContentRender={
                                  logic.contentsExams[index]?.backContentRender
                                }
                              />
                            )}
                          </Flex>
                        </>
                      ))}
                  </VStack>
                  <Flex w="100%" justifyContent="flex-start">
                    <Button
                      size="sm"
                      bg="blue.300"
                      color="white"
                      onClick={() => {
                        logic.handlerAddNewExamRequest()
                      }}
                    >
                      {t('container5.btnAdd')}
                    </Button>
                  </Flex>
                </CardContainer>
              </TabPanel>
              {/* <TabPanel>
                <CardContainer title={t('container6.title')}>
                  <Stack
                    w="100%"
                    direction="column"
                    my="2"
                    spacing="6"
                    position="relative"
                  >
                    {logic.indications?.map((indication, index: number) => {
                      return (
                        <Flex
                          w="100%"
                          position="relative"
                          direction="row"
                          p="2"
                          key={indication.id}
                        >
                          <Box w="50%" mx="2">
                            <SelectComponent
                              name="serviceIndication"
                              placeholder={t('container6.placeholder.service')}
                              title={t('container6.service')}
                              options={services.allIndications}
                              value={indication.service}
                              onChange={(event: any) =>
                                logic.handlerSelectIndication(
                                  indication.id,
                                  event
                                )
                              }
                            />
                          </Box>
                          <Box w="20%" mx="2">
                            <Input
                              name="dateIndication"
                              label={t('container6.date')}
                              type="date"
                              isRequired
                              value={indication.indicationDate}
                              onChange={(event) =>
                                logic.handlerChangeIndicationsDate(
                                  indication.id,
                                  event
                                )
                              }
                            />
                          </Box>
                          <Box w="30%" mx="2">
                            <Input
                              name="observationIndication"
                              label={t('container6.observation')}
                              value={indication.observation}
                              onChange={(event) =>
                                logic.handlerChangeObservationOfIndication(
                                  indication.id,
                                  event
                                )
                              }
                              placeholder={t(
                                'container6.placeholder.observation'
                              )}
                            />
                          </Box>
                          <Box>
                            <Button
                              colorScheme="red"
                              size="xs"
                              onClick={() =>
                                logic.handlerRemoveIndications(index)
                              }
                            >
                              x
                            </Button>
                          </Box>
                        </Flex>
                      )
                    })}
                    <Flex w="100%">
                      <Button
                        bg="blue.300"
                        color="white"
                        size="sm"
                        onClick={() => logic.handlerAddNewIndications()}
                      >
                        Adicionar indicação
                      </Button>
                    </Flex>
                  </Stack>
                </CardContainer>
              </TabPanel> */}
            </TabPanels>
          </Tabs>

          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            <Button
              colorScheme="red"
              size="md"
              onClick={() => openModalFinished()}
              isDisabled={ableButton()}
            >
              Finalizar Registro
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalArrivedTime
        isOpen={isFinished}
        onClose={() => {
          closeModalFinished()
        }}
        onUpdate={() => {
          handlerFinalizeRegister()
        }}
        title={t('modalFinishedTitle')}
      >
        <VStack w="100%" spacing="4" />
      </ModalArrivedTime>
      <ModalConfirmExclude
        isOpen={isExcluded}
        onClose={() => {
          closeModalExclude()
        }}
        title={t('modalExclude.title')}
        onConfirmExclude={() => {
          switch (typeExclude) {
            case 'token':
              logic.handlerRemoveToken(indexExclude)
              break
            case 'exam':
              logic.handlerRemoveExamRequest(indexExclude)
              break
            case 'prescription':
              logic.handlerRemovePrescription(indexExclude)
              break
            default:
              null
          }

          closeModalExclude()
        }}
      >
        {t('modalExclude.message')}
      </ModalConfirmExclude>
    </>
  )
}
