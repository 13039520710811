/* eslint-disable react-hooks/exhaustive-deps */
import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'

interface ButtonPropsMenu extends MenuButtonProps {}

interface Options {
  id: string
  name: string
}

export interface MultiSelectMenuProps {
  label: string
  options: Options[]
  backChange?: (values: String[] | String) => void
  buttonProps?: ButtonPropsMenu
  isDefaultFull?: boolean
  valueReset?: string[] | string
  isReset?: boolean
  render?: () => string[]
}

export const MultiSelectMenuFilter: FC<MultiSelectMenuProps> = ({
  label,
  options,
  buttonProps,
  backChange,
  isDefaultFull,
  valueReset,
  isReset,
  render
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[] | string>(
    render?.() || []
  )

  useEffect(() => {
    if (isReset !== undefined) {
      if (isReset === true) {
        setSelectedOptions(valueReset || [])
      }
    }
  }, [isReset])

  const renderNameOption = (id: string) => {
    const findValue = options.find((option) => {
      return option.id === id
    })

    const name = findValue?.name

    return name || ''
  }

  return (
    <Menu closeOnSelect={false}>
      {({ onClose }) => (
        <>
          <MenuButton
            type="button"
            backgroundColor={selectedOptions.length ? 'blue.500' : 'white'}
            color={selectedOptions.length ? 'white' : 'gray.600'}
            borderRadius={8}
            px="2"
            py="2"
            h="38px"
            mx="2"
            my="2px"
            border={selectedOptions.length ? 'none' : '1px solid gray'}
            _focus={{ outline: 'none' }}
            {...buttonProps}
          >
            {selectedOptions.length === 1
              ? renderNameOption(selectedOptions[0])
              : `${label} ${
                  selectedOptions.length > 0 ? `${selectedOptions.length}` : ''
                }`}
          </MenuButton>
          <MenuList zIndex="modal">
            {options?.length > 0 && (
              <MenuGroup title={undefined}>
                <MenuItem
                  onClick={() => {
                    const allOption = options.map((item) => {
                      return item.id
                    })

                    setSelectedOptions(allOption)
                    backChange?.(allOption)
                    onClose()
                  }}
                >
                  Selecionar tudo
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedOptions([])
                    backChange?.([])
                    onClose()
                  }}
                >
                  Limpar filtro
                </MenuItem>
              </MenuGroup>
            )}
            <MenuDivider />
            <MenuOptionGroup
              type="checkbox"
              title={undefined}
              value={selectedOptions}
              onChange={(values) => {
                setSelectedOptions(values)
                backChange?.(values)
              }}
            >
              {options.map((option) => (
                <MenuItemOption
                  key={option.id}
                  type="checkbox"
                  value={option.id}
                >
                  <Text fontSize="14px" noOfLines={1}>
                    {option.name}
                  </Text>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
