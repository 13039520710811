import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Flex,
  Button,
  Stack,
  Text,
  Link as LinkChakra,
  useToast
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '../../components/Form/Input'
import { useAuth } from '../../hooks/auth'
import { Logo } from '../../components/atoms/Logo'

type SignInFormData = {
  email: string
  password: string
}

const siginFormSchema = yup.object().shape({
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  password: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No mínimo 6 caracteres')
})

export const Signin = () => {
  const { signIn } = useAuth()
  const navigation = useHistory()
  const toast = useToast()
  const { register, handleSubmit, formState } = useForm<SignInFormData>({
    resolver: yupResolver(siginFormSchema)
  })
  const { errors } = formState

  const handleSignIn: SubmitHandler<SignInFormData> = useCallback(
    async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 2000))

      const { email, password } = values

      const data = {
        email,
        password
      }

      try {
        await signIn(data)

        navigation.push('/scheduling')
      } catch (err: any) {
        console.log(err)
        if (err.message.indexOf('500') === -1) {
          toast({
            title: 'Credenciais inválidas!',
            description: 'Usuário ou senha estão incorreto.',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top'
          })
        }
      }
    },
    [toast, navigation, signIn]
  )

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={480}
        p="8"
        border={['none', '1px']}
        borderColor={['none', 'gray.100']}
        borderRadius={[0, 8]}
        flexDir="column"
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Logo />
        <Stack>
          <Input
            label="E-mail"
            type="email"
            size="md"
            error={errors.email}
            {...register('email')}
          />
          <Input
            label="Senha"
            type="password"
            size="md"
            {...register('password')}
            error={errors.password}
          />
        </Stack>
        <Flex my="2" align="center">
          <Text>
            <LinkChakra as={Link} to="/forgot-password" color="pink.500">
              Esqueceu a senha?
            </LinkChakra>
          </Text>
        </Flex>
        <Button
          type="submit"
          mt="6"
          colorScheme="blue"
          size="lg"
          isLoading={formState.isSubmitting}
        >
          Entrar
        </Button>
        {/* <Flex my="8" align="center" justify="center">
          <Text>
            Ainda não tem uma conta?{' '}
            <LinkChakra as={Link} to="/register" color="pink.500">
              Criar conta
            </LinkChakra>
          </Text>
        </Flex> */}
      </Flex>
    </Flex>
  )
}
