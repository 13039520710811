/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type AttendanceSpot = {
  name: string
  id: string
  description: string
  index: number
}

export type ResponseGetAttendanceSpot = {
  spot: AttendanceSpot[]
  totalCountOfPage: number
}

export const getAttendanceSpot = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetAttendanceSpot> => {
  try {
    const { data } = await apiAuth.get(
      `clinics/${clinicsId}/attendance-spots/`,
      {
        params: {
          page,
          name__icontains: search,
          is_active: isActive
        }
      }
    )

    const totalCountOfPage = data.count
    const spot: AttendanceSpot[] = data.results

    return {
      spot,
      totalCountOfPage
    }
  } catch (error: any) {
    const spot: AttendanceSpot[] = []
    const totalCountOfPage = 0
    return {
      spot,
      totalCountOfPage
    }
  }
}

export function useAttendanceSpot(
  page: number,
  functionLoading: Promise<ResponseGetAttendanceSpot>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['AttendanceSpot', page, search, isActive],
    () => functionLoading
  )
}
