/* eslint-disable no-undef */
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

interface TimerProps {
  timerInit: number | undefined
}

let countTimeUp: NodeJS.Timeout

export const Timer: React.FC<TimerProps> = ({ timerInit }) => {
  const [timer, setTimer] = useState(0)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(true)
  }, [])

  const days = Math.floor(timer / 86400)
  const dividerHours = timer % 86400
  const hours = Math.floor(dividerHours / 3600)
  const dividerMinutes = timer % (60 * 60)
  const minutes = Math.floor(dividerMinutes / 60)
  const seconds = timer % 60

  useEffect(() => {
    countTimeUp = setTimeout(() => {
      setTimer(timer + 1)
    }, 1000)
  }, [timer, isActive])

  const [minutesLeft, minutesRight] = String(minutes).padStart(2, '0').split('')
  const [secondsLeft, secondsRight] = String(seconds).padStart(2, '0').split('')
  const [hoursLeft, hoursRight] = String(hours).padStart(2, '0').split('')
  const [daysLeft, daysRight] = String(days).padStart(2, '0').split('')

  return (
    <Stack direction="row" fontSize="xs">
      <Stack
        direction="column"
        spacing="2px"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDir="row"
          p="4px"
          bg="blue.300"
          color="white"
          borderRadius={8}
        >
          <Text>{daysLeft}</Text>
          <Text>{daysRight}</Text>
        </Flex>
      </Stack>
      <Stack
        direction="column"
        spacing="2px"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDir="row"
          p="4px"
          bg="blue.300"
          color="white"
          borderRadius={8}
        >
          <Text>{hoursLeft}</Text>
          <Text>{hoursRight}</Text>
        </Flex>
      </Stack>
      <Stack
        direction="column"
        spacing="2px"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDir="row"
          p="4px"
          bg="blue.300"
          color="white"
          borderRadius={8}
        >
          <Text>{minutesLeft}</Text>
          <Text>{minutesRight}</Text>
        </Flex>
      </Stack>
      <Stack
        direction="column"
        spacing="2px"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDir="row"
          p="4px"
          bg="blue.300"
          color="white"
          borderRadius={8}
        >
          <Text>{secondsLeft}</Text>
          <Text>{secondsRight}</Text>
        </Flex>
      </Stack>
      <Flex alignItems="center" justifyContent="center">
        <Text fontSize="xs" fontWeight="hairline">
          dd-hh-mm-ss
        </Text>
      </Flex>
    </Stack>
  )
}
