/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type ClientsData = {
  name: string
  social_name: string
  document_number: string
  telephone: string
  id: number
  number: string
  created_at: string
}

export type ResponseGetCost = {
  clients: ClientsData[]
  totalCountOfPage: number
}

export const getClients = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetCost> => {
  try {
    const { data } = await apiAuth.get(`clinics/patients/`, {
      params: {
        page,
        name: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data?.count
    const clients: ClientsData[] = data.results

    return {
      clients,
      totalCountOfPage
    }
  } catch (error: any) {
    const clients: ClientsData[] = []
    const totalCountOfPage = 0
    return {
      clients,
      totalCountOfPage
    }
  }
}

export function useClients(
  page: number,
  functionLoading: Promise<ResponseGetCost>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['clients', page, search, isActive], () => functionLoading)
}
