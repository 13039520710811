/* eslint-disable no-debugger */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Divider,
  useToast,
  useDisclosure,
  Icon,
  useBreakpointValue,
  Heading,
  SimpleGrid,
  Tooltip,
  Link as LinkChakra,
  Spinner
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useHistory, Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { FiEdit, FiFile, FiUploadCloud } from 'react-icons/fi'
import { AiOutlineDollarCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import { RiAttachmentLine } from 'react-icons/ri'

import { CardContainer } from '../../components/molecules/CardContainer'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { Input } from '../../components/Form/Input'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import Dropzone from './Dropzone'

import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { PermissionComponent } from '../../components/Permissions'
import { apiAuth } from '../../services/apiAuth'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'

type DocumentData = {
  id: number
  document: any
}

interface ParamsProps {
  id: string
}

export function BillsToPayDocument() {
  const [t] = useTranslation('pagePaymentBill')
  const history = useHistory()
  const { id: idPosting } = useParams<ParamsProps>()
  // const { state }: any = history.location
  const [state, setState] = useState<any>(null)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const [documents, setDocuments] = useState<DocumentData[]>([
    { id: 1, document: {} }
  ])
  const [indexDoc, setIndexDoc] = useState(0)
  const [editName, setEditName] = useState('')
  const [idEdit, setIdEdit] = useState(0)
  const [isOpenEdit, setIsOpenEdit] = useState(false)

  useEffect(() => {
    // format data for state value
    const formatData = (myBills: any) => {
      const newCompetenceString = remaskCaractersAll(myBills?.competence_date)
      const newDueDateString = remaskCaractersAll(myBills?.due_date)

      // date invalid for app
      const newPaymentDate = remaskCaractersAll(
        myBills?.payment_date || '11112000'
      )
      // logic Get Format Date for body
      const vc = newCompetenceString.split('')
      const dayVc = Number(`${0}${5}`)
      const monthVc = Number(`${vc[4]}${vc[5]}`) - 1
      const yearVc = Number(`${vc[0]}${vc[1]}${vc[2]}${vc[3]}`)
      const dateVc = new Date(yearVc, monthVc, dayVc)
      const FormatMonthVc = String(format(dateVc, 'MM/yyyy'))

      // logic Get Format Date for body
      const vd = newDueDateString.split('')
      const dayVd = Number(`${vd[6]}${vd[7]}`)
      const monthVd = Number(`${vd[4]}${vd[5]}`) - 1
      const yearVd = Number(`${vd[0]}${vd[1]}${vd[2]}${vd[3]}`)
      const dateVd = new Date(yearVd, monthVd, dayVd)
      const FormatMonthVd = String(format(dateVd, 'dd/MM/yyyy'))
      // logic Get Format Date for body format
      const vp = newPaymentDate.split('')
      const dayVp = Number(`${vp[6]}${vp[7]}`)
      const monthVp = Number(`${vp[4]}${vp[5]}`) - 1
      const yearVp = Number(`${vp[0]}${vp[1]}${vp[2]}${vp[3]}`)
      const dateVp = new Date(yearVp, monthVp, dayVp)
      const FormatMonthVp = String(format(dateVp, 'dd/MM/yyyy'))

      const posting_methods = myBills?.posting_methods.map(
        (posting_method: any) => {
          const newPaymentDateMethod = remaskCaractersAll(
            posting_method.payment_date || '11112000'
          )
          // logic Get Format Date for body format
          const vpm = newPaymentDateMethod.split('')
          const dayVpm = Number(`${vpm[6]}${vpm[7]}`)
          const monthVpm = Number(`${vpm[4]}${vpm[5]}`) - 1
          const yearVpm = Number(`${vpm[0]}${vpm[1]}${vpm[2]}${vpm[3]}`)
          const dateVpm = new Date(yearVpm, monthVpm, dayVpm)
          const FormatMonthVpm = String(format(dateVpm, 'yyyy-MM-dd'))

          return {
            paymentDate: FormatMonthVpm,
            amount: formatBrOrDollarDefault(posting_method.price || ''),
            paymentMethod: String(posting_method.payment_source),
            balance: formatBrOrDollarDefault(posting_method.debt || '')
          }
        }
      )

      const attachments = myBills?.attachments.map((attachment: any) => {
        return {
          id: String(attachment.id),
          document: {
            file: attachment.file,
            file_format: attachment.file_format,
            description: attachment.description,
            alt: attachment.alt,
            posting_document: attachment.posting_document
          }
        }
      })

      return {
        id: myBills?.id,
        name: myBills?.name,
        code: myBills?.code,
        price: formatBrOrDollarDefault(myBills?.price),
        nominal_price: formatBrOrDollarDefault(myBills?.nominal_price),
        observation: myBills?.observation,
        pos: String(
          `${myBills?.share_number} de ${myBills?.total_of_postings}`
        ),
        competence_date: FormatMonthVc,
        due_date: myBills?.due_date,
        due_date_view: FormatMonthVd,
        payment_date: FormatMonthVp,
        share_number: myBills?.share_number,
        total_of_postings: myBills?.total_of_postings,
        addition_price: formatBrOrDollarDefault(myBills?.addition_price || ''),
        discount_price: formatBrOrDollarDefault(myBills?.discount_price || ''),
        status: myBills?.status,
        posting_methods,
        category: myBills?.category,
        supplier_posting: myBills?.supplier_posting,
        cost_center: myBills?.cost_center,
        postings: myBills?.total_of_postings,
        posting_clinic: myBills?.posting_clinic,
        posting_group: myBills?.posting_group,
        attachments,
        created_at: myBills?.created_at,
        updated_at: myBills?.updated_at,
        created_by: myBills?.created_by,
        updated_by: myBills?.updated_by
      }
    }

    const getPosting = async () => {
      const { data } = await apiAuth.get(`clinics/postings/${idPosting}/`)
      const dataState = formatData(data)

      setState(dataState)
    }
    getPosting()

    return () => {}
  }, [idPosting])

  useEffect(() => {
    if (state?.attachments?.length > 0) {
      setDocuments(state.attachments)
    }
  }, [state])

  // handler change new name document
  const handlerUpdateNameDocument = async () => {
    try {
      const { data } = await apiAuth.post(
        `clinics/documents-posting/${idEdit}/rename/`,
        {
          new_name: editName || undefined
        }
      )

      console.log(editName)

      if (data.id !== undefined) {
        toast({
          title: 'Alteração realizado com sucesso!',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right'
        })

        const values = [...documents]
        values[indexDoc].id = data.id
        values[indexDoc].document = data

        setDocuments(values)
      }
    } catch (error: any) {
      toast({
        title: 'Não é possível atualizar',
        description:
          'Para poder atualizar um documento, acesse novamente esta área',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top-right'
      })
    }
  }

  // function fields documents
  function handleAddFieldDocumentsPaymentMethods() {
    const values: DocumentData[] = [...documents]
    const lengthAux = values.length - 1

    setDocuments([
      ...documents,
      {
        id: values[lengthAux].id + 1,
        document: {}
      }
    ])
  }

  function onRemoveFieldDocumentsPaymentMethods(index: number) {
    if (index === 0 && documents.length === 1) {
      setDocuments([{ id: 1, document: {} }])
    } else {
      const values = [...documents]
      values.splice(index, 1)
      setDocuments(values)
    }
  }

  async function handleExcludedDocumentByIndexId(index: number) {
    const values: DocumentData[] = [...documents]
    const myDocument = values[index]
    const idDocument = String(myDocument.id)

    try {
      const { status } = await apiAuth.delete(
        `/clinics/documents-posting/${idDocument}/`
      )

      if (status === 204) {
        toast({
          position: 'top-right',
          status: 'success',
          duration: 3000,
          title: 'Documento excluído',
          isClosable: true,
          description: 'Seu documento foi excluído com sucesso'
        })

        onRemoveFieldDocumentsPaymentMethods(index)
        onClose()
      }
    } catch (error: any) {
      toast({
        position: 'top-right',
        status: 'warning',
        duration: 3000,
        title: 'Ação não realizada!',
        isClosable: true,
        description: 'Não foi possível excluir no momento'
      })
    }
  }

  function handleUploadDocument(e: any, index: number) {
    const values: any = [...documents]
    values[index].document = e[0] || ''

    setDocuments(values)
  }

  if (state === null) {
    return (
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('update.title')}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/finances/bills-to-pay">
                <Text fontSize="md" fontWeight="bold">
                  Voltar
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
        >
          <Flex w="100vw" h="100vh" align="center" justify="center">
            <Spinner color="blue" />
          </Flex>
        </Flex>
      </Box>
    )
  }

  return (
    <>
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Heading
                noOfLines={1}
                fontSize="24px"
                fontWeight="600"
                height="100%"
              >
                {`${t('create.container5.title')} (${state?.name})`}
              </Heading>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/finances/bills-to-pay">
                <Text fontSize="md" fontWeight="bold">
                  Voltar
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
        >
          <Stack spacing="4" w="100%">
            <CardContainer title={t('info')}>
              <Stack position="absolute" direction="row" right="4" top="4">
                <PermissionComponent spiCode="BILLS_TO_PAY" ipCode="EDIT">
                  <Flex flexDirection="row" align="center" justify="center">
                    <Link
                      to={{
                        pathname: `/finances/bills-to-pay/update/${state.id}`,
                        state
                      }}
                    >
                      <Tooltip
                        label={t('updateTitle')}
                        aria-label={t('updateTitle')}
                        hasArrow
                        placement="top-start"
                      >
                        <Box ml="1rem" w="24px">
                          <FiEdit
                            color="#9699B0"
                            size={16}
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </Tooltip>
                    </Link>
                    {/*                     <Link
                      to={{
                        pathname: `/finances/bills-to-pay/files/${state.id}`,
                        state
                      }}
                    >
                      <Tooltip
                        label={t('file')}
                        aria-label={t('updateTitle')}
                        hasArrow
                        placement="top-start"
                      >
                        <Box ml="1rem" w="24px">
                          <FiFile
                            color="#9699B0"
                            size={16}
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </Tooltip>
                    </Link> */}
                    <Link
                      to={{
                        pathname: `/finances/bills-to-pay/payment/${state.id}`,
                        state
                      }}
                    >
                      <Tooltip
                        label={t('payment')}
                        aria-label={t('payment')}
                        hasArrow
                        placement="top-start"
                      >
                        <Box ml="1rem" w="24px">
                          <AiOutlineDollarCircle
                            color="#9699B0"
                            size={20}
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </Tooltip>
                    </Link>
                  </Flex>
                </PermissionComponent>
              </Stack>
              <SimpleGrid minChildWidth="320px">
                <Stack direction="row" my="2" spacing="6">
                  <Box w="100px">
                    <Input
                      w="100px"
                      label={t('create.container3.i1')}
                      defaultValue={state?.code}
                      name="code"
                      isDisabled
                    />
                  </Box>
                  <Box w="100px">
                    <Input
                      w="100px"
                      label={t('create.container3.i2')}
                      defaultValue={state?.pos}
                      name="share_number"
                      isDisabled
                    />
                  </Box>
                  <Box w="300px">
                    <Input
                      name="competence_date"
                      defaultValue={state?.competence_date}
                      label={t('create.container3.i3')}
                      isDisabled
                    />
                  </Box>
                  <Box w="300px">
                    <Input
                      defaultValue={state?.due_date_view}
                      name="due_date"
                      label={t('create.container3.i4')}
                      isDisabled
                    />
                  </Box>
                </Stack>
              </SimpleGrid>
            </CardContainer>
            <CardContainer title={t('create.container5.title')}>
              {documents.map((doc: any, index: number) => (
                <>
                  <Stack>
                    <Flex justify="space-between">
                      {doc?.document?.posting_document && (
                        <Flex mx="6" d="flex" align="center" justify="center">
                          <Icon mx="2" as={RiAttachmentLine} />
                          <Text mx="2">{doc?.document?.alt}</Text>
                          <Text mx="2" fontSize="14px" fontWeight="hairline">
                            {doc?.document?.description}
                          </Text>
                          <LinkChakra
                            mx="2"
                            href={doc?.document?.file}
                            target="_blank"
                            d="flex"
                            alignItems="center"
                            justifyContent="center"
                            p="2"
                            bg="green.300"
                            borderRadius={8}
                            color="white"
                            transition="0.2s ease-in"
                            _hover={{
                              bg: 'green.400'
                            }}
                          >
                            Download
                          </LinkChakra>
                        </Flex>
                      )}
                      {!doc.document.posting_document && (
                        <Flex direction="column">
                          <Dropzone
                            getImg={(e: any) => {
                              handleUploadDocument(e, index)
                            }}
                            handleSetImg={doc.document}
                            idPost={state?.id}
                            getDocumentData={(indexItem: number, item: any) => {
                              const documentsNew = [...documents]
                              documentsNew[indexItem] = {
                                id: item?.id,
                                document: item
                              }

                              if (item?.id !== undefined) {
                                if (item?.id !== null) {
                                  setDocuments(documentsNew)
                                }
                              }
                            }}
                            index={index}
                          />
                        </Flex>
                      )}
                      <Flex justify="space-between">
                        {documents[index].document.file && (
                          <Stack direction="row">
                            <Button
                              size="xs"
                              bg="transparent"
                              _hover={{
                                bg: 'transparent'
                              }}
                              onClick={() => {
                                setIndexDoc(index)
                                setIdEdit(documents[index]?.id)
                                setEditName(documents[index].document?.alt)
                                setIsOpenEdit(true)
                              }}
                            >
                              <FiEdit size={16} />
                            </Button>
                            <Button
                              type="button"
                              colorScheme="red"
                              size="xs"
                              mb="2"
                              onClick={() => {
                                setIndexDoc(index)
                                onOpen()
                              }}
                            >
                              X
                            </Button>
                          </Stack>
                        )}
                      </Flex>
                    </Flex>
                  </Stack>
                  <Divider my="6" />
                </>
              ))}

              <Box mt="5">
                <Button
                  size="md"
                  fontSize="sm"
                  bg="green.300"
                  color="#fff"
                  onClick={() => handleAddFieldDocumentsPaymentMethods()}
                >
                  {t('create.container5.btnLabel')}
                </Button>
              </Box>
            </CardContainer>
          </Stack>
        </Flex>
      </Box>
      <ModalConfirmExclude
        isOpen={isOpen}
        title="Confirma Exclusão:"
        onConfirmExclude={() => {
          handleExcludedDocumentByIndexId(indexDoc)
          onClose()
        }}
        onClose={onClose}
      >
        <Text>Tem certeza que quer excluir este documento?</Text>
      </ModalConfirmExclude>
      <ModalArrivedTime
        isOpen={isOpenEdit}
        title="Edição de arquivo:"
        onUpdate={async () => {
          await handlerUpdateNameDocument()
          setIsOpenEdit(false)
        }}
        onClose={() => setIsOpenEdit(false)}
      >
        <Input
          name="editNameDocument"
          label="Nome"
          value={editName}
          onChange={(event) => setEditName(event.target.value)}
        />
      </ModalArrivedTime>
    </>
  )
}
