/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type IndicationsData = {
  id: number
  service: {
    id: number
    name: string
    observation: string | null
    is_active: boolean
    price: number
    days_to_come_back: number
    days_to_maturity: number
    protocol: string
  }
  patient_name: string
  service_name: string
  observation: string | null
  status: number
  indication_date: string
  specialist_name: string
  medical_record_finish_date: string
  updated_at: string
  updated_by_name: string
}

export type ResponseGetIndications = {
  indications: IndicationsData[]
  totalCountOfPage: number
}

export const getIndications = async (
  page: number = 1,
  orderBy: string = '',
  status: Array<string> = [],
  startDate: string = '',
  finishDate: string = '',
  servicesIds: Array<number> = []
): Promise<ResponseGetIndications> => {
  try {
    const { data } = await apiAuth.get(
      `clinics/clinics/indications/
`,
      {
        params: {
          page,
          order_by: orderBy,
          status: status?.join(','),
          startDate,
          finishDate,
          services_ids: servicesIds?.join(',')
        }
      }
    )

    const totalCountOfPage = data.count
    const indications: IndicationsData[] = data.results

    return {
      indications,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const indications: IndicationsData[] = []
    const totalCountOfPage = 0
    return {
      indications,
      totalCountOfPage
    }
  }
}

export function useIndications(
  functionLoading: Promise<ResponseGetIndications>,
  page: number = 1,
  orderBy: string = '',
  status: Array<string> = [],
  startDate: string = '',
  finishDate: string = '',
  servicesIds: Array<number> = [],
  options?: {}
) {
  return useQuery(
    ['indications', page, orderBy, status, startDate, finishDate, servicesIds],
    () => functionLoading
  )
}
