/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react'
import axios from 'axios'
import Countries from '../json/countries.json'

let optionsContries: any = []
Countries.map((currency) => {
  optionsContries = [
    ...optionsContries,
    { value: currency.name, label: currency.name, code: currency.code }
  ]
})

export const useAddress = () => {
  const [cep, setCep] = useState<any>('')
  const [loadingCompletAddress, setLoadingCompletAddress] = useState(false)
  const [address, setAddress] = useState('')
  const [bairro, setBairro] = useState('')
  const [municipio, setMunicipio] = useState('')
  const [ufDefault, setUfDefault] = useState({
    value: '',
    label: '',
    sigla: ''
  })
  const [selectedBR, setSeledtedBR] = useState(false)

  const [ufs, setUfs] = useState<any>([])
  const [seletedCountry, setSeletedCountry] = useState<any>({
    value: '',
    label: ''
  })

  const [seletedState, setSelectedState] = useState<any>({})

  const handleCompletAddress = (e: any) => {
    if (e.length <= 8) {
      setCep(e)
      if (selectedBR === true) {
        if (e.length === 8) {
          setLoadingCompletAddress(true)
          axios
            .get(`https://viacep.com.br/ws/${e}/json/`)
            .then((res) => {
              const filterUF = ufs.filter(
                (uf: any) =>
                  uf.sigla.toLowerCase() === res.data.uf.toLowerCase()
              )
              setAddress(res.data.logradouro)
              setBairro(res.data.bairro)
              setMunicipio(res.data.localidade)
              setUfDefault(filterUF[0])
              setSelectedState(filterUF)
            })
            .catch((res) => {})
            .finally(() => {
              setLoadingCompletAddress(false)
            })
        }
      }
    }
  }

  const handleBRSelectedAndShowStates = (e?: any, search?: boolean) => {
    if (e?.value !== undefined) {
      setSeletedCountry({
        value: e?.label,
        label: e?.label
      })
    }

    if (e?.value !== 'Brasil' || seletedCountry.value === 'Brasil') {
      axios
        .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
        .then((res) => {
          let aux: any = []
          res.data.map((uf: any) => {
            aux = [
              ...aux,
              {
                value: uf.nome,
                label: uf.nome,
                sigla: uf.sigla
              }
            ]
          })
          setUfs(aux)
        })
    } else {
      setSeledtedBR(false)
    }
  }

  useEffect(() => {
    if (seletedCountry.value === 'Brasil') {
      setSeledtedBR(true)
      handleBRSelectedAndShowStates()
    } else {
      setSeledtedBR(false)
      handleBRSelectedAndShowStates()
    }
  }, [seletedCountry, selectedBR])

  return {
    cep,
    loadingCompletAddress,
    address,
    bairro,
    municipio,
    ufDefault,
    selectedBR,
    ufs,
    seletedCountry,
    handleBRSelectedAndShowStates,
    handleCompletAddress,
    optionsContries,
    setAddress,
    setBairro,
    setMunicipio,
    setSelectedState,
    seletedState,
    setSeletedCountry,
    setSeledtedBR,
    setCep,
    setUfDefault
  }
}
