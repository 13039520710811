/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { apiAuth } from '../../services/apiAuth'
import {
  formatCurrencyUSDDefault,
  remaskCaractersAll
} from '../../utils/fns/removeCaracters'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'

export type billsToPay = {
  id: number
  name: string
  code: number
  pos: string
  share_number: string
  due_date_view: string
  is_active: boolean
  price: string
  nominal_price: string
  observation: string
  competence_date: string
  due_date: string
  payment_date: string
  total_of_postings: number
  addition_price: string
  discount_price: string
  status: number
  posting_methods: Array<{
    payment_date: string
    price: number
    debt: number
    payment_source: number
  }>
  category: {
    id: number
    name: string
  }
  supplier_posting: {
    id: number
    trading_name: string
  }
  cost_center: {
    id: number
    name: string
  }
  posting_clinic: number
  posting_group: number
  attachments: Array<{
    id: number
    file: string
    file_format: string
    description: string
    alt: string
    posting_document: number
  }>
  created_at: string
  updated_at: string
  created_by: number
  updated_by: number
}

export type ResponseGetPostings = {
  postings: billsToPay[]
  totalCountOfPage: number
}

export const getBillsToPay = async (
  page: number,
  search: string = '',
  isActive: boolean = true,
  dueDateInitial: string = '',
  dueDateFinal: string = '',
  paymentDateInitial: string = '',
  paymentDateFinal: string = '',
  competenceDate: string = '',
  code: string = '',
  name: string = '',
  status: any = [],
  category: any = [],
  cost: any = [],
  order: string = ''
): Promise<ResponseGetPostings> => {
  console.log(code)

  try {
    const { data } = await apiAuth.get(`clinics/postings/`, {
      params: {
        page,
        keyword: search || name,
        due_date__gte: dueDateInitial,
        due_date__lte: dueDateFinal,
        payment_date__gte: paymentDateInitial,
        payment_date__lte: paymentDateFinal,
        competence_date: competenceDate,
        code,
        status: status?.join(','),
        category: category?.join(','),
        cost_center: cost?.join(','),
        ordering: order
      }
    })

    const totalCountOfPage = data.count

    const postings = data.results.map((myBills: billsToPay) => {
      const newCompetenceString = remaskCaractersAll(myBills.competence_date)
      const newDueDateString = remaskCaractersAll(myBills.due_date)

      // date invalid for app
      const newPaymentDate = remaskCaractersAll(
        myBills.payment_date || '11112000'
      )
      // logic Get Format Date for body
      const vc = newCompetenceString.split('')
      const dayVc = Number(`${0}${5}`)
      const monthVc = Number(`${vc[4]}${vc[5]}`) - 1
      const yearVc = Number(`${vc[0]}${vc[1]}${vc[2]}${vc[3]}`)
      const dateVc = new Date(yearVc, monthVc, dayVc)
      const FormatMonthVc = String(format(dateVc, 'MM/yyyy'))

      // logic Get Format Date for body
      const vd = newDueDateString.split('')
      const dayVd = Number(`${vd[6]}${vd[7]}`)
      const monthVd = Number(`${vd[4]}${vd[5]}`) - 1
      const yearVd = Number(`${vd[0]}${vd[1]}${vd[2]}${vd[3]}`)
      const dateVd = new Date(yearVd, monthVd, dayVd)
      const FormatMonthVd = String(format(dateVd, 'dd/MM/yyyy'))
      // logic Get Format Date for body format
      const vp = newPaymentDate.split('')
      const dayVp = Number(`${vp[6]}${vp[7]}`)
      const monthVp = Number(`${vp[4]}${vp[5]}`) - 1
      const yearVp = Number(`${vp[0]}${vp[1]}${vp[2]}${vp[3]}`)
      const dateVp = new Date(yearVp, monthVp, dayVp)
      const FormatMonthVp = String(format(dateVp, 'dd/MM/yyyy'))

      const posting_methods = myBills.posting_methods.map((posting_method) => {
        const newPaymentDateMethod = remaskCaractersAll(
          posting_method.payment_date || '11112000'
        )
        // logic Get Format Date for body format
        const vpm = newPaymentDateMethod.split('')
        const dayVpm = Number(`${vpm[6]}${vpm[7]}`)
        const monthVpm = Number(`${vpm[4]}${vpm[5]}`) - 1
        const yearVpm = Number(`${vpm[0]}${vpm[1]}${vpm[2]}${vpm[3]}`)
        const dateVpm = new Date(yearVpm, monthVpm, dayVpm)
        const FormatMonthVpm = String(format(dateVpm, 'yyyy-MM-dd'))

        return {
          paymentDate: FormatMonthVpm,
          amount: formatCurrencyUSDDefault(posting_method.price),
          paymentMethod: String(posting_method.payment_source),
          balance: formatCurrencyUSDDefault(posting_method.debt)
        }
      })

      const attachments = myBills.attachments.map((attachment) => {
        return {
          id: String(attachment.id),
          document: {
            file: attachment.file,
            file_format: attachment.file_format,
            description: attachment.description,
            alt: attachment.alt,
            posting_document: attachment.posting_document
          }
        }
      })

      return {
        id: myBills.id,
        name: myBills.name,
        code: myBills.code,
        price: formatBrOrDollarDefault(myBills.price),
        nominal_price: formatBrOrDollarDefault(myBills.nominal_price),
        observation: myBills.observation,
        pos: String(`${myBills.share_number} de ${myBills.total_of_postings}`),
        competence_date: FormatMonthVc,
        due_date: myBills.due_date,
        due_date_view: FormatMonthVd,
        payment_date: FormatMonthVp,
        share_number: myBills.share_number,
        total_of_postings: myBills.total_of_postings,
        addition_price: formatBrOrDollarDefault(myBills?.addition_price || ''),
        discount_price: formatBrOrDollarDefault(myBills?.discount_price || ''),
        status: myBills.status,
        posting_methods,
        category: myBills.category,
        supplier_posting: myBills.supplier_posting,
        cost_center: myBills.cost_center,
        postings: myBills.total_of_postings,
        posting_clinic: myBills.posting_clinic,
        posting_group: myBills.posting_group,
        attachments,
        created_at: myBills.created_at,
        updated_at: myBills.updated_at,
        created_by: myBills.created_by,
        updated_by: myBills.updated_by
      }
    })

    return {
      postings,
      totalCountOfPage
    }
  } catch (error: any) {
    const postings: billsToPay[] = []
    const totalCountOfPage = 0
    return {
      postings,
      totalCountOfPage
    }
  }
}

export function useBillsToPay(
  page: number,
  functionLoading: Promise<ResponseGetPostings>,
  search: string,
  isActive: boolean,
  dueDateInitial: string,
  dueDateFinal: string,
  paymentDateInitial: string,
  paymentDateFinal: string,
  competenceDate: string,
  code: string,
  name: string,
  status: any,
  category: any,
  cost: any,
  order: string,
  options?: {}
) {
  return useQuery(
    [
      'billsToPay',
      page,
      search,
      dueDateInitial,
      dueDateFinal,
      paymentDateInitial,
      paymentDateFinal,
      competenceDate,
      code,
      name,
      status,
      category,
      cost,
      order
    ],
    () => functionLoading
  )
}
