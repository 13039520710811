/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { format } from 'date-fns'

import { useCreate } from '../../hooks/useCreate'
import { useLogicBillsToPay } from './logic'
import { useService } from './service'

import { Input } from '../../components/Form/Input'
import { CardContainer } from '../../components/molecules/CardContainer'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Header } from '../../components/Header'
import { Sidebar } from '../../components/Sidebar'

import { RemoveBtnIcon } from '../../components/atoms/removeBtnIcon'
import { InputTypeDate } from '../../components/Form/InputDate'
import { Select } from '../../components/Form/Select'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { useAuth } from '../../hooks/auth'
import { useModal } from '../../hooks/useModal'
import { queryClient } from '../../services/reactQuery'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { apiAuth } from '../../services/apiAuth'

type LaunchData = {
  competence: string
  amountPortion: string
  portion: number
  dueDate: string
}

type FormData = {
  reference: string
  category: string
  supplier_posting: string
  payment_date: string
  cost: string
}

// const CreateNewPostingsValidate = yup.object().shape({
//   supplier_posting: yup.string().ensure().required('Este campo é obrigatório')
// })

export function CreateAndUpdateBillsToPay() {
  const [t] = useTranslation('pageBillsToPay')
  const { currency } = useAuth()
  const { LocaleApp } = useModal()
  const logic = useLogicBillsToPay()
  const { createNewBill } = useCreate()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const service = useService()
  const toast = useToast()
  const createPayable = useMutation(
    async (payload: any) => {
      await createNewBill(payload)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('billsToPay')
      },
      onSuccess: () => {
        queryClient.invalidateQueries('billsToPay')
      },
      onError: () => {
        // Invalidate Cache
        queryClient.invalidateQueries('billsToPay')

        // Message error
        toast({
          title: 'Cadastro não realizado',
          description: 'Ouve um erro de comunicação com o servidor',
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }
    }
  )
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const [isDefaultCost, setDefaultCost] = useState(false)

  const handleCreateBillsToPay: SubmitHandler<FormData> = async () => {
    // Format my Launch for request body
    if (logic.supplier_posting.value === '') {
      toast({
        duration: 3000,
        status: 'error',
        title: 'Error!',
        description: 'Selecione um recebedor!',
        position: 'top'
      })

      return
    }

    if (logic.category.value === '') {
      toast({
        duration: 3000,
        status: 'error',
        title: 'Error!',
        description: 'Selecione um categoria!',
        position: 'top'
      })

      return
    }

    if (logic.cost_center.value === '') {
      toast({
        duration: 3000,
        status: 'error',
        title: 'Error!',
        description: 'Selecione um centro de custo !',
        position: 'top'
      })

      return
    }

    const postings = logic.launch.map((post: LaunchData) => {
      const newCompetenceString = remaskCaractersAll(post.competence)
      const newDueDateString = remaskCaractersAll(post.dueDate)
      // logic Get Format Date for body
      const vc = newCompetenceString.split('')
      const dayVc = Number(`${0}${5}`)
      const monthVc = Number(`${vc[0]}${vc[1]}`) - 1
      const yearVc = Number(`${vc[2]}${vc[3]}${vc[4]}${vc[5]}`)
      const dateVc = new Date(yearVc, monthVc, dayVc)
      const FormatMonthVc = String(
        format(dateVc, 'yyyy-MM-dd', { locale: LocaleApp })
      )

      return {
        name: logic.name,
        share_number: post.portion,
        nominal_price: Number(remaskCaractersAll(post.amountPortion)) / 100,
        is_active: true,
        price: Number(remaskCaractersAll(post.amountPortion)) / 100,
        observation: '',
        payment_date: null,
        competence_date: FormatMonthVc,
        due_date: post.dueDate,
        addition_price: null,
        discount_price: null,
        status: 1,
        category: {
          id: Number(logic.category.value)
        },
        supplier_posting: {
          id: Number(logic.supplier_posting.value)
        },
        cost_center: {
          id: Number(logic.cost_center.value)
        }
      }
    })

    console.log(postings)

    createPayable.mutateAsync({
      postings
    })
  }

  useEffect(() => {
    if (isDefaultCost === true) {
      return
    }

    service.newCostsFormats.forEach((cost) => {
      if (cost.is_default === true) {
        logic.setCostCenter({
          label: cost.label,
          value: cost.value
        })
        setDefaultCost(true)
      }
    })
  }, [service.newCostsFormats])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('create.title')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/finances/bills-to-pay">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateBillsToPay)}
      >
        <Stack spacing="4" flex="1">
          <CardContainer title={t('create.container1.title')}>
            <SimpleGrid minChildWidth="320px">
              <Stack direction="row" my="2" spacing="6">
                <Box w="320px">
                  <Input
                    label={t('create.container1.i1')}
                    placeholder={t('create.container1.placeholder.name')}
                    isRequired
                    name="name"
                    value={logic.name}
                    onChange={(event) => {
                      logic.setName(event.target.value.toUpperCase())
                    }}
                  />
                </Box>
                <Box w="320px">
                  <SelectComponent
                    title={t('create.container1.i2')}
                    isRequired
                    options={service.newSuppliersFormats}
                    value={logic.supplier_posting}
                    name="supplier_posting"
                    placeholder={t('create.container1.placeholder.suppliers')}
                    onChange={(event: any) =>
                      logic.handleSelectSuppliers(event)
                    }
                    error={errors.supplier_posting}
                  />
                </Box>
                <Box w="320px">
                  <SelectComponent
                    isRequired
                    options={service.newCategoriesFormats}
                    name="category"
                    onChange={(event: any) => logic.setCategory(event)}
                    value={logic.category}
                    title={t('create.container1.i3')}
                    placeholder={t('create.container1.placeholder.category')}
                    error={errors.category}
                  />
                </Box>
                <Box w="320px">
                  <SelectComponent
                    isRequired
                    value={logic.cost_center}
                    name="cost_center"
                    onChange={(event: any) => logic.setCostCenter(event)}
                    options={service.newCostsFormats}
                    title={t('create.container1.i4')}
                    placeholder={t('create.container1.placeholder.cost')}
                    error={errors.cost}
                  />
                </Box>
              </Stack>
            </SimpleGrid>
            <Flex />
          </CardContainer>

          <CardContainer title={t('create.container3.title')}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('create.container3.i1')}</Th>
                  <Th>{t('create.container3.i2')}</Th>
                  <Th>{t('create.container3.i3')}</Th>
                  <Th>{t('create.container3.i4')}</Th>
                  {logic.launch.length > 1 && (
                    <Th>{t('create.container3.i5')}</Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {logic.launch.map((launch: LaunchData, index: number) => (
                  <Tr key={launch.portion}>
                    {logic.launch.length === 1 ? (
                      <Td>{t('create.container3.uniqueLaunch')}</Td>
                    ) : (
                      <Td>
                        {`${launch.portion}
                          ${t('create.container3.of')}
                          ${logic.launch.length}`}
                      </Td>
                    )}
                    <Td>
                      <InputTypeDate
                        isRequired
                        value={launch.competence}
                        name="competence"
                        onChange={(event) =>
                          logic.handleInputChange(event, index, 'MM/AAAA')
                        }
                      />
                    </Td>
                    <Td>
                      <Input
                        isRequired
                        type="date"
                        name="dueDate"
                        value={launch.dueDate}
                        onChange={(event) =>
                          logic.handleInputChange(event, index)
                        }
                      />
                    </Td>
                    <Td>
                      <TextFieldControlled
                        isRequired
                        name="amountPortion"
                        mask={
                          currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                        }
                        initialValue={launch.amountPortion}
                        onKeyUp={(event) =>
                          logic.handleInputChangeAmountLaunch(event, index)
                        }
                      />
                    </Td>
                    {logic.launch.length > 1 && (
                      <Td>
                        <RemoveBtnIcon
                          onClick={() =>
                            logic.onRemoveFieldLaunchDateIndex(index)
                          }
                        />
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
              <Box mt="5">
                <Button
                  size="md"
                  fontSize="sm"
                  bg="green.300"
                  color="#fff"
                  isLoading={formState.isSubmitting}
                  onClick={() => logic.handleAddFieldLaunchDateIndex()}
                >
                  {t('create.container3.btnLabel')}
                </Button>
              </Box>
            </Table>
          </CardContainer>

          <Flex mt="4" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
