import ls from 'localstorage-slim'
import { remaskCaractersAll } from './removeCaracters'
import formatBr from '../newMask/formatMonetary'
import formatUSA from '../newMask/formatMonetaryDollar'

type Currency = 'BRL' | 'USD' | 'EUR'

export const formatBrOrDollarDefault = (value: string) => {
  const currency = ls.get<Currency>('@Clinik:Currency', {
    decrypt: true
  })

  if (!value) {
    return ''
  }

  const valueNumber = Number(value)
  const formatValue = remaskCaractersAll(value)

  if (valueNumber >= 0) {
    if (currency === 'USD') {
      const formatValueUSA = formatUSA(formatValue)

      return formatValueUSA
    }

    const formatValueBr = formatBr(formatValue)

    return formatValueBr
  }

  if (valueNumber < 0) {
    if (currency === 'USD') {
      const formatValueUSA = formatUSA(formatValue)

      return `-${formatValueUSA}`
    }

    const formatValueBr = formatBr(formatValue)

    return `-${formatValueBr}`
  }

  if (currency === 'USD') {
    const formatValueUSA = formatUSA(formatValue)

    return formatValueUSA
  }

  const formatValueBr = formatBr(formatValue)

  return formatValueBr
}
