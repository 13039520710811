/* eslint-disable import/no-duplicates */
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import { Locale } from 'date-fns'
import format from 'date-fns/format'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import ptBr from 'date-fns/locale/pt-BR'
import ls from 'localstorage-slim'
import React, { createContext, ReactNode, useEffect, useState } from 'react'

interface ModalProviderProps {
  children: ReactNode
}

type ModalData = {
  actions: UseDisclosureReturn
  dueDateInitial: string
  dueDateFinal: string
  paymentDateInitial: string
  paymentDateFinal: string
  tabIndex: number
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
  dateScheduling: string
  setDateScheduling: React.Dispatch<React.SetStateAction<string>>
  competenceDate: string
  order: string
  status: any
  category: any
  cost: any
  code: string
  name: string
  isActiveFilterBillsToPay: boolean
  isActiveFilterProducts: boolean
  setIsActiveFilterBillsToPay: React.Dispatch<React.SetStateAction<boolean>>
  setIsActiveFilterConciliation: React.Dispatch<React.SetStateAction<boolean>>
  setIsActiveFilterBillsToReceive: React.Dispatch<React.SetStateAction<boolean>>
  setIsActiveFilterServices: React.Dispatch<React.SetStateAction<boolean>>
  isOpenBillsToPay: boolean
  isOpenBillsToReceive: boolean
  isOpenConciliation: boolean
  isOpenServices: boolean
  setSpecialistIds: React.Dispatch<React.SetStateAction<String | String[]>>
  setStatusParam: React.Dispatch<React.SetStateAction<String | String[]>>
  setSpecialistIdsReceived: React.Dispatch<
    React.SetStateAction<String | String[]>
  >
  setStatusParamReceived: React.Dispatch<
    React.SetStateAction<String | String[]>
  >
  setWaysParam: React.Dispatch<React.SetStateAction<String | String[]>>
  setStatusConciliation: React.Dispatch<React.SetStateAction<String | String[]>>
  setStatusReceiveConciliation: React.Dispatch<
    React.SetStateAction<String | String[]>
  >
  specialistIds: String[] | String
  isLoginSpecialistIsDefault: boolean
  setIsLoginSpecialistIsDefault: React.Dispatch<React.SetStateAction<boolean>>
  statusParam: String[] | String
  specialistIdsReceived: String[] | String
  statusParamReceived: String[] | String
  waysParam: String[] | String
  statusConciliation: String[] | String
  statusReceiveConciliation: String[] | String
  startAtBillsToReceive: string
  endAtBillsToReceive: string
  startAtConciliation: string
  LocaleApp: Locale
  endAtConciliation: string
  setStartAtConciliation: React.Dispatch<React.SetStateAction<string>>
  setEndAtConciliation: React.Dispatch<React.SetStateAction<string>>
  startAtScheduleConciliation: string
  endAtScheduleConciliation: string
  isActiveFilterBillsToReceive: boolean
  isActiveFilterServices: boolean
  setStartAtScheduleConciliation: React.Dispatch<React.SetStateAction<string>>
  isOpenTaxControl: boolean
  statusPaymentTaxControl: String[] | String
  setEndAtScheduleConciliation: React.Dispatch<React.SetStateAction<string>>
  setStartAt: React.Dispatch<React.SetStateAction<string>>
  setEndAt: React.Dispatch<React.SetStateAction<string>>
  setSearchState: React.Dispatch<React.SetStateAction<string>>
  tabIndexClient: number
  setTabIndexClient: React.Dispatch<React.SetStateAction<number>>
  searchState: string
  setDefaultSearchBillsToReceive: React.Dispatch<React.SetStateAction<string>>
  defaultSearchBillsToReceive: string
  setSearchDefaultScheduling: React.Dispatch<React.SetStateAction<string>>
  searchDefaultScheduling: string
  setSearchDefaultClient: React.Dispatch<React.SetStateAction<string>>
  searchDefaultClient: string
  setServicesFilter: React.Dispatch<React.SetStateAction<String | String[]>>
  servicesFilter: any
  startAtTaxControl: string
  endAtTaxControl: string
  specialistIdsTaxControl: String[] | String
  statusTaxControl: String[] | String
  groupsIds: String[] | String
  typesProducts: String[] | String
  isOpenProducts: boolean
  searchStateProducts: string
  setSearchStateProducts: React.Dispatch<React.SetStateAction<string>>
  defaultTemplate: SelectTemplate | null
  setDefaultTemplate: React.Dispatch<
    React.SetStateAction<SelectTemplate | null>
  >
  setSearchDailyBox: React.Dispatch<React.SetStateAction<string>>
  searchDailyBox: string
  dateInitialDailyBox: string
  setDateInitialDailyBox: React.Dispatch<React.SetStateAction<string>>
  dateFinalDailyBox: string
  setDateFinalDailyBox: React.Dispatch<React.SetStateAction<string>>
  numberTicketDailyBox: string
  patientNameDailyBox: string
  setPatientNameDailyBox: React.Dispatch<React.SetStateAction<string>>
  setNumberTicketDailyBox: React.Dispatch<React.SetStateAction<string>>
  setPatientIdDailyBox: React.Dispatch<React.SetStateAction<string>>
  patientIdDailyBox: string
  setPatientReference: React.Dispatch<React.SetStateAction<string>>
  patientReference: string
  dateInitialTicketsCanceled: string
  setDateInitialTicketsCanceled: React.Dispatch<React.SetStateAction<string>>
  dateFinalTicketsCanceled: string
  setDateFinalTicketsCanceled: React.Dispatch<React.SetStateAction<string>>
  setGenerateInvoiceFilterTicketsCanceled: React.Dispatch<
    React.SetStateAction<any>
  >
  generateInvoiceFilterTicketsCanceled: any
  setStatusConferenceFilterDailyBox: React.Dispatch<React.SetStateAction<any>>
  statusConferenceFilterDailyBox: any
  setWayReceiveFilterDaily: React.Dispatch<React.SetStateAction<any>>
  wayReceiveFilterDailyBox: any
  setGenerateInvoiceFilterDailyBox: React.Dispatch<React.SetStateAction<any>>
  generateInvoiceFilterDailyBox: any
  setStatusPaymentFilterDailyBox: React.Dispatch<React.SetStateAction<any>>
  statusPaymentFilterDailyBox: any
  blockGuideReceived: string
  numberTicketReceived: string
  numberGuideReceived: string
  statusPaymentReceived: any
  setBlockGuideReceived: React.Dispatch<React.SetStateAction<any>>
  setNumberTicketReceived: React.Dispatch<React.SetStateAction<any>>
  setStatusPaymentReceived: React.Dispatch<React.SetStateAction<any>>
  setIsHaveTicketReceived: React.Dispatch<React.SetStateAction<any>>
  isHaveTicketReceived: string
  handlerRemoveFilters: () => void
  handlerRemoveFiltersDefaultSearchBox: () => void
  handlerApplyFiltersBillsToPay: (filters: Filter) => void
  handlerApplyFiltersTaxControl: (filters: FilterTaxControl) => void
  handlerRemoveFiltersTaxControl: () => void
  handlerApplyFiltersBillsToReceive: (filters: FilterGuideReceived) => void
  handlerRemoveFiltersBillsToReceive: () => void
  handlerApplyFiltersConciliation: (filters: FilterConciliation) => void
  handlerRemoveFiltersConciliation: () => void
  handlerApplyFiltersProducts: (filters: FilterProducts) => void
  handlerRemoveFiltersProducts: () => void
  verifyModalFilterActive: (keyLocal: ModalActivesFiltersNames) => boolean
  handlerOpenModal: (keyLocal: ModalActivesFiltersNames) => void
  handlerCloseModal: (keyLocal: ModalActivesFiltersNames) => void
}

type Filter = {
  status: String | String[] | any
  category: String | String[] | any
  cost: String | String[] | any
  code: string
  order: string
  name: string
  dueDateInitial: string
  dueDateFinal: string
  paymentDateInitial: string
  paymentDateFinal: string
  competenceDate: string
}

type FilterTaxControl = {
  startAt: string
  endAt: string
  specialistIds: String[] | string
  status: String[] | string
  statusPayment?: String[] | string
}

type FilterGuideReceived = {
  startAt: string
  endAt: string
  specialistIds: String[] | string
  status: String[] | string
  statusPayment: any
  blockGuide: string
  numberGuide: string
  numberTicket: string
  isHaveTicket: string
  patientName: string
}

type FilterConciliation = {
  startAt: string
  endAt: string
  endAtConciliation: string
  startAtConciliation: string
  meansToReceiveIds: String[] | string
  statusReceive: String[] | string
  statusConciliation: String[] | string
}

type FilterProducts = {
  types: String[] | string
  groupsIds: String[] | string
}

export type ModalActivesFiltersNames =
  | 'BillsToPay'
  | 'BillsToReceive'
  | 'Conciliation'
  | 'Services'
  | 'TaxControl'
  | 'Products'
  | undefined

type Language = 'pt' | 'en' | 'es'

type SelectTemplate = {
  value: number
  label: string
}

export const ModalFiltersContext = createContext({} as ModalData)

export function ModalFiltersProvider({ children }: ModalProviderProps) {
  const language = ls.get<Language>('@CLINIC:LANGUAGE_DEFAULT')
  const [LocaleApp, setLocaleApp] = useState<Locale>(() => {
    switch (language) {
      case 'en':
        return en
      case 'es':
        return es

      default:
        return ptBr
    }
  })
  const [defaultTemplate, setDefaultTemplate] = useState<SelectTemplate | null>(
    null
  )

  // scheduling filters insert in context
  const [specialistIds, setSpecialistIds] = useState<String[] | String>([])
  const [isLoginSpecialistIsDefault, setIsLoginSpecialistIsDefault] =
    useState(false)
  const [statusParam, setStatusParam] = useState<String[] | String>([])
  const [searchDefaultScheduling, setSearchDefaultScheduling] = useState('')
  const [dateScheduling, setDateScheduling] = useState(
    format(new Date(), 'yyyy-MM-dd')
  )

  // client filters insert in context
  const [searchDefaultClient, setSearchDefaultClient] = useState('')
  // state tab client context
  const [tabIndexClient, setTabIndexClient] = useState(1)
  // Get current date
  const date = new Date()
  const dateReceived = format(new Date(), 'yyyy-MM-dd')
  // Handle Actions disclosure
  const actions = useDisclosure()
  // isOpen modals
  const [isOpenBillsToPay, setIsOpenBillsToPay] = useState(false)
  const [isOpenBillsToReceive, setIsOpenBillsToReceive] = useState(false)
  const [isOpenConciliation, setIsOpenConciliation] = useState(false)
  const [isOpenServices, setIsOpenServices] = useState(false)
  const [isOpenTaxControl, setIsOpenTaxControl] = useState(false)
  const [isOpenProducts, setIsOpenProducts] = useState(false)
  // states dailyBox
  const [searchDailyBox, setSearchDailyBox] = useState('')
  const [dateInitialDailyBox, setDateInitialDailyBox] = useState(
    format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp })
  )
  const [dateFinalDailyBox, setDateFinalDailyBox] = useState(
    format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp })
  )
  const [numberTicketDailyBox, setNumberTicketDailyBox] = useState('')
  const [patientNameDailyBox, setPatientNameDailyBox] = useState('')
  const [statusPaymentFilterDailyBox, setStatusPaymentFilterDailyBox] =
    useState<any>([])
  const [wayReceiveFilterDailyBox, setWayReceiveFilterDaily] = useState<any>([])
  const [statusConferenceFilterDailyBox, setStatusConferenceFilterDailyBox] =
    useState<any>([])
  const [generateInvoiceFilterDailyBox, setGenerateInvoiceFilterDailyBox] =
    useState<any>([])
  const [patientIdDailyBox, setPatientIdDailyBox] = useState('')
  const [patientReference, setPatientReference] = useState('')
  // tickets canceled
  const [dateInitialTicketsCanceled, setDateInitialTicketsCanceled] = useState(
    format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp })
  )
  const [dateFinalTicketsCanceled, setDateFinalTicketsCanceled] = useState(
    format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp })
  )
  const [
    generateInvoiceFilterTicketsCanceled,
    setGenerateInvoiceFilterTicketsCanceled
  ] = useState<any>([])
  // states taxControl
  const [startAtTaxControl, setStartAtTaxControl] = useState(
    `${dateReceived}T00:00`
  )
  const [endAtTaxControl, setEndAtTaxControl] = useState(
    `${dateReceived}T23:59`
  )
  const [specialistIdsTaxControl, setSpecialistTaxControl] = useState<
    String[] | String
  >([])
  const [statusTaxControl, setStatusTaxControl] = useState<String[] | String>(
    []
  )
  const [statusPaymentTaxControl, setStatusPaymentTaxControl] = useState<
    String[] | String
  >([])
  // states bills to receive filters
  const [defaultSearchBillsToReceive, setDefaultSearchBillsToReceive] =
    useState('')
  const [startAtBillsToReceive, setStartAt] = useState(`${dateReceived}T00:00`)
  const [endAtBillsToReceive, setEndAt] = useState(`${dateReceived}T23:59`)
  const [specialistIdsReceived, setSpecialistIdsReceived] = useState<
    String[] | String
  >([])
  const [blockGuideReceived, setBlockGuideReceived] = useState('')
  const [statusPaymentReceived, setStatusPaymentReceived] = useState<
    String[] | String
  >([])
  const [isHaveTicketReceived, setIsHaveTicketReceived] = useState('')
  const [numberTicketReceived, setNumberTicketReceived] = useState('')
  const [numberGuideReceived, setNumberGuideReceived] = useState('')
  const [statusParamReceived, setStatusParamReceived] = useState<
    String[] | String
  >([])
  // conciliation filters
  const [startAtScheduleConciliation, setStartAtScheduleConciliation] =
    useState('')
  const [endAtScheduleConciliation, setEndAtScheduleConciliation] = useState('')
  const [startAtConciliation, setStartAtConciliation] = useState(
    `${dateReceived}T00:00`
  )
  const [endAtConciliation, setEndAtConciliation] = useState(
    `${dateReceived}T23:59`
  )
  const [waysParam, setWaysParam] = useState<String[] | String>([])
  const [statusConciliation, setStatusConciliation] = useState<
    String[] | String
  >([])
  const [statusReceiveConciliation, setStatusReceiveConciliation] = useState<
    String[] | String
  >([])
  // ActiveFilter
  const [isActiveFilterBillsToPay, setIsActiveFilterBillsToPay] =
    useState(false)
  const [isActiveFilterBillsToReceive, setIsActiveFilterBillsToReceive] =
    useState(false)
  const [isActiveFilterConciliation, setIsActiveFilterConciliation] =
    useState(false)
  const [isActiveFilterServices, setIsActiveFilterServices] = useState(false)
  const [isActiveFilterTaxControl, setIsActiveFilterTaxControl] =
    useState(false)
  const [isActiveFilterProducts, setIsActiveFilterProducts] = useState(false)
  // Filters BillsToPay State
  // modal index tab
  const [tabIndex, setTabIndex] = useState(0)
  const [status, setStatus] = useState<any>(['1', '2', '3'])
  const [code, setCode] = useState('')
  const [order, setOrder] = useState('due_date')
  const [name, setName] = useState('')
  const [dueDateInitial, setDueDateInitial] = useState(
    format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')
  )
  const [dueDateFinal, setDueDateFinal] = useState(
    format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd')
  )
  const [paymentDateInitial, setPaymentDateInitial] = useState('')
  const [paymentDateFinal, setPaymentDateFinal] = useState('')
  const [competenceDate, setCompetenceDate] = useState('')
  const [category, setCategory] = useState<any>([])
  const [cost, setCost] = useState<any>([])
  // services filters
  const [searchState, setSearchState] = useState('')
  const [servicesFilter, setServicesFilter] = useState<String[] | String>([])

  // products stock filters
  const [searchStateProducts, setSearchStateProducts] = useState('')
  const [groupsIds, setGroupsIds] = useState<String[] | String>([])
  const [typesProducts, setTypeProducts] = useState<String[] | String>([])

  useEffect(() => {
    switch (language) {
      case 'en':
        setLocaleApp(en)
        break
      case 'es':
        setLocaleApp(es)
        break

      default:
        setLocaleApp(ptBr)
        break
    }

    return () => {}
  }, [language])

  // verify active modals
  const verifyModalFilterActive = (keyLocal: ModalActivesFiltersNames) => {
    let isActive = false

    switch (keyLocal) {
      case 'BillsToPay':
        if (isActiveFilterBillsToPay === true) {
          isActive = true
        }
        return isActive
      case 'BillsToReceive':
        if (isActiveFilterBillsToReceive === true) {
          isActive = true
        }
        return isActive
      case 'Conciliation':
        if (isActiveFilterConciliation === true) {
          isActive = true
        }

        return isActive
      case 'Services':
        if (isActiveFilterServices === true) {
          isActive = true
        }

        return isActive
      case 'TaxControl':
        if (isActiveFilterTaxControl === true) {
          isActive = true
        }

        return isActive
      case 'Products':
        if (isActiveFilterProducts === true) {
          isActive = true
        }

        return isActive
      default:
        return isActive
    }
  }

  // change isOpen modal
  const handlerOpenModal = (keyLocal: ModalActivesFiltersNames) => {
    switch (keyLocal) {
      case 'BillsToPay':
        setIsOpenBillsToPay(true)
        break
      case 'BillsToReceive':
        setIsOpenBillsToReceive(true)
        break
      case 'Conciliation':
        setIsOpenConciliation(true)
        break
      case 'Services':
        setIsOpenServices(true)
        break
      case 'TaxControl':
        setIsOpenTaxControl(true)
        break
      case 'Products':
        setIsOpenProducts(true)
        break
      default:
        break
    }
  }

  const handlerCloseModal = (keyLocal: ModalActivesFiltersNames) => {
    switch (keyLocal) {
      case 'BillsToPay':
        setIsOpenBillsToPay(false)
        break
      case 'BillsToReceive':
        setIsOpenBillsToReceive(false)
        break
      case 'Conciliation':
        setIsOpenConciliation(false)
        break
      case 'Services':
        setIsOpenServices(false)
        break
      case 'TaxControl':
        setIsOpenTaxControl(false)
        break
      case 'Products':
        setIsOpenProducts(false)
        break
      default:
        break
    }
  }
  // change Close modal

  // Remove all Filters BillsToPay
  const handlerRemoveFilters = () => {
    // Filters BillsTopPay
    setIsActiveFilterBillsToPay(false)
    setStatus(['1', '2', '3'])
    setCategory([])
    setCost([])
    setCode('')
    setOrder('due_date')
    setName('')
    setDueDateInitial(
      format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')
    )
    setDueDateFinal(
      format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd')
    )
    setPaymentDateInitial('')
    setPaymentDateFinal('')
    setCompetenceDate('')
  }

  // Apply Filters BillsToPay
  const handlerApplyFiltersBillsToPay = (filters: Filter) => {
    setStatus(filters.status)
    setCategory(filters.category)
    setCost(filters.cost)
    setCode(filters.code)
    setOrder(filters.order)
    setName(filters.name)
    setDueDateInitial(filters.dueDateInitial)
    setDueDateFinal(filters.dueDateFinal)
    setPaymentDateInitial(filters.paymentDateInitial)
    setPaymentDateFinal(filters.paymentDateFinal)
    setCompetenceDate(filters.competenceDate)
    setIsActiveFilterBillsToPay(true)
  }

  // Remove all Filters for search box BillsToPay
  const handlerRemoveFiltersDefaultSearchBox = () => {
    setIsActiveFilterBillsToPay(false)
    setStatus(['1', '2', '3'])
    setCategory([])
    setCost([])
    setCode('')
    setOrder('due_date')
    setName('')
    setDueDateInitial('')
    setDueDateFinal('')
    setPaymentDateInitial('')
    setPaymentDateFinal('')
    setCompetenceDate('')
  }

  // Apply filters TaxControl
  const handlerApplyFiltersTaxControl = (filters: FilterTaxControl) => {
    setStartAtTaxControl(filters.startAt)
    setEndAtTaxControl(filters.endAt)
    setStatusTaxControl(filters.status)
    setSpecialistTaxControl(filters.specialistIds)
    setStatusPaymentTaxControl(filters?.statusPayment || [])

    setIsActiveFilterTaxControl(true)
    setIsOpenTaxControl(false)
  }

  // Remove filters TaxControl
  const handlerRemoveFiltersTaxControl = () => {
    setStartAtTaxControl(`${format(new Date(), 'yyyy-MM-dd')}T00:00`)
    setEndAtTaxControl(`${format(new Date(), 'yyyy-MM-dd')}T23:59`)
    setStatusTaxControl([])
    setSpecialistTaxControl([])
    setStatusPaymentTaxControl([])

    setIsActiveFilterTaxControl(false)
    setIsOpenTaxControl(false)
  }

  // Apply filters BillsToReceive
  const handlerApplyFiltersBillsToReceive = (filters: FilterGuideReceived) => {
    setStartAt(filters.startAt)
    setEndAt(filters.endAt)
    setStatusParamReceived(filters.status)
    setSpecialistIdsReceived(filters.specialistIds)

    setNumberGuideReceived(filters.numberGuide)
    setNumberTicketReceived(filters.numberTicket)
    setStatusPaymentReceived(filters.statusPayment)
    setBlockGuideReceived(filters.blockGuide)
    setIsHaveTicketReceived(filters.isHaveTicket)
    setDefaultSearchBillsToReceive(filters.patientName)

    setIsActiveFilterBillsToReceive(true)
    setIsOpenBillsToReceive(false)
  }

  // Remove filters BillsToReceive
  const handlerRemoveFiltersBillsToReceive = () => {
    setStartAt(`${format(new Date(), 'yyyy-MM-dd')}T00:00`)
    setEndAt(`${format(new Date(), 'yyyy-MM-dd')}T23:59`)
    setStatusParamReceived([])
    setSpecialistIdsReceived([])

    setIsActiveFilterBillsToReceive(false)
    setIsOpenBillsToReceive(false)
  }

  // Apply filters Conciliation
  const handlerApplyFiltersConciliation = (filters: FilterConciliation) => {
    setStartAtScheduleConciliation(filters.startAtConciliation)
    setEndAtScheduleConciliation(filters.endAtConciliation)
    setStartAtConciliation(filters.startAt)
    setEndAtConciliation(filters.endAt)
    setWaysParam(filters.meansToReceiveIds)
    setStatusConciliation(filters.statusConciliation)
    setStatusReceiveConciliation(filters.statusReceive)

    setIsActiveFilterConciliation(true)
    setIsOpenConciliation(false)
  }

  // Remove filters Conciliation
  const handlerRemoveFiltersConciliation = () => {
    setStartAtScheduleConciliation('')
    setEndAtScheduleConciliation('')
    setStartAtConciliation(`${format(new Date(), 'yyyy-MM-dd')}T00:00`)
    setEndAtConciliation(`${format(new Date(), 'yyyy-MM-dd')}T23:59`)
    setWaysParam([])
    setStatusConciliation([])
    setStatusReceiveConciliation([])

    setIsActiveFilterConciliation(false)
    setIsOpenConciliation(false)
  }

  // Apply filers Products
  const handlerApplyFiltersProducts = (filters: FilterProducts) => {
    setGroupsIds(filters.groupsIds)
    setTypeProducts(filters.types)

    setIsActiveFilterProducts(true)
    setIsOpenProducts(false)
  }

  // Remove filters Products
  const handlerRemoveFiltersProducts = () => {
    setGroupsIds([])
    setTypeProducts([])

    setIsActiveFilterProducts(false)
    setIsOpenProducts(false)
  }

  return (
    <ModalFiltersContext.Provider
      value={{
        actions,
        defaultTemplate,
        patientIdDailyBox,
        setPatientIdDailyBox,
        patientReference,
        setPatientReference,
        setDefaultTemplate,
        handlerApplyFiltersProducts,
        handlerRemoveFiltersProducts,
        isHaveTicketReceived,
        numberGuideReceived,
        blockGuideReceived,
        numberTicketReceived,
        setBlockGuideReceived,
        setIsHaveTicketReceived,
        setNumberTicketReceived,
        setStatusPaymentReceived,
        statusPaymentReceived,
        dateFinalDailyBox,
        setDateFinalDailyBox,
        searchDailyBox,
        setDateInitialDailyBox,
        dateInitialDailyBox,
        setSearchDailyBox,
        generateInvoiceFilterDailyBox,
        dateFinalTicketsCanceled,
        dateInitialTicketsCanceled,
        generateInvoiceFilterTicketsCanceled,
        setDateFinalTicketsCanceled,
        setDateInitialTicketsCanceled,
        setGenerateInvoiceFilterTicketsCanceled,
        setGenerateInvoiceFilterDailyBox,
        setStatusConferenceFilterDailyBox,
        setStatusPaymentFilterDailyBox,
        setWayReceiveFilterDaily,
        statusConferenceFilterDailyBox,
        statusPaymentFilterDailyBox,
        wayReceiveFilterDailyBox,
        groupsIds,
        isActiveFilterProducts,
        isOpenProducts,
        typesProducts,
        searchStateProducts,
        setSearchStateProducts,
        category,
        cost,
        dueDateFinal,
        endAtTaxControl,
        handlerRemoveFiltersBillsToReceive,
        searchDefaultClient,
        searchDefaultScheduling,
        setSearchDefaultClient,
        setSearchDefaultScheduling,
        defaultSearchBillsToReceive,
        setDefaultSearchBillsToReceive,
        handlerApplyFiltersBillsToReceive,
        specialistIdsTaxControl,
        startAtTaxControl,
        statusTaxControl,
        handlerApplyFiltersTaxControl,
        handlerRemoveFiltersTaxControl,
        handlerApplyFiltersConciliation,
        handlerRemoveFiltersConciliation,
        setTabIndex,
        tabIndex,
        dueDateInitial,
        statusPaymentTaxControl,
        order,
        code,
        name,
        status,
        paymentDateFinal,
        setTabIndexClient,
        tabIndexClient,
        paymentDateInitial,
        LocaleApp,
        isOpenServices,
        patientNameDailyBox,
        numberTicketDailyBox,
        setPatientNameDailyBox,
        setNumberTicketDailyBox,
        competenceDate,
        isActiveFilterBillsToPay,
        setIsActiveFilterBillsToPay,
        handlerRemoveFilters,
        handlerApplyFiltersBillsToPay,
        handlerRemoveFiltersDefaultSearchBox,
        setIsActiveFilterConciliation,
        verifyModalFilterActive,
        handlerOpenModal,
        handlerCloseModal,
        isOpenBillsToPay,
        isOpenBillsToReceive,
        setIsActiveFilterBillsToReceive,
        setIsActiveFilterServices,
        dateScheduling,
        setDateScheduling,
        setSpecialistIds,
        setStatusParam,
        specialistIds,
        statusParam,
        startAtBillsToReceive,
        setStartAt,
        endAtBillsToReceive,
        setEndAt,
        specialistIdsReceived,
        setSpecialistIdsReceived,
        statusParamReceived,
        setStatusParamReceived,
        isActiveFilterBillsToReceive,
        isOpenConciliation,
        startAtConciliation,
        endAtConciliation,
        waysParam,
        statusConciliation,
        statusReceiveConciliation,
        setStartAtConciliation,
        setEndAtConciliation,
        setWaysParam,
        setStatusConciliation,
        setStatusReceiveConciliation,
        startAtScheduleConciliation,
        setStartAtScheduleConciliation,
        setEndAtScheduleConciliation,
        endAtScheduleConciliation,
        setSearchState,
        searchState,
        setServicesFilter,
        servicesFilter,
        isActiveFilterServices,
        isOpenTaxControl,
        isLoginSpecialistIsDefault,
        setIsLoginSpecialistIsDefault
      }}
    >
      {children}
    </ModalFiltersContext.Provider>
  )
}
