/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Input } from '../../components/Form/Input'
import { Header } from '../../components/Header'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Draft } from '../../components/molecules/Draft'
import { Sidebar } from '../../components/Sidebar'

import { Select } from '../../components/Form/Select'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { Switch } from '../../components/Form/Switch'
import { ImageComponent } from '../../components/Webcam/ImageComponent'
import { useDocument } from '../../hooks/useDocument'
import DropZone from '../Clients/Dropzone'
import { useAttendance } from './service'

type FormData = {
  is_active: boolean
  description: string
}

type Actions = {
  label: string
  value: string
}

type Specialist = {
  label: string
  value: number
}

type SpecialistData = Array<Specialist>

export function UpdateDocumentPrint() {
  const [t] = useTranslation('pageDocumentPrint')
  const attendanceService = useAttendance()
  const { imgSrc, setImgSrc } = useDocument()
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const optionsDefaultActions = [
    {
      value: '1',
      label: `${t('status.1')}`
    },
    {
      value: '2',
      label: `${t('status.2')}`
    }
  ]
  const optionsDefaultMarginX = [
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' }
  ]
  const optionsDefaultMarginY = [
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '47', label: '47' },
    { value: '48', label: '48' },
    { value: '49', label: '49' },
    { value: '50', label: '50' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '53', label: '53' },
    { value: '54', label: '54' },
    { value: '55', label: '55' },
    { value: '56', label: '56' },
    { value: '57', label: '57' },
    { value: '58', label: '58' },
    { value: '59', label: '59' },
    { value: '60', label: '60' },
    { value: '61', label: '61' },
    { value: '62', label: '62' },
    { value: '63', label: '63' },
    { value: '64', label: '64' },
    { value: '65', label: '65' },
    { value: '66', label: '66' },
    { value: '67', label: '67' },
    { value: '68', label: '68' },
    { value: '69', label: '69' },
    { value: '70', label: '70' },
    { value: '71', label: '71' },
    { value: '72', label: '72' },
    { value: '73', label: '73' },
    { value: '74', label: '74' },
    { value: '75', label: '75' },
    { value: '76', label: '76' },
    { value: '77', label: '77' },
    { value: '78', label: '78' },
    { value: '79', label: '79' },
    { value: '80', label: '80' },
    { value: '81', label: '81' },
    { value: '82', label: '82' },
    { value: '83', label: '83' },
    { value: '84', label: '84' },
    { value: '85', label: '85' },
    { value: '86', label: '86' },
    { value: '87', label: '87' },
    { value: '88', label: '88' },
    { value: '89', label: '89' },
    { value: '90', label: '90' },
    { value: '91', label: '91' },
    { value: '92', label: '92' },
    { value: '93', label: '93' },
    { value: '94', label: '94' },
    { value: '95', label: '95' },
    { value: '96', label: '96' },
    { value: '97', label: '97' },
    { value: '98', label: '98' },
    { value: '99', label: '99' },
    { value: '100', label: '100' }
  ]
  // state
  const [name, setName] = useState('')
  const [actions, setActions] = useState<Actions>()
  const [specialists, setSpecialists] = useState<SpecialistData>([])
  const [header, setHeader] = useState<any>()
  const [footer, setFooter] = useState<any>()
  const [marginLeft, setMarginLeft] = useState('5')
  const [marginRight, setMarginRight] = useState('5')
  const [marginTop, setMarginTop] = useState('5')
  const [marginBottom, setMarginBottom] = useState('5')
  const router = useHistory()
  const { state }: any = router.location

  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }

    if (state?.status !== undefined) {
      optionsDefaultActions.forEach((action) => {
        if (String(state.status) === action.value) {
          setActions(action)
        }
      })
    }

    if (state?.users !== undefined) {
      setSpecialists(
        state?.users.map((user: any) => {
          return {
            value: user?.id,
            label: user?.social_name
          }
        })
      )
    }

    if (state?.header) {
      setHeader(state?.header)
    }

    if (state?.footer) {
      setFooter(state?.footer)
    }

    if (state?.margin_top) {
      setMarginTop(state?.margin_top?.toFixed(0))
    }

    if (state?.margin_left) {
      setMarginLeft(state?.margin_left?.toFixed(0))
    }

    if (state?.margin_bottom) {
      setMarginBottom(state?.margin_bottom?.toFixed(0))
    }

    if (state?.margin_right) {
      setMarginRight(state?.margin_right?.toFixed(0))
    }

    if (state?.logo) {
      setImgSrc(state?.logo)
    }
  }, [])

  const handleSelectAction = (event: any) => {
    setActions(event)
  }

  const handlerSelectSpecialist = (event: any) => {
    setSpecialists(event)
  }

  const handleKeyUpEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = event.target.value.toUpperCase()
    setName(values)
  }

  // format users specialist for preferences body
  const returnUsersSpecialist = () => {
    const users = specialists.map((user) => {
      return {
        id: user.value,
        name: user.label
      }
    })

    return users
  }

  const handlerUpdateRecipe: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const { is_active, description } = values

    const users = returnUsersSpecialist()

    console.log(imgSrc)

    const payload = {
      id: state?.id,
      name,
      is_active,
      description: description === '' ? null : description,
      users,
      status: Number(actions?.value),
      header: header === undefined ? '<p><br><p>' : header,
      footer: footer === undefined ? '<p><br><p>' : footer,
      logo: imgSrc === '' ? undefined : imgSrc,
      margin_top: marginTop === '' ? 0 : Number(marginTop),
      margin_left: marginLeft === '' ? 0 : Number(marginLeft),
      margin_bottom: marginBottom === '' ? 0 : Number(marginBottom),
      margin_right: marginRight === '' ? 0 : Number(marginRight)
    }

    attendanceService.updateDocumentPrint(payload)
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('updateTitle')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/settings/template">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handlerUpdateRecipe)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Flex flex="1" direction="column">
                <Stack direction="row" my="2" spacing="4">
                  <Box w="50%">
                    <Input
                      label={t('create.container1.i1')}
                      name="name"
                      fontSize="16"
                      placeholder={t('placeholder.name')}
                      isRequired
                      value={name}
                      onChange={handleKeyUpEvent}
                      maxLength={30}
                    />
                  </Box>
                  <Box w="300px" mr="2">
                    <SelectComponent
                      options={optionsDefaultActions}
                      name="status"
                      value={actions}
                      onChange={handleSelectAction}
                      title={t('label.status')}
                      placeholder={t('placeholder.status')}
                    />
                  </Box>
                  <Box>
                    <Switch
                      defaultChecked={state?.is_active || true}
                      label="Ativo?"
                      {...register('is_active')}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" my="2" spacing="4">
                  <Box w="280px">
                    <Select
                      label={t('create.container1.i3')}
                      name="marginRight"
                      options={optionsDefaultMarginX}
                      value={marginRight}
                      onChange={(e) => setMarginRight(e.currentTarget.value)}
                    />
                  </Box>
                  <Box w="280px">
                    <Select
                      label={t('create.container1.i4')}
                      options={optionsDefaultMarginX}
                      name="marginLeft"
                      value={marginLeft}
                      onChange={(e) => setMarginLeft(e.currentTarget.value)}
                    />
                  </Box>
                  <Box w="280px">
                    <Select
                      label={t('create.container1.i5')}
                      options={optionsDefaultMarginY}
                      name="marginTop"
                      value={marginTop}
                      onChange={(e) => setMarginTop(e.currentTarget.value)}
                    />
                  </Box>
                  <Box w="280px">
                    <Select
                      label={t('create.container1.i6')}
                      options={optionsDefaultMarginY}
                      name="marginBottom"
                      value={marginBottom}
                      onChange={(e) => setMarginBottom(e.currentTarget.value)}
                    />
                  </Box>
                </Stack>
                <Flex direction="column">
                  <Text mb="2" fontSize="sm">
                    {t('create.container1.i7')}
                  </Text>
                  <Textarea
                    placeholder={t('create.container1.description')}
                    {...register('description')}
                    defaultValue={state?.description}
                    maxLength={200}
                  />
                </Flex>
              </Flex>
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Stack direction="column" my="2" spacing="6" w="100%">
              <SelectComponent
                options={
                  attendanceService.specialists?.length &&
                  attendanceService.specialists.map((specialist) => {
                    return {
                      value: specialist?.user?.id,
                      label: specialist?.social_name || specialist?.user?.name
                    }
                  })
                }
                value={specialists}
                title={t('create.container2.i1')}
                isMulti
                onChange={handlerSelectSpecialist}
                name="specialists"
                placeholder={t('create.container2.specialist')}
              />
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.dictionary.title')}>
            <Flex
              w="100%"
              p="2"
              flexDir="column"
              borderRadius="16px"
              border="1px solid gray"
              mb="2"
            >
              <Text fontWeight="bold" fontSize="lg" my="2">
                Dicionário para criação automatizada
              </Text>
              <Stack direction="row" w="100%">
                <Stack w="50%" direction="column">
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.client')}
                  </Text>
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.clientDocument')}
                  </Text>
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.clientBirthDate')}
                  </Text>
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.address')}
                  </Text>
                </Stack>
                <Stack w="50%" direction="column">
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.specialistName')}
                  </Text>
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.specialistDocument')}
                  </Text>
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.now')}
                  </Text>
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.clinic')}
                  </Text>
                  <Text
                    p="2"
                    bg="blue.300"
                    color="white"
                    fontWeight="bold"
                    borderRadius={16}
                  >
                    {t('dictionary.addressClinic')}
                  </Text>
                </Stack>
              </Stack>
            </Flex>
          </CardContainer>

          <CardContainer title={t('create.container3.title')}>
            <Stack direction={['column', 'row']} spacing="4">
              <Box>
                <Text fontSize="14px" borderRadius={16}>
                  {t('create.container1.i2')}
                </Text>
                <Box>{imgSrc ? <ImageComponent /> : <DropZone />}</Box>
              </Box>
              <Draft
                getValue={(value: any) => {
                  setHeader(value)
                }}
                defaultValue={state?.header}
              />
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container4.title')}>
            <Draft
              getValue={(value: any) => {
                setFooter(value)
              }}
              defaultValue={state?.footer}
            />
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
