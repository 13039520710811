import { ElementType, ReactNode } from 'react'
import {
  Link as ChakraLink,
  Icon,
  Text,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/react'
import { ActiveLink } from './ActiveLink'

interface NavLinkProps extends Readonly<ChakraLinkProps> {
  readonly icon?: ElementType
  readonly children: ReactNode
  readonly to: string
}

export function NavLink({
  icon,
  children,
  to,
  ...rest
}: Readonly<NavLinkProps>) {
  return (
    <ActiveLink to={to}>
      <ChakraLink display="flex" align="center" {...rest}>
        {!!icon && <Icon as={icon} fontSize="18px" mt="2px" />}
        <Text ml="4" fontSize="14px">
          {children}
        </Text>
      </ChakraLink>
    </ActiveLink>
  )
}
