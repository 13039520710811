/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiAuth } from '../../services/apiAuth'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { ServicesData } from './service'

interface Client {
  value: number
  label: string
}

interface Service {
  value: number
  label: string
  isFixed?: boolean
  id?: number
}

interface Specialist {
  value: number
  label: string
}

interface Spot {
  value: number
  label: string
}

type ServiceData = Service[] | null

type SpecialistData = Specialist | null

type SpotData = Spot | null

type ClientData = Client | null

type SerDataInfo = (ServicesData | undefined)[] | null

interface InformationClient {
  id: number
  name: string
  contacts_patient: Array<{
    contact: string
  }>
  patient_tags: Array<number>
}

type InfoClientData = InformationClient | null

export const useLogic = () => {
  // hooks terceiros
  const toast = useToast()
  // states
  // state client select
  const [clientSelect, setClientSelect] = useState<ClientData>(null)

  // all infos client
  const [infoClient, setInfoClient] = useState<InfoClientData>(null)
  const [clientPhone, setClientPhone] = useState('')
  const [clientObservation, setClientObservation] = useState('')
  const [clientAvatar, setClientAvatar] = useState<string | null>(null)
  const [isLoadingInfoClient, setIsLoadingInfoClient] = useState(false)
  const [searchClient, setSearchClient] = useState('')

  // state specialist select
  const [specialistSelect, setSpecialistSelect] = useState<SpecialistData>(null)

  // state services select
  const [servicesSelects, setServicesSelects] = useState<ServiceData>([])
  // state spot select
  const [spotSelect, setSpotSelect] = useState<SpotData>(null)
  // use by calculate hours event
  const [serInfo, setSerInfo] = useState<SerDataInfo>(null)
  // state hours and date
  const [attendanceDate, setAttendanceDate] = useState('')
  const [timeToBeThere, setTimeToBeThere] = useState('')
  const [startHours, setStartHours] = useState('')
  const [endHours, setEndHours] = useState('')

  const getClientInfoById = async (id: number) => {
    try {
      setIsLoadingInfoClient(true)
      const { data } = await apiAuth.get(`clinics/patients/${id}/`)

      const { data: telephones } = await apiAuth.get(
        `clinics/patients/${id}/telephones/`
      )

      if (telephones.length !== undefined) {
        if (telephones.length > 0) {
          setClientPhone(telephones[0].number)
        } else {
          setClientPhone('')
        }
      }

      if (data.observation !== undefined) {
        if (data.observation !== null) {
          setClientObservation(data.observation)
        } else {
          setClientObservation('')
        }
      }

      if (data.image !== undefined) {
        setClientAvatar(data.image)
      } else {
        setClientAvatar('')
      }

      setInfoClient(data)
      setIsLoadingInfoClient(false)
    } catch (error: any) {
      console.log(error)
      setIsLoadingInfoClient(false)
    }
  }

  // get info client
  useEffect(() => {
    if (clientSelect === null) {
      return
    }

    getClientInfoById(clientSelect?.value)
  }, [clientSelect])

  // select client
  const handleSelectClient = (event: any) => {
    setClientSelect(event)
  }

  // select specialist
  const handleSelectSpecialist = (event: any) => {
    setSpecialistSelect(event)
  }

  // clean client select
  const handleCleanClientInSelect = () => {
    setClientSelect(null)
    setInfoClient(null)
    setClientPhone('')
    setClientObservation('')
    setClientAvatar(null)
  }

  // select services
  const handleSelectServices = (event: any) => {
    setServicesSelects(event)
  }

  const handleSelectSpot = (event: any) => {
    setSpotSelect(event)
  }

  // function get max value time to be there in Services
  function getMaxTimeInServicesTimeToBeThere() {
    if (serInfo === null) {
      return 0
    }

    const maxValueTimeToBeThereArray = serInfo.map((ser) => {
      const maxValue = Math.max(ser?.service_sessions[0].time_to_be_there || 0)

      return maxValue
    })

    const maxValueInArray = Math.max(...maxValueTimeToBeThereArray)

    return maxValueInArray
  }

  // function get minutes acc services selected
  function getAccValuesTimeServices() {
    if (serInfo === null) {
      return 0
    }

    const valuesArrayAcc = serInfo.map((ser) => {
      const maxValue = Math.max(ser?.service_sessions[0].duration || 0)

      return maxValue
    })

    const totalValueInArray = valuesArrayAcc.reduce((acc, number) => {
      return acc + number
    }, 0)

    return totalValueInArray
  }

  // function get minutes for hours and calculate total minutes
  function getMinutesForCalculate(hours: string) {
    const hoursValue = hours.substring(0, 2)
    const minuteValue = hours.substring(2, 4)
    const hoursInMinute = Number(hoursValue) * 60
    const minute = Number(minuteValue)

    const totalMinute = hoursInMinute + minute
    return totalMinute
  }

  // function get new hours for set value state calculate Attendance Start
  function getNewHoursAttendance(min: number, minAcc: number) {
    const totalAllMinutes = min + minAcc
    const hours = Math.floor(totalAllMinutes / 60)
    const minutes = totalAllMinutes % 60
    const [hoursLeft, hoursRight] = String(hours).padStart(2, '0').split('')
    const [minutesLeft, minutesRight] = String(minutes)
      .padStart(2, '0')
      .split('')

    if (Number(`${hoursLeft}${hoursRight}`) > 23) {
      // get date
      // const day = Number(attendanceDate.substring(8, 10))
      // const month = Number(attendanceDate.substring(5, 7)) - 1
      // const year = Number(attendanceDate.substring(0, 4))
      // const date = new Date(year, month, day)
      // const tomorrow = format(date.setDate(date.getDate() + 1), 'yyyy-MM-dd')
      // setAttendanceDate(tomorrow)
      const hourInvalid = Number(`${hoursLeft}${hoursRight}`)
      const recoverHours = hourInvalid - 24
      const [hoursRecoverLeft, hoursRecoverRight] = String(recoverHours)
        .padStart(2, '0')
        .split('')

      return `${hoursRecoverLeft}${hoursRecoverRight}:${minutesLeft}${minutesRight}`
    }

    return `${hoursLeft}${hoursRight}:${minutesLeft}${minutesRight}`
  }

  // calculate new hours prevision Start and End
  const handleActionButtonCalculateHours = () => {
    // services null
    if (serInfo === null) {
      toast({
        status: 'warning',
        position: 'top',
        description:
          'Novo serviço não foi selecionado, selecione pelo menos 1 serviço.',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })

      return
    }

    // invalid length hours
    if (timeToBeThere.length !== 5) {
      toast({
        status: 'warning',
        position: 'top',
        description: 'A Hora de chegada não foi informada corretamente!',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })

      return
    }

    // get value hours remask and validate
    const valueValidation = remaskCaractersAll(timeToBeThere)
    const valueValidationMinute = valueValidation.substring(2, 4)

    if (Number(valueValidation) > 2359) {
      toast({
        status: 'error',
        position: 'top',
        description: 'A Hora de chegada informada não é um horário válido!',
        duration: 3000,
        title: 'Error!',
        isClosable: true
      })

      return
    }

    if (Number(valueValidationMinute) > 59) {
      toast({
        status: 'error',
        position: 'top',
        description: 'A Hora de chegada informada não é um horário válido!',
        duration: 3000,
        title: 'Error!',
        isClosable: true
      })

      return
    }

    const maxValueToBeThere = getMaxTimeInServicesTimeToBeThere()
    const HoursForMinute = getMinutesForCalculate(valueValidation)

    // start prevision attendance and hours calcule end prevision
    const newHoursAttendanceStart = getNewHoursAttendance(
      HoursForMinute,
      maxValueToBeThere
    )

    // value acc service
    const serviceDuration = getAccValuesTimeServices()

    // remove :
    const remaskStartHours = remaskCaractersAll(newHoursAttendanceStart)
    const StartPrevisionMinutes = getMinutesForCalculate(remaskStartHours)

    const newHoursAttendanceEnd = getNewHoursAttendance(
      StartPrevisionMinutes,
      serviceDuration
    )

    setStartHours(newHoursAttendanceStart)
    setEndHours(newHoursAttendanceEnd)
  }

  // get input value Attendance arrived time
  const handleInputHoursArrivedTime = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setTimeToBeThere(event.currentTarget.value)
  }

  // get input value Attendance start scheduling
  const handleInputHoursStartAttendanceTime = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setStartHours(event.currentTarget.value)
  }

  // get input value Attendance start scheduling
  const handleInputHoursEndAttendanceTime = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setEndHours(event.currentTarget.value)
  }

  return {
    clientSelect,
    clientPhone,
    setSerInfo,
    serInfo,
    clientObservation,
    setStartHours,
    setEndHours,
    specialistSelect,
    setSpecialistSelect,
    searchClient,
    setSearchClient,
    servicesSelects,
    attendanceDate,
    setSpotSelect,
    spotSelect,
    startHours,
    endHours,
    setServicesSelects,
    handleSelectSpecialist,
    handleInputHoursStartAttendanceTime,
    handleInputHoursEndAttendanceTime,
    handleSelectSpot,
    setTimeToBeThere,
    timeToBeThere,
    handleSelectServices,
    isLoadingInfoClient,
    setAttendanceDate,
    setClientSelect,
    handleInputHoursArrivedTime,
    clientAvatar,
    infoClient,
    handleCleanClientInSelect,
    handleActionButtonCalculateHours,
    setInfoClient,
    handleSelectClient
  }
}
