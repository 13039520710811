/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton
} from '@chakra-ui/react'
import { FiEdit } from 'react-icons/fi'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCost, getCost, CostsData } from '../../../hooks/cost/useCost'
import { useAuth } from '../../../hooks/auth'
import { LayoutList } from '../../../layouts/List'
import { NotFound } from '../../../components/molecules/NotFound'
import { Pagination } from '../../../components/PaginationNew'
import { PermissionComponent } from '../../../components/Permissions'
import { useQuery } from 'react-query'

// debounce
let timer: any = null

export function CostCenters() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageCostCenters')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)

  const {
    data: getCostsData,
    isFetching,
    refetch,
    isLoading
  } = useQuery(
    ['costsCenters', String(clinicId), currentPage, searchState, isActive],
    () => getCost(String(clinicId), currentPage, searchState, isActive)
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  useEffect(() => {
    refetch()
  }, [])

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  return (
    <LayoutList
      title={t('title')}
      urlNew="/financial/cost-centers/create"
      refetch={refetch}
      isFetching={isFetching}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="COST_CENTERS"
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getCostsData?.costs.map((cost: CostsData, index: number) => (
                  <Tr key={cost.id} maxHeight="40px">
                    <Td>
                      <Text fontSize="sm">{cost.name}</Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm">{cost.observation}</Text>
                    </Td>
                    {cost.is_default === true ? (
                      <Td>
                        <Text fontSize="sm">{t('is_default')}</Text>
                      </Td>
                    ) : (
                      <Td />
                    )}
                    <Td>
                      <PermissionComponent spiCode="COST_CENTERS" ipCode="EDIT">
                        <Link
                          to={{
                            pathname: `/financial/cost-centers/update/${cost.id}`,
                            state: cost
                          }}
                        >
                          <Tooltip
                            label="Editar"
                            aria-label="editar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box ml="1rem" w="24px">
                              <FiEdit
                                color="#9699B0"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </Link>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {getCostsData?.totalCountOfPage === 0 && !isLoading && <NotFound />}
      </Box>
      {!(getCostsData?.totalCountOfPage === 0 && !isLoading) && (
        <Pagination
          totalCountOfRegisters={getCostsData?.totalCountOfPage || 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </LayoutList>
  )
}
