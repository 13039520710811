/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type BlockTimesData = {
  id: number
  name: string
  description: string | null
  start_time: string | null
  finish_time: string | null
  is_recurrent: boolean
  week_day: string | null
  specialists: Array<{
    id: number
    name: string
    social_name: string
  }>
  created_at: string
  expand_recurrent_days: boolean | null
  recurrent_start_time: string | null
  recurrent_finish_time: string | null
}

export type ResponseGetBlockTimes = {
  times: BlockTimesData[]
  totalCountOfPage: number
}

export const getBlockTimes = async (
  page: number,
  startTime: string,
  finishTime: string,
  keyword: string
): Promise<ResponseGetBlockTimes> => {
  try {
    const { data } = await apiAuth.get(`clinics/block-times/`, {
      params: {
        page,
        startTime,
        finishTime,
        keyword
      }
    })

    const totalCountOfPage = data.count
    const times: BlockTimesData[] = data.results || []

    return {
      times,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const times: BlockTimesData[] = []
    const totalCountOfPage = 0
    return {
      times,
      totalCountOfPage
    }
  }
}

export function useBlockTimes(
  page: number,
  functionLoading: Promise<ResponseGetBlockTimes>,
  startTime: string,
  finishTime: string,
  keyword: string,
  options?: {}
) {
  return useQuery(
    ['blockTimes', page, startTime, finishTime, keyword],
    () => functionLoading
  )
}
