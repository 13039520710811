/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type UserData = {
  id: string
  user: {
    id: string
    name: string
    email: string
    is_active: boolean
    is_specialist: boolean
    is_superuser: boolean
  }
  birth_date: string | null
  telephone: string | null
  social_name: string | null
  role: string | null
  observation: string | null
  user_contacts: []
}

export type ResponseGetUsers = {
  users: UserData[]
  totalCountOfPage: number
}

export const getUsers = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetUsers> => {
  try {
    const { data } = await apiAuth.get(`users/`, {
      params: {
        page,
        keyword: search,
        user__is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const users: UserData[] = data.results

    return {
      users,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const users: UserData[] = []
    const totalCountOfPage = 0
    return {
      users,
      totalCountOfPage
    }
  }
}

export function useUsers(
  page: number,
  functionLoading: Promise<ResponseGetUsers>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['users', page, search, isActive], () => functionLoading)
}
