/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useMediaQuery
} from '@chakra-ui/react'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { AiOutlineOrderedList } from 'react-icons/ai'
import { FaFilter } from 'react-icons/fa'
import { FcClearFilters } from 'react-icons/fc'
import { FiHelpCircle, FiRefreshCw } from 'react-icons/fi'
import { RiAddLine, RiSearchLine } from 'react-icons/ri'
import { SiMicrosoftexcel } from 'react-icons/si'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { UseQueryResult } from 'react-query'
import { Input } from '../components/Form/Input'
import { Switch } from '../components/Form/Switch'
import { Header } from '../components/Header'
import { PermissionComponent, SpiOptions } from '../components/Permissions'
import { Sidebar } from '../components/Sidebar'
import { ModalActivesFiltersNames } from '../contexts/ModalContext'
import { useDnd } from '../hooks/useDnd'
import { useModal } from '../hooks/useModal'
import { ModalConfirmExclude } from '../components/Modal/confirmExclude'
import Footer from '../components/Footer'

interface ListProps {
  title: string
  children: ReactNode
  isHaveFilter?: boolean
  nameFilter?: ModalActivesFiltersNames
  isHaveOrdering?: boolean
  urlNew: string
  capturaChange?: (e: any) => void
  defaultSearch?: string
  captureActiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  refetch: (options?: {
    throwOnError: boolean
    cancelRefetch: boolean
  }) => Promise<UseQueryResult>
  placeholder?: string
  isLoading?: boolean
  isFetching?: boolean
  spiCode?: SpiOptions
  ignorePermissions?: boolean
  generateExcel?: () => void
  isHaveData?: boolean
  isSearched?: boolean
  isViewActive?: boolean
}

export function LayoutList({
  title,
  urlNew,
  children,
  capturaChange,
  captureActiveChange,
  defaultSearch,
  isHaveOrdering,
  placeholder = '',
  isHaveFilter = false,
  refetch,
  isFetching,
  spiCode,
  ignorePermissions = false,
  generateExcel,
  isHaveData,
  nameFilter,
  isSearched = true,
  isViewActive = true
}: ListProps) {
  const { t } = useTranslation('global')
  const { verifyModalFilterActive, handlerOpenModal, searchState } = useModal()
  const [mediaQuery1024] = useMediaQuery('(min-width: 1200px)')
  const initIsOrdering = false
  const { setIsOrdering, isOrdering, setList } = useDnd()
  const styleActiveFilter = {
    background: 'transparent'
  }
  const [isOpen, setIsOpen] = useState(false)
  // style order active
  const selectedButtonOrder = {
    border: '1px solid #031f35',
    background: '#fff',
    color: '#000'
  }
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  const handleInputChange = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      capturaChange!(e)
    },
    [capturaChange]
  )

  useEffect(() => {
    setIsOrdering(initIsOrdering)
    setList([])
  }, [])

  // active and desative ordering
  const handlerClickButtonOrdering = (isOrderChange: boolean) => {
    if (isOrderChange === true) {
      setIsOrdering(false)
    } else {
      setIsOrdering(true)
    }
  }

  return (
    <Box position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="2"
          align="center"
          justify="space-around"
        >
          {isWideVersion ? (
            <Stack as="form" direction="row">
              <Box w="400px">
                {isSearched && (
                  <Input
                    placeholder={placeholder}
                    borderRadius={8}
                    name="searchList"
                    size="md"
                    defaultValue={defaultSearch}
                    iconRight={RiSearchLine}
                    onKeyPress={(event) => {
                      if (event.code === 'Enter') {
                        event.preventDefault()
                        event.stopPropagation()
                      }
                    }}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      handleInputChange(event)
                    }}
                  />
                )}
              </Box>
              <Flex align="center" justify="center">
                {isViewActive && (
                  <Tooltip
                    label="Ativo"
                    aria-label="Ativo"
                    hasArrow
                    placement="top-start"
                  >
                    <Box mx="2">
                      <Switch
                        defaultChecked
                        onChange={(event: any) => {
                          captureActiveChange!(event)
                        }}
                        name="active"
                        size="sm"
                      />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
              {isHaveFilter && (
                <>
                  <Button
                    mr="2"
                    top="1"
                    size="sm"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="0"
                    bg="blue.300"
                    color="#fff"
                    onClick={() => {
                      handlerOpenModal(nameFilter)
                    }}
                    style={
                      verifyModalFilterActive(nameFilter)
                        ? styleActiveFilter
                        : undefined
                    }
                  >
                    {verifyModalFilterActive(nameFilter) ? (
                      <Icon as={FcClearFilters} fontSize="16" color="white" />
                    ) : (
                      <Icon as={FaFilter} fontSize="16" color="white" />
                    )}
                  </Button>
                </>
              )}
            </Stack>
          ) : null}
          <Flex alignItems="center" justifyContent="center" flexDirection="row">
            <Text
              alignItems="center"
              justifyContent="center"
              fontSize={!mediaQuery1024 ? '16px' : '24px'}
              fontWeight="600"
              mx="2"
            >
              {title}
            </Text>
            <Icon as={FiHelpCircle} />
          </Flex>
          <Box px="2" position="relative">
            {isFetching && (
              <Box
                position="absolute"
                d="flex"
                alignItems="center"
                justifyContent="center"
                left="-12"
                top="1"
              >
                <Spinner mx="4" color="gray.200" />
              </Box>
            )}
            {isHaveData && (
              <Button
                size={!mediaQuery1024 ? 'xs' : 'sm'}
                mr="2"
                alignItems="center"
                justifyContent="center"
                fontSize="0"
                bg="blue.300"
                color="#fff"
                onClick={() => setIsOpen(true)}
              >
                <Icon as={SiMicrosoftexcel} fontSize="18" color="white" />
              </Button>
            )}
            <Button
              mr="2"
              size={!mediaQuery1024 ? 'xs' : 'sm'}
              alignItems="center"
              justifyContent="center"
              fontSize="0"
              bg="blue.300"
              color="#fff"
              onClick={() => refetch()}
            >
              <Icon as={FiRefreshCw} fontSize="18" color="white" />
            </Button>

            {isHaveOrdering && (
              <PermissionComponent
                spiCode={spiCode}
                ipCode="EDIT"
                ignore={ignorePermissions}
              >
                <Button
                  mr="2"
                  size={!mediaQuery1024 ? 'xs' : 'sm'}
                  alignItems="center"
                  justifyContent="center"
                  fontSize="0"
                  bg="blue.300"
                  color="#fff"
                  style={isOrdering ? selectedButtonOrder : undefined}
                  onClick={() => handlerClickButtonOrdering(isOrdering)}
                >
                  <Icon
                    as={AiOutlineOrderedList}
                    fontSize="20"
                    color={isOrdering ? 'black' : 'white'}
                  />
                </Button>
              </PermissionComponent>
            )}

            <PermissionComponent
              spiCode={spiCode}
              ipCode="CREATE"
              ignore={ignorePermissions}
            >
              <Link to={urlNew} style={{ marginLeft: 'auto' }}>
                <Button
                  size={!mediaQuery1024 ? 'xs' : 'sm'}
                  fontSize={!mediaQuery1024 ? 'xs' : 'sm'}
                  bg="blue.300"
                  color="#fff"
                  leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                >
                  {t('btnNovo')}
                </Button>
              </Link>
            </PermissionComponent>
          </Box>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        direction="column"
        pr="6"
        pb="6"
        flex="1"
        mx="auto"
        ml={isWideVersion ? '75px' : '2'}
        mr="4"
        mt="10px"
        mb="8px"
        borderRadius={8}
        bg="white"
        p="2"
        px="4"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.16)"
        h="cal(100vh - 60px)"
      >
        {children}
      </Flex>
      {/* <Flex>
        <Footer />
      </Flex> */}
      <ModalConfirmExclude
        title={t('modalData')}
        isOpen={isOpen}
        onConfirmExclude={async () => {
          setIsOpen(false)
          generateExcel?.()
        }}
        onClose={() => setIsOpen(false)}
      >
        <Flex>
          <Text>{t('modalDataDescription')}</Text>
        </Flex>
      </ModalConfirmExclude>
    </Box>
  )
}
