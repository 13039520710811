/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-children-prop */
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useState,
  ElementType,
  useEffect
} from 'react'
import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Icon,
  Stack,
  Box,
  Text,
  Flex
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

import { mask } from '../../utils/mask'
import { FlagCountries } from '../atoms/FlagCountries'

import Countries from '../../utils/json/countriesTwo.json'

interface InputProps extends ChakraInputProps {
  name: string
  label?: string
  error?: FieldError
  type?: string
  isRequired?: boolean
  iconRight?: ElementType
  _active?: any
  disabled?: boolean
  size?: string
  getValues?: (value: any) => void
  defaultValues?: any
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    error = null,
    type = 'text',
    isRequired = false,
    iconRight,
    disabled,
    size = 'md',
    getValues,
    defaultValues,
    ...rest
  },
  ref
) => {
  const [typeInput, setTypeInput] = useState(type)
  const [value, setValue] = useState('')
  const [masker, setMasker] = useState('')
  const [DDI, setDDI] = useState('')
  const [DDIDefaultFlag, setDDIDefaultFlag] = useState('')
  const [selectedValue, setSelectedValue] = useState<any>(null)

  // pega o valor do numero e o ddi
  const getValuesAux = useCallback(() => {}, [DDI, value])

  // aplicar mascaras
  const changeValueApplyMask = useCallback(
    (e: any) => {
      const valueModifers = e.target.value

      if (masker === 'foneBr') {
        setValue(mask(valueModifers, '(99) 99999-9999'))
      } else {
        setValue(mask(valueModifers, '9999999999999999999'))
      }

      getValuesAux()
    },
    [masker, getValuesAux]
  )

  // mascara phone br
  function maskerPhone(mas: string) {
    if (mas === 'Brasil') {
      setMasker('foneBr')
    } else {
      setMasker('')
    }
  }

  useEffect(() => {
    setMasker('foneBr')
    setDDI('+55')
  }, [])

  // capturando valor da flag
  const handleSeletecFlag = useCallback((e?: any) => {
    setValue('')
    setSelectedValue(e)

    Countries.map((country: any) => {
      if (country.name === e.name) {
        setDDI(country.ddi)
        const valueAux = {
          ddi_country: country.ddi
        }

        getValues?.(valueAux)

        maskerPhone(e.name)
      }
    })
  }, [])

  useEffect(() => {
    if (defaultValues?.ddi !== undefined) {
      setDDI(defaultValues?.ddi)

      Countries.map((country) => {
        if (country.ddi === defaultValues?.ddi) {
          setDDIDefaultFlag(country.name)
          setSelectedValue(country)
        }
      })
    }
  }, [defaultValues?.ddi])

  return (
    <Stack direction="row" w="100%" spacing="4" placeholder="">
      <Box w="30%" maxWidth="100px">
        <FlagCountries
          medium
          name="flag"
          title={`País ${!!selectedValue && `(${selectedValue.name})`}`}
          selectedCountry={handleSeletecFlag}
          defaultDDI={DDIDefaultFlag}
          handleModifiers={() => getValuesAux()}
        />
      </Box>
      <Box w="75%" flex="1">
        <FormControl
          isInvalid={!!error}
          isRequired={isRequired}
          bgColor="white"
        >
          {!!label && (
            <FormLabel fontSize="14px" htmlFor={name}>
              {label}
            </FormLabel>
          )}
          <InputGroup
            display="flex"
            onChange={(e: any) => changeValueApplyMask(e)}
          >
            <Flex w="100%" align="center" mx="4">
              {!!DDI && (
                <Text w="70px" fontWeight="bold" color="blue.500" mr="2">
                  {DDI}
                </Text>
              )}
              <ChakraInput
                w="100%"
                borderRadius="none"
                name={name}
                id={name}
                focusBorderColor="blue.400"
                bgColor="white"
                borderColor="gray.100"
                borderWidth="1px"
                variant="filled"
                disabled={disabled}
                type={typeInput}
                _hover={{
                  bgColor: 'blue.50'
                }}
                size={size}
                ref={ref}
                value={value}
                onChange={(e) => changeValueApplyMask(e.target.value)}
                {...rest}
              />
              {!!iconRight && (
                <InputRightElement
                  mt={size === 'md' ? '0px' : '4px'}
                  children={
                    <Icon
                      as={iconRight}
                      fontSize={size === 'md' ? '20px' : '24px'}
                      color="#9699B0"
                    />
                  }
                />
              )}
            </Flex>
          </InputGroup>
          {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
      </Box>
    </Stack>
  )
}

export const InputPhone2 = forwardRef(InputBase)
