/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import ls from 'localstorage-slim'
import { BiMoveVertical } from 'react-icons/bi'
import { FiEdit } from 'react-icons/fi'

import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { Pagination } from '../../components/PaginationNew'
import { PermissionComponent } from '../../components/Permissions'
import { useAuth } from '../../hooks/auth'
import {
  getProductsItems,
  ProductsItemsData,
  useProductsItems
} from '../../hooks/ItemsProducts/useItemsProducts'
import { useModal } from '../../hooks/useModal'
import { LayoutList } from '../../layouts/List'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { handlerExportXlsx } from '../../utils/fns/exportXlsx'
// debounce
let timer: any = null

type Group = {
  id: number
  name: string
}

type Language = 'pt' | 'en' | 'es'

export function ProductsItems() {
  const {
    setSearchStateProducts,
    searchStateProducts,
    isOpenProducts,
    handlerCloseModal,
    handlerApplyFiltersProducts,
    handlerRemoveFiltersProducts,
    groupsIds,
    typesProducts
  } = useModal()
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageProductsItems')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  // state
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const [groups, setGroups] = useState<Group[]>([])
  const [groupsIdsLocal, setGroupsIds] = useState<any>(groupsIds || [])
  const [types, setTypes] = useState<any>(typesProducts || [])

  const statusOptionsDefaultTypes = [
    { value: '1', label: `${t('type.1')}` },
    { value: '2', label: `${t('type.2')}` },
    { value: '3', label: `${t('type.3')}` }
  ]

  const {
    data: getProductsData,
    refetch,
    isLoading,
    isFetching
  } = useQuery(
    [
      'stock',
      currentPage,
      searchStateProducts,
      isActive,
      groupsIds,
      typesProducts
    ],
    () =>
      getProductsItems(
        currentPage,
        searchStateProducts,
        isActive,
        groupsIds,
        typesProducts
      )
  )

  useEffect(() => {
    refetch()
  }, [])

  // maker document planilhe
  const exportOject = getProductsData?.products?.map((product) => {
    const language = ls.get<Language>('@CLINIC:LANGUAGE_DEFAULT')
    switch (language) {
      case 'en':
        return {
          Item: product?.name,
          Type: product?.stock_type?.type,
          Group: product?.stock_groups?.[0]?.name,
          Description: product?.description,
          Unity: product?.stock_unit?.unit_en,
          Min: product?.minimun_quantity,
        }

      case 'es':
        return {
          Item: product?.name,
          Type: product?.stock_type?.type,
          Group: product?.stock_groups?.[0]?.name,
          Description: product?.description,
          Unity: product?.stock_unit?.unit_en,
          Min: product?.minimun_quantity,
        }
      default:
        return {
          Item: product?.name,
          Tipo: product?.stock_type?.type,
          Grupo: product?.stock_groups?.[0]?.name,
          Descrição: product?.description,
          Unidade: product?.stock_unit?.unit_ptbr,
          Qtd_mínima: product?.minimun_quantity,
        }
    }
  })

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchStateProducts(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  const returnColorBgLineInTable = (minimum: number, balance: number) => {
    if (balance <= minimum) {
      return 'red.500'
    }

    return 'green.500'
  }

  // close filter modal
  const handlerCloseModalProducts = () => {
    handlerCloseModal('Products')
  }

  useEffect(() => {
    apiAuth
      .get(`clinics/stock-groups/`, {
        params: {
          is_active: true
        }
      })
      .then(({ data }) => {
        if (data?.results) {
          setGroups(data?.results)
        }
      })

    return () => {}
  }, [])

  const handlerGeneratedExcel = () => {
    handlerExportXlsx(exportOject, t('title'))
  }

  return (
    <>
      <LayoutList
        title={t('title')}
        urlNew="/items-products/create"
        refetch={refetch}
        capturaChange={(event: any) => SearchInputCallbackValue(event)}
        captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
        placeholder={t('search')}
        spiCode="PRODUCTS"
        nameFilter="Products"
        isHaveFilter
        isHaveData={
          getProductsData?.products && getProductsData?.products.length > 0
        }
        isFetching={isFetching}
        generateExcel={handlerGeneratedExcel}
        defaultSearch={searchStateProducts}
      >
        <Box>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th>{t('t1')}</Th>
                <Th>{t('t2')}</Th>
                <Th>{t('t3')}</Th>
                <Th>{t('t4')}</Th>
                <Th textAlign="center">{t('t5')}</Th>
                <Th textAlign="right">{t('t6')}</Th>
                <Th textAlign="right">{t('t7')}</Th>
                <Th textAlign="right">{t('t8')}</Th>
                <Th>{t('t9')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton, index) => (
                    <Tr key={`position${index}`}>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <>
                  {getProductsData?.products.map(
                    (product: ProductsItemsData, index: number) => (
                      <Tr key={product.id} maxHeight="40px">
                        <Td w="250px">
                          <Tooltip
                            label={product.name}
                            aria-label={product.name}
                          >
                            <Text
                              _hover={{
                                cursor: 'default'
                              }}
                              fontSize="sm"
                              noOfLines={1}
                            >
                              {product.name}
                            </Text>
                          </Tooltip>
                        </Td>
                        {product?.stock_type.id === 1 && (
                          <Td>
                            <Text fontSize="sm">{t('type.1')}</Text>
                          </Td>
                        )}
                        {product?.stock_type.id === 2 && (
                          <Td>
                            <Text fontSize="sm">{t('type.2')}</Text>
                          </Td>
                        )}
                        {product?.stock_type.id === 3 && (
                          <Td>
                            <Text fontSize="sm">{t('type.3')}</Text>
                          </Td>
                        )}
                        <Td>
                          <Text fontSize="sm">
                            {product?.stock_groups?.[0]?.name || ''}
                          </Text>
                        </Td>
                        <Td w="350px">
                          <Tooltip
                            label={product?.description}
                            aria-label={product?.description}
                          >
                            <Text
                              fontSize="sm"
                              noOfLines={1}
                              _hover={{ cursor: 'default' }}
                            >
                              {product?.description}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td textAlign="center">
                          <Heading fontSize="sm" noOfLines={1}>
                            {product?.stock_unit?.symbol}
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontSize="sm" textAlign="right">
                            {product?.minimun_quantity || '0'}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            textAlign="right"
                            fontSize="sm"
                            color={returnColorBgLineInTable(
                              product?.minimun_quantity,
                              product?.balance_units
                            )}
                          >
                            {product?.balance_units || '0'}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="sm" textAlign="right">
                            {formatBrOrDollarDefault(
                              product?.balance_value?.toFixed(2)
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td>
                          <PermissionComponent spiCode="PRODUCTS" ipCode="EDIT">
                            <Stack direction="row">
                              <Link
                                to={{
                                  pathname: `/items-products/update/${product.id}`,
                                  state: product
                                }}
                              >
                                <Tooltip
                                  label="Editar"
                                  aria-label="editar"
                                  hasArrow
                                  placement="top-start"
                                >
                                  <Box ml="1rem" w="24px">
                                    <FiEdit
                                      color="#9699B0"
                                      size={16}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Box>
                                </Tooltip>
                              </Link>
                              <Link
                                to={{
                                  pathname: `/items-products/stock/${product.id}`,
                                  state: product
                                }}
                              >
                                <Tooltip
                                  label="Movimentações"
                                  aria-label="Movimentações"
                                  hasArrow
                                  placement="top-start"
                                >
                                  <Box ml="1rem" w="24px">
                                    <BiMoveVertical
                                      color="#9699B0"
                                      size={16}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Box>
                                </Tooltip>
                              </Link>
                            </Stack>
                          </PermissionComponent>
                        </Td>
                      </Tr>
                    )
                  )}
                </>
              )}
            </Tbody>
          </Table>
          {getProductsData?.totalCountOfPage === 0 && !isLoading && (
            <NotFound />
          )}
        </Box>
        {!(getProductsData?.totalCountOfPage === 0 && !isLoading) && (
          <Pagination
            totalCountOfRegisters={getProductsData?.totalCountOfPage || 0}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )}
      </LayoutList>
      <ModalArrivedTime
        title="FIltros - Produtos"
        isOpen={isOpenProducts}
        onClose={handlerCloseModalProducts}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Stack w="100%" p="4" dir="column" h="500px">
          <Flex w="100%" justifyContent="flex-start">
            <MultiSelectMenuFilter
              label="Grupos"
              options={groups.map((way) => {
                return {
                  id: String(way.id),
                  name: way?.name
                }
              })}
              backChange={(values) => {
                setGroupsIds(values)
              }}
              render={() => {
                return groupsIdsLocal
              }}
            />

            <MultiSelectMenuFilter
              label="Tipo"
              options={statusOptionsDefaultTypes.map((itemStatus) => {
                return {
                  id: String(itemStatus.value),
                  name: itemStatus.label
                }
              })}
              backChange={(values) => {
                setTypes(values)
              }}
              render={() => {
                return types
              }}
            />
          </Flex>
          <Flex
            position="absolute"
            bottom="2"
            right="2"
            justifyContent="flex-end"
            alignItems="flex-end"
            flexDir="row"
          >
            <Button
              variant="outline"
              mx="2"
              onClick={() => handlerRemoveFiltersProducts()}
            >
              Limpar filtro
            </Button>
            <Button
              bg="blue.300"
              color="white"
              onClick={() => {
                const filters = {
                  groupsIds: groupsIdsLocal,
                  types
                }

                handlerApplyFiltersProducts(filters)
              }}
            >
              Aplicar filtro
            </Button>
          </Flex>
        </Stack>
      </ModalArrivedTime>
    </>
  )
}
