/* eslint-disable camelcase */
/* eslint-disable import/no-duplicates */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Link as LinkChakra,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import format from 'date-fns/format'
import ls from 'localstorage-slim'
import { useSubscription } from 'mqtt-react-hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillLock, AiFillUnlock, AiOutlineWhatsApp } from 'react-icons/ai'
import { FcInfo } from 'react-icons/fc'
import { FiDollarSign, FiEdit } from 'react-icons/fi'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { Pagination } from '../../components/PaginationNew'
import { ViewDayTimeline } from '../../components/ReactScheduling'
import {
  getScheduling,
  Scheduling as SchedulingType
} from '../../hooks/scheduling/useScheduling'
import { LayoutList } from '../../layouts/List'

import { Select } from '../../components/Form/Select'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { useModal } from '../../hooks/useModal'
import { queryClient } from '../../services/reactQuery'
import { handlerExportXlsx } from '../../utils/fns/exportXlsx'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { useService } from './service'
import { useAuth } from '../../hooks/auth'

// debounce
let timer: any = null

// language
type Language = 'pt' | 'en' | 'es'

type ActionsWs =
  | 'cancel-attendance'
  | 'finish-attendance'
  | 'allow-start-attendance'
  | 'logout-user'
  | undefined

// debounce update call
let timerUpdate: any = null

interface ServiceData {
  id: number
  service_id: number
  service_name: string
}

interface Mutation {
  payload: object
  id: string
}

export function Scheduling() {
  const { decodeToken, isSpecialist } = useAuth()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  // context work filters
  const {
    setStatusParam,
    setSpecialistIds,
    specialistIds,
    statusParam,
    dateScheduling,
    setDateScheduling,
    LocaleApp,
    setTabIndexClient,
    searchDefaultScheduling,
    setSearchDefaultScheduling,
    isLoginSpecialistIsDefault,
    setIsLoginSpecialistIsDefault
  } = useModal()
  const services = useService()
  const toast = useToast()
  const [t] = useTranslation('pageScheduling')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  // mutation
  const updateAttendance = useMutation(
    async ({ payload, id }: Mutation) => {
      await services.handlerUpdateAttendance(payload, id)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('scheduling')
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries('scheduling')
      },
      onError: () => {
        // Invalidate Cache
        queryClient.invalidateQueries('scheduling')

        // Message error
        toast({
          title: 'Cadastro não realizado',
          description: 'Ouve um erro de comunicação com o servidor',
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }
    }
  )
  // state control bar
  // specialist state control filter
  const [startAt, setStartAt] = useState(`${dateScheduling}T00:00`)
  const [endAt, setEndAt] = useState(`${dateScheduling}T23:59`)
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  // control state front-end model
  const [frontendModel, setFrontendModel] = useState(0)
  // style button selected
  const selectedButton = {
    border: '1px solid #031f35',
    background: '#fff',
    color: '#000'
  }
  const statusOptionsLimited = [
    { value: 1, label: `${t('status.1')}` },
    { value: 2, label: `${t('status.2')}` },
    { value: 3, label: `${t('status.3')}` }
  ]
  const statusOptionsDefault = [
    { value: 1, label: `${t('status.1')}` },
    { value: 2, label: `${t('status.2')}` },
    { value: 3, label: `${t('status.3')}` },
    { value: 4, label: `${t('status.4')}` },
    { value: 5, label: `${t('status.5')}` }
  ]
  const statusOptionsDocument = [
    { value: 1, label: `${t('status.1')}` },
    { value: 2, label: `${t('status.2')}` },
    { value: 3, label: `${t('status.3')}` },
    { value: 4, label: `${t('status.4')}` },
    { value: 5, label: `${t('status.5')}` },
    { value: 6, label: `${t('status.6')}` },
    { value: 7, label: `${t('status.7')}` }
  ]
  // status change state value for back end
  const [status, setStatus] = useState<number | null>(null)
  const [isChangeStatus, setIsChangeStatus] = useState(false)
  const [indexScheduling, setIndexScheduling] = useState<number | null>(null)
  const [arrivedTime, setArrivedTime] = useState('')
  const exactHours = new Date().getHours()
  const exactMinutes = new Date().getMinutes()
  // modal input hours arrivedTime
  const [isOpen, setIsOpen] = useState(false)
  // modal message whatsApp
  const [message, setMessage] = useState('')
  const [numberWhatsApp, setNumberWhatsApp] = useState('')
  const [isOpenWhatsApp, setIsOpenWhatsApp] = useState(false)
  const [statusLocal, setStatusLocal] = useState<any>(statusParam)
  const [specialistLocal, setSpecialistLocal] = useState(specialistIds)
  const [isButtonFilters, setIsButtonFilters] = useState(false)

  useEffect(() => {
    if (isLoginSpecialistIsDefault === false) {
      if (isSpecialist === true) {
        setSpecialistLocal([String(decodeToken?.user_id)])
        setSpecialistIds([String(decodeToken?.user_id)])
      } else {
        setSpecialistIds(specialistIds)
      }

      setIsLoginSpecialistIsDefault(true)
    }

    return () => {}
  }, [isSpecialist])

  const {
    data: getSchedulingData,
    refetch,
    isLoading,
    isFetching
  } = useQuery(
    [
      'scheduling',
      currentPage,
      searchDefaultScheduling,
      startAt,
      endAt,
      specialistIds,
      statusParam,
      isActive,
      frontendModel
    ],
    () =>
      getScheduling(
        currentPage,
        searchDefaultScheduling,
        startAt,
        endAt,
        specialistIds,
        statusParam,
        isActive,
        frontendModel
      )
  )

  useEffect(() => {
    const verifyFilterApply = () => {
      let isDifferent = false

      console.log(specialistIds, specialistLocal)

      if (specialistIds !== specialistLocal) {
        isDifferent = true
      }

      if (statusLocal !== statusParam) {
        isDifferent = true
      }

      return isDifferent
    }

    setIsButtonFilters(verifyFilterApply)
  }, [specialistLocal, statusLocal])

  // apply filters
  const handlerApplyFilters = () => {
    setSpecialistIds(specialistLocal)
    setStatusParam(statusLocal)
  }

  // update date Scheduling
  const updateDataScheduling = async () => {
    refetch()
    setIsChangeStatus(false)
    setStatus(null)
    setIndexScheduling(null)
    setArrivedTime('')
  }

  // return exact hours
  const formatAndReturnExactHours = () => {
    const [hoursLeft, hoursRight] = String(exactHours)
      .padStart(2, '0')
      .split('')
    const [minutesLeft, minutesRight] = String(exactMinutes)
      .padStart(2, '0')
      .split('')

    return `${hoursLeft}${hoursRight}:${minutesLeft}${minutesRight}`
  }

  // handler Change status for scheduling by index
  const handlerUpdateStatusScheduling = async (
    value: number | null,
    index: number | null
  ) => {
    if (getSchedulingData === undefined) {
      return
    }

    if (value === 5) {
      const exactTime = formatAndReturnExactHours()
      setArrivedTime(exactTime)
      setIsOpen(true)
      return
    }

    if (index === null) {
      return
    }

    const id = String(getSchedulingData.scheduling[index].id)

    if (id === undefined) {
      return
    }

    if (value !== 5) {
      const payload = {
        attendance_spot: getSchedulingData.scheduling[index].attendance_spot,
        block_guide: getSchedulingData.scheduling[index].block_guide,
        guide: getSchedulingData.scheduling[index].guide,
        id,
        invoice_date: getSchedulingData.scheduling[index].invoice_date,
        invoice_number: getSchedulingData.scheduling[index].invoice_number,
        is_active: getSchedulingData.scheduling[index].is_active,
        observation: getSchedulingData.scheduling[index].observation,
        observation_payment:
          getSchedulingData.scheduling[index].observation_payment,
        patient_id: getSchedulingData.scheduling[index].patient_id,
        real_arrival_time: null,
        real_final_time: getSchedulingData.scheduling[index].real_final_time,
        real_initial_time:
          getSchedulingData.scheduling[index].real_initial_time,
        scheduled_arrival_time:
          getSchedulingData.scheduling[index].scheduled_arrival_time,
        scheduled_final_time:
          getSchedulingData.scheduling[index].scheduled_final_time,
        scheduled_initial_time:
          getSchedulingData.scheduling[index].scheduled_initial_time,
        professional_id: getSchedulingData.scheduling[index].professional_id,
        status: value,
        attendance_items: getSchedulingData.scheduling[index].attendance_items,
        value_to_income: getSchedulingData.scheduling[index].value_to_income,
        indications: getSchedulingData.scheduling[index].indications || [],
        sessions: getSchedulingData.scheduling[index].sessions || [],
        sessions_not_performed:
          getSchedulingData.scheduling[index].sessions_not_performed || [],
        equipments: getSchedulingData?.scheduling[index]?.equipments || []
      }

      await updateAttendance.mutateAsync({ payload, id })
    }

    setIsChangeStatus(true)
    updateDataScheduling()
  }

  const { mutate } = useMutation(
    () => handlerUpdateStatusScheduling(status, indexScheduling),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('scheduling')
      }
    }
  )

  const generateArrayMqttObserver = () => {
    if (getSchedulingData?.scheduling !== undefined) {
      if (getSchedulingData?.scheduling !== null) {
        const allArrayAllow = getSchedulingData?.scheduling?.map(
          (item: any) => {
            return `/attendances/${item.hash}/allow-start-attendance/`
          }
        )
        const allArrayFinish = getSchedulingData?.scheduling.map((item) => {
          return `/attendances/${item.hash}/finish-attendance/`
        })

        const allArrayCancel = getSchedulingData?.scheduling?.map((item) => {
          return `/attendances/${item.hash}/cancel-attendance/`
        })

        const allArray = [
          ...allArrayFinish,
          ...allArrayAllow,
          ...allArrayCancel
        ]

        return allArray
      }
    }

    return []
  }

  // websocket mqtt listen
  const { message: clientMessage } = useSubscription(
    generateArrayMqttObserver()
  )

  // return hours format
  const hoursFormat = (myDate: string) => {
    if (myDate === null) {
      return ''
    }

    const formatDate = myDate?.substring(11, 16) || ''
    return formatDate
  }

  // return string for xls document
  const ArrayToString = (list: Array<ServiceData>) => {
    const ArrayString = list?.map((item) => {
      return item?.service_name
    })

    const stringArrayGenerator = ArrayString?.join(',')
    const resultString = stringArrayGenerator?.replaceAll(',', '\n')

    return resultString
  }

  // maker document planilhe
  const exportOject = getSchedulingData?.scheduling?.map((item) => {
    const language = ls.get<Language>('@CLINIC:LANGUAGE_DEFAULT')
    const servicesStringValue = ArrayToString(item?.attendance_items)

    switch (language) {
      case 'en':
        return {
          Guide: item?.guide,
          Date: format(new Date(item?.scheduled_initial_time), 'dd/MM/yyyy'),
          Scheduling: hoursFormat(item?.scheduled_initial_time),
          Arrived: hoursFormat(item?.real_arrival_time),
          Attendance: hoursFormat(item?.scheduled_final_time),
          Status: statusOptionsDocument[item?.status - 1]?.label,
          Client: item?.patient_name,
          Specialist: item?.specialist_name,
          Services: servicesStringValue
        }

      case 'es':
        return {
          Guide: item?.guide,
          Date: format(new Date(item?.scheduled_initial_time), 'dd/MM/yyyy'),
          Scheduling: hoursFormat(item?.scheduled_initial_time),
          Arrived: hoursFormat(item?.real_arrival_time),
          Attendance: hoursFormat(item?.scheduled_final_time),
          Status: statusOptionsDocument[item?.status - 1]?.label,
          Client: item?.patient_name,
          Specialist: item?.specialist_name,
          Services: servicesStringValue
        }

      default:
        return {
          Guia: item?.guide,
          Data: format(new Date(item?.scheduled_initial_time), 'dd/MM/yyyy'),
          Agendado: hoursFormat(item?.scheduled_initial_time),
          Chegou: hoursFormat(item?.real_arrival_time),
          Atendimento: hoursFormat(item?.scheduled_final_time),
          Status: statusOptionsDocument[item?.status - 1]?.label,
          Cliente: item?.patient_name,
          Especialista: item?.specialist_name,
          Serviços: servicesStringValue
        }
    }
  })

  const handlerGeneratedExcel = () => {
    if (frontendModel === 0) {
      handlerExportXlsx(exportOject, t('title'), dateScheduling)
    } else {
      handlerExportXlsx(exportOject, t('title'))
    }
  }

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchDefaultScheduling(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  // button next day
  const handlerClickButtonNextDay = () => {
    // get date
    const day = Number(dateScheduling.substring(8, 10))
    const month = Number(dateScheduling.substring(5, 7)) - 1
    const year = Number(dateScheduling.substring(0, 4))
    const nowDate = new Date(year, month, day)
    const tomorrow = format(
      nowDate.setDate(nowDate.getDate() + 1),
      'yyyy-MM-dd'
    )
    setDateScheduling(tomorrow)
  }

  // button back day
  const handlerClickButtonBackDay = () => {
    // get date
    const day = Number(dateScheduling.substring(8, 10))
    const month = Number(dateScheduling.substring(5, 7)) - 1
    const year = Number(dateScheduling.substring(0, 4))
    const nowDate = new Date(year, month, day)
    const backDay = format(nowDate.setDate(nowDate.getDate() - 1), 'yyyy-MM-dd')
    setDateScheduling(backDay)
  }
  // validation hours Arrived Time input
  const validationHoursArrivedTime = () => {
    let validationReturn = false
    const hour = arrivedTime

    if (hour.length !== 5) {
      validationReturn = true
    }

    if (hour.length === 5) {
      const hoursRemask = remaskCaractersAll(hour)
      const minutes = hoursRemask.substring(2, 4)

      if (Number(hoursRemask) > 2359) {
        validationReturn = true
      }

      if (Number(minutes) > 59) {
        validationReturn = true
      }
    }

    return validationReturn
  }

  // handler Close modal Arrived time
  const handlerCloseModalArrivedTime = () => {
    setIsOpen(false)
    setArrivedTime('')
  }

  // handler Change status for Status Arrived Time
  const handlerUpdateStatusAndArrivedTime = async (
    value: number | null,
    index: any
  ) => {
    if (getSchedulingData === undefined) {
      return
    }

    const id = String(getSchedulingData.scheduling[index].id)

    if (id === undefined) {
      return
    }

    const dateNow = format(new Date(), 'yyyy-MM-dd')
    const time = `${dateNow}T${arrivedTime}`

    const payload = {
      attendance_spot: getSchedulingData.scheduling[index].attendance_spot,
      block_guide: getSchedulingData.scheduling[index].block_guide,
      guide: getSchedulingData.scheduling[index].guide,
      id,
      invoice_date: getSchedulingData.scheduling[index].invoice_date,
      invoice_number: getSchedulingData.scheduling[index].invoice_number,
      is_active: getSchedulingData.scheduling[index].is_active,
      observation: getSchedulingData.scheduling[index].observation,
      observation_payment:
        getSchedulingData.scheduling[index].observation_payment,
      patient_id: getSchedulingData.scheduling[index].patient_id,
      real_arrival_time: time,
      real_final_time: getSchedulingData.scheduling[index].real_final_time,
      real_initial_time: getSchedulingData.scheduling[index].real_initial_time,
      scheduled_arrival_time:
        getSchedulingData.scheduling[index].scheduled_arrival_time,
      scheduled_final_time:
        getSchedulingData.scheduling[index].scheduled_final_time,
      scheduled_initial_time:
        getSchedulingData.scheduling[index].scheduled_initial_time,
      attendance_items: getSchedulingData.scheduling[index].attendance_items,
      professional_id: getSchedulingData.scheduling[index].professional_id,
      status: value,
      value_to_income: getSchedulingData.scheduling[index].value_to_income,
      indications: getSchedulingData.scheduling[index].indications || [],
      sessions: getSchedulingData.scheduling[index].sessions || [],
      sessions_not_performed:
        getSchedulingData.scheduling[index].sessions_not_performed || [],
      equipments: getSchedulingData?.scheduling[index]?.equipments || []
    }

    if (arrivedTime === '') {
      toast({
        title: 'Aconteceu um error!',
        description: `O horário é obrigatório`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top-right'
      })

      return
    }

    const validateHours = validationHoursArrivedTime()

    if (validateHours === true) {
      toast({
        title: 'Aconteceu um error!',
        description: `O horário informado é invalido`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top-right'
      })

      return
    }

    await updateAttendance.mutateAsync({ payload, id })
    handlerCloseModalArrivedTime()
    await updateDataScheduling()
  }

  // get filters specialist
  const getFiltersSpecialist = (values: String[] | String) => {
    setSpecialistLocal(values)
  }

  // websocket receive message
  useEffect(() => {
    const topic = clientMessage?.topic.split('/')
    const hash = topic?.[2]
    const action = topic?.[1]

    switch (action as ActionsWs) {
      case 'allow-start-attendance':
        queryClient.invalidateQueries('scheduling')
        refetch()
        break
      case 'cancel-attendance':
        queryClient.invalidateQueries('scheduling')
        refetch()

        break
      case 'finish-attendance':
        queryClient.invalidateQueries('scheduling')
        refetch()

        break
      default:
        break
    }
  }, [clientMessage])

  // effect update Status Scheduling
  useEffect(() => {
    if (status === null) {
      return
    }

    if (indexScheduling === null) {
      return
    }

    clearTimeout(timerUpdate)

    timerUpdate = setTimeout(() => {
      mutate()
      if (status !== 5) {
        setIsChangeStatus(true)
      }
    }, 10)
  }, [status, indexScheduling])

  // message whatsApp functions
  const handleCloseModalWhatsApp = () => {
    setIsOpenWhatsApp(false)
    setMessage('')
  }

  const handleOpenDialogWhatsApp = (dataClientScheduling: SchedulingType) => {
    const client = dataClientScheduling.patient_social_name
    const hoursMessage = hoursFormat(
      dataClientScheduling.scheduled_arrival_time
    )
    const date = format(
      new Date(dataClientScheduling.scheduled_arrival_time),
      'dd/MM/yyyy'
    )
    const professional = dataClientScheduling.specialist_name
    const numberPhone = remaskCaractersAll(
      dataClientScheduling.patient_number || ''
    )

    const formatMessage = `Olá ${client}, lembrete de agenda em ${date} às ${hoursMessage} com ${professional}.`

    setMessage(formatMessage)
    setNumberWhatsApp(numberPhone)
    setIsOpenWhatsApp(true)
  }

  // effect update dateParam start and end
  useEffect(() => {
    if (frontendModel === 0) {
      setStartAt(`${dateScheduling}T00:00`)
      setEndAt(`${dateScheduling}T23:59`)
    }

    return () => {}
  }, [frontendModel, dateScheduling])

  useEffect(() => {
    refetch()
  }, [])

  // jxs status item ticket
  const returnJsxElementStatusTicket = (
    statusPayment: number | null | undefined
  ) => {
    if (statusPayment === 1) {
      return (
        <Tooltip
          label="Checkout"
          aria-label="checkout"
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#c71717f4"
              size={16}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 2) {
      return (
        <Tooltip
          label="Checkout"
          aria-label="checkout"
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#56c20ff4"
              size={16}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 3) {
      return (
        <Tooltip
          label="Checkout"
          aria-label="checkout"
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#fd7011f0"
              size={16}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 4) {
      return (
        <Tooltip
          label="Checkout"
          aria-label="checkout"
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#192facdc"
              size={16}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 5) {
      return null
    }

    return (
      <Tooltip
        label="Checkout"
        aria-label="checkout"
        hasArrow
        placement="top-start"
      >
        <Box w="24px">
          <FiDollarSign
            color="#9699B0"
            size={16}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      </Tooltip>
    )
  }

  const returnColorStatusByView = (numberStatus: number) => {
    switch (numberStatus) {
      case 1:
        return '#9699B0'
      case 2:
        return '#5891e6'
      case 3:
        return '#fd7011f0'
      case 4:
        return '#c71717f4'
      case 5:
        return '#192facdc'
      case 6:
        return '#56c20ff4'
      case 7:
        return '#36770a'

      default:
        return '#9699B0'
    }
  }

  return (
    <>
      <LayoutList
        title={t('title')}
        urlNew="/scheduling/create"
        placeholder={t('placeholder.search')}
        isFetching={isFetching}
        refetch={refetch}
        capturaChange={(event: any) => SearchInputCallbackValue(event)}
        captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
        ignorePermissions
        defaultSearch={searchDefaultScheduling}
        generateExcel={handlerGeneratedExcel}
        isHaveData={
          getSchedulingData?.scheduling &&
          getSchedulingData?.scheduling.length > 0
        }
      >
        <>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            mb="2"
            p="4"
            justify="space-around"
          >
            <Flex w="35%" justify="flex-start" align="end">
              {services.isLoadingSpecialists ? (
                <>
                  <Spinner color="blue" />
                </>
              ) : (
                <>
                  <HStack spacing={6}>
                    <MultiSelectMenuFilter
                      label="Especialistas"
                      options={services.specialists.map((specialist) => {
                        return {
                          id: String(specialist?.user?.id),
                          name: specialist?.social_name || specialist.user.name
                        }
                      })}
                      backChange={(values) => {
                        getFiltersSpecialist(values)
                      }}
                      render={() => {
                        const specialistsRender: any = specialistIds
                        return specialistsRender
                      }}
                    />

                    <MultiSelectMenuFilter
                      label="Status"
                      options={statusOptionsDocument?.map((itemStatus) => {
                        return {
                          id: String(itemStatus.value),
                          name: itemStatus.label
                        }
                      })}
                      backChange={(values) => {
                        setStatusLocal(values)
                      }}
                      render={() => {
                        const statusRender: any = statusParam
                        return statusRender
                      }}
                    />

                    {isButtonFilters && (
                      <Button
                        bg="blue.300"
                        color="white"
                        onClick={() => handlerApplyFilters()}
                      >
                        Aplicar
                      </Button>
                    )}
                  </HStack>
                </>
              )}
            </Flex>
            {frontendModel === 0 ? (
              <Flex align="end" px="2" mx="2">
                <Flex p="2px" mx="2" w="180px">
                  <Text
                    fontSize={isWideVersion ? '20px' : '14px'}
                    fontWeight="hairline"
                  >
                    {format(
                      new Date(
                        Number(dateScheduling?.substring(0, 4)),
                        Number(dateScheduling?.substring(5, 7)) - 1,
                        Number(dateScheduling?.substring(8, 10))
                      ),
                      'EEEE',
                      {
                        locale: LocaleApp
                      }
                    )}
                  </Text>
                </Flex>
                <Button
                  bg="transparent"
                  onClick={() => handlerClickButtonBackDay()}
                  size="sm"
                >
                  <ChevronLeftIcon w={5} h={5} />
                </Button>
                <Flex mx="8px" align="baseline" justify="baseline">
                  <TextFieldControlled
                    textAlign="center"
                    type="date"
                    value={dateScheduling}
                    onChange={(event) => setDateScheduling(event.target.value)}
                    name="date"
                  />
                </Flex>
                <Button
                  bg="transparent"
                  onClick={() => handlerClickButtonNextDay()}
                  size="sm"
                >
                  <ChevronRightIcon w={5} h={5} />
                </Button>
              </Flex>
            ) : null}
            <Flex mx="2" justify="space-around">
              <Flex mr="2" align="end" justify="space-around">
                <Button
                  mr="2"
                  bg="blue.300"
                  color="white"
                  onClick={() => setFrontendModel(0)}
                  _hover={{ background: '#215B88' }}
                  style={frontendModel === 0 ? selectedButton : undefined}
                  size="sm"
                >
                  {t('controlBar.btn1')}
                </Button>
                <Button
                  mr="2"
                  bg="blue.300"
                  color="white"
                  onClick={() => setFrontendModel(1)}
                  _hover={{ background: '#215B88' }}
                  style={frontendModel === 1 ? selectedButton : undefined}
                  size="sm"
                >
                  {t('controlBar.btn2')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
          {frontendModel === 0 && (
            <Box>
              <Table variant="striped" colorScheme="blue" size="sm">
                <Thead>
                  <Tr>
                    <Th>{t('t1')}</Th>
                    <Th>{t('t2')}</Th>
                    <Th>{t('t3')}</Th>
                    <Th>{t('t4')}</Th>
                    <Th>{t('t5')}</Th>
                    <Th>{t('t6')}</Th>
                    {/* <Th>{t('t7')}</Th> */}
                    {specialistIds.length === 1 ? null : <Th>{t('t8')}</Th>}
                    <Th>{t('t9')}</Th>
                    <Th>{t('t10')}</Th>
                    <Th>{t('t11')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading ? (
                    <>
                      {skeletonRepeat?.map((skeleton) => (
                        <Tr key={skeleton}>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                        </Tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {getSchedulingData?.scheduling?.map(
                        (item, index: number) => (
                          <Tr key={item.id}>
                            <Td>
                              <Text fontSize="sm">{item.guide}</Text>
                            </Td>
                            <Td>
                              <Text fontSize="sm">
                                {hoursFormat(item?.scheduled_arrival_time)}
                              </Text>
                            </Td>
                            <Td>
                              <Text fontSize="sm">
                                {hoursFormat(item?.real_arrival_time)}
                              </Text>
                            </Td>
                            <Td>
                              <Text fontSize="sm">
                                {hoursFormat(item?.scheduled_initial_time)}
                              </Text>
                            </Td>
                            {item?.edit_status === 3 || item?.status > 5 ? (
                              <>
                                <Td flexDir="row" textAlign="left">
                                  <Flex
                                    flexDirection="row"
                                    gap="2"
                                    textAlign="left"
                                  >
                                    {item.status === 1 && (
                                      <Text
                                        fontSize="small"
                                        fontWeight="extrabold"
                                      >
                                        {t('status.1')}
                                      </Text>
                                    )}
                                    {item.status === 2 && (
                                      <Text
                                        fontWeight="extrabold"
                                        color="blue.300"
                                        fontSize="small"
                                      >
                                        {t('status.2')}
                                      </Text>
                                    )}
                                    {item.status === 3 && (
                                      <Text
                                        fontWeight="extrabold"
                                        color="orange.500"
                                        fontSize="small"
                                      >
                                        {t('status.3')}
                                      </Text>
                                    )}
                                    {item.status === 4 && (
                                      <Text
                                        fontWeight="extrabold"
                                        color="red.500"
                                        fontSize="small"
                                      >
                                        {t('status.4')}
                                      </Text>
                                    )}
                                    {item.status === 5 && (
                                      <Text
                                        fontWeight="extrabold"
                                        color="blue.900"
                                        fontSize="small"
                                      >
                                        {t('status.5')}
                                      </Text>
                                    )}
                                    {item.status === 6 && (
                                      <Text
                                        fontWeight="extrabold"
                                        color="green.300"
                                        fontSize="small"
                                      >
                                        {t('status.6')}
                                      </Text>
                                    )}
                                    {item.status === 7 && (
                                      <Text
                                        fontWeight="extrabold"
                                        color="green.600"
                                        fontSize="small"
                                      >
                                        {t('status.7')}
                                      </Text>
                                    )}
                                    {item?.status_by_system && (
                                      <Tooltip
                                        label={t('bySystem')}
                                        aria-label={t('bySystem')}
                                        hasArrow
                                        placement="auto-end"
                                      >
                                        <Box>
                                          <Flex
                                            rounded="full"
                                            w="14px"
                                            alignItems="center"
                                            justifyContent="center"
                                            h="14px"
                                            bg="blue.300"
                                            color="white"
                                          >
                                            <Text fontSize="8px">S</Text>
                                          </Flex>
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </Flex>
                                </Td>
                              </>
                            ) : (
                              <>
                                <Td flexDir="row">
                                  <Flex
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    flexDirection="row"
                                    gap="2px"
                                  >
                                    <Box w="128px">
                                      <Select
                                        size="xs"
                                        my="0"
                                        name="status"
                                        color={returnColorStatusByView(
                                          item?.status
                                        )}
                                        fontWeight="bold"
                                        options={
                                          item?.edit_status === 1
                                            ? statusOptionsLimited
                                            : statusOptionsDefault
                                        }
                                        value={item.status}
                                        onChange={(event) => {
                                          setStatus(Number(event.target.value))
                                          setIndexScheduling(index)
                                        }}
                                      />
                                    </Box>
                                    {item?.status_by_system && (
                                      <Tooltip
                                        label={t('bySystem')}
                                        aria-label={t('bySystem')}
                                        hasArrow
                                        placement="auto-end"
                                      >
                                        <Box>
                                          <Flex
                                            rounded="full"
                                            w="14px"
                                            alignItems="center"
                                            justifyContent="center"
                                            h="14px"
                                            bg="blue.300"
                                            color="white"
                                          >
                                            <Text fontSize="8px">S</Text>
                                          </Flex>
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </Flex>
                                </Td>
                              </>
                            )}
                            <Td w="280px">
                              <Tooltip
                                label={
                                  item?.patient_social_name ||
                                  item?.patient_name ||
                                  ''
                                }
                                aria-label={
                                  item?.patient_social_name ||
                                  item?.patient_name ||
                                  ''
                                }
                                hasArrow
                                placement="top-start"
                              >
                                <Link
                                  to={{
                                    pathname: `/clients/update/${item.patient_id}`
                                  }}
                                  onClick={() => {
                                    setTabIndexClient(1)
                                  }}
                                >
                                  <Text
                                    noOfLines={1}
                                    fontSize="xs"
                                    _hover={{
                                      textDecoration: 'underline'
                                    }}
                                  >
                                    {item?.patient_name ||
                                      item?.patient_social_name}
                                  </Text>
                                </Link>
                              </Tooltip>
                            </Td>
                            {specialistIds.length === 1 ? null : (
                              <Td>
                                <Text fontSize="smaller">
                                  {item.specialist_name}
                                </Text>
                              </Td>
                            )}
                            <Td w="230px">
                              {item?.attendance_items !== undefined &&
                                item?.attendance_items?.map((service) => (
                                  <Tooltip
                                    key={service.id}
                                    label={
                                      `${service?.service_name} - 1/${service?.total_session}` ||
                                      ''
                                    }
                                    aria-label={
                                      `${service?.service_name} - 1/${service?.total_session}` ||
                                      ''
                                    }
                                    hasArrow
                                    placement="top-start"
                                  >
                                    <Text
                                      noOfLines={1}
                                      key={service.id}
                                      fontSize="12px"
                                      fontWeight="semibold"
                                      _hover={{
                                        cursor: 'default'
                                      }}
                                    >
                                      {service?.service_name}
                                    </Text>
                                  </Tooltip>
                                ))}
                              {item?.sessions !== undefined &&
                                item?.sessions?.map((sec) => (
                                  <Tooltip
                                    key={sec.id}
                                    label={`${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`}
                                    aria-label={`${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`}
                                    hasArrow
                                    placement="top-start"
                                  >
                                    <Text
                                      noOfLines={1}
                                      fontSize="12px"
                                      fontWeight="semibold"
                                      _hover={{
                                        cursor: 'default'
                                      }}
                                    >
                                      {`${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`}
                                    </Text>
                                  </Tooltip>
                                ))}
                            </Td>

                            <Td>
                              <Stack direction="row">
                                {item?.observation ? (
                                  <Tooltip
                                    label={item?.observation || ''}
                                    aria-label={item?.observation || ''}
                                    hasArrow
                                    placement="auto-end"
                                  >
                                    <Box>
                                      <FcInfo size={20} />
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <Box w="20px" />
                                )}
                                {item.block_guide ? (
                                  <Tooltip
                                    label={t('block')}
                                    aria-label={t('block')}
                                    hasArrow
                                    placement="top-start"
                                  >
                                    <Box>
                                      <AiFillLock size={20} color="red" />
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    label={t('noBlock')}
                                    aria-label={t('noBlock')}
                                    hasArrow
                                    placement="top-start"
                                  >
                                    <Box>
                                      <AiFillUnlock size={20} color="green" />
                                    </Box>
                                  </Tooltip>
                                )}
                              </Stack>
                            </Td>

                            <Td>
                              <Flex gap={2} flexDir="row">
                                <Link
                                  to={{
                                    pathname: `/scheduling/update/${item.id}`,
                                    state: item
                                  }}
                                >
                                  <Tooltip
                                    label="Editar"
                                    aria-label="editar"
                                    hasArrow
                                    placement="top-start"
                                  >
                                    <Box w="24px">
                                      <FiEdit
                                        color="#9699B0"
                                        size={16}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </Box>
                                  </Tooltip>
                                </Link>
                                {!item?.block_checkout && (
                                  <Link
                                    to={{
                                      pathname: `/financial/checkout/${item.id}`,
                                      state: item
                                    }}
                                  >
                                    {returnJsxElementStatusTicket(
                                      item?.ticket_status_payment
                                    )}
                                  </Link>
                                )}
                                {item?.patient_number && (
                                  <Icon
                                    as={AiOutlineWhatsApp}
                                    _hover={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      handleOpenDialogWhatsApp(item)
                                    }}
                                  />
                                )}
                              </Flex>
                            </Td>
                          </Tr>
                        )
                      )}
                    </>
                  )}
                </Tbody>
              </Table>
              {getSchedulingData?.totalCountOfPage === 0 && !isLoading && (
                <NotFound />
              )}

              {!(getSchedulingData?.totalCountOfPage === 0 && !isLoading) && (
                <Pagination
                  totalCountOfRegisters={
                    getSchedulingData?.totalCountOfPage || 0
                  }
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              )}
            </Box>
          )}
          {frontendModel === 1 && (
            <Box>
              <ViewDayTimeline
                data={getSchedulingData?.scheduling}
                block={getSchedulingData?.blocks}
                initialDate={dateScheduling}
                setEndAt={setEndAt}
                setStartAt={setStartAt}
                specialistId={
                  specialistIds.length === 1 ? specialistIds[0] : undefined
                }
              />
            </Box>
          )}
          <ModalArrivedTime
            isOpen={isOpen}
            onClose={handlerCloseModalArrivedTime}
            title={t('modalArrivedTime.title')}
            onUpdate={async () => {
              try {
                await handlerUpdateStatusAndArrivedTime(status, indexScheduling)
                queryClient.invalidateQueries('scheduling')
                handlerCloseModalArrivedTime()
              } catch (error: any) {
                setIsChangeStatus(false)
              }
              setIsChangeStatus(true)
            }}
          >
            <Flex w="100%" align="center" justify="center" p="2">
              <TextFieldControlled
                name="ArrivedTime"
                mask="time"
                textAlign="left"
                placeholder="-- : --"
                initialValue={arrivedTime}
                label={t('modalArrivedTime.i1')}
                inputOnChange={(event) =>
                  setArrivedTime(event.currentTarget.value)
                }
              />
            </Flex>
          </ModalArrivedTime>
        </>
        <Flex w="100%" h="300px" />
      </LayoutList>
      <ModalArrivedTime
        notRenderFooter
        title="Mensagem para WhatsApp"
        isOpen={isOpenWhatsApp}
        onUpdate={() => {}}
        onClose={handleCloseModalWhatsApp}
      >
        <Stack
          w="100%"
          direction="column"
          p="2"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Textarea
            placeholder="opcional"
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
          />
          <Box>
            <LinkChakra
              href={`https://api.whatsapp.com/send?phone=${numberWhatsApp}&text=${message}`}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none'
              }}
              onClick={() => handleCloseModalWhatsApp()}
            >
              <Button colorScheme="red">Enviar</Button>
            </LinkChakra>
          </Box>
        </Stack>
      </ModalArrivedTime>
    </>
  )
}
