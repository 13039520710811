/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type ProductsGroupData = {
  name: string
  stock_type: {
    id: number
    type: 'Insumo' | 'Revenda' | 'Consumo'
  }
  description: number
  id: number
}

export type ResponseGetProductsGroup = {
  products: ProductsGroupData[]
  totalCountOfPage: number
}

export const getProductsGroup = async (
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetProductsGroup> => {
  try {
    const { data } = await apiAuth.get(`clinics/stock-groups/`, {
      params: {
        page,
        name__icontains: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const products: ProductsGroupData[] = data.results

    return {
      products,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const products: ProductsGroupData[] = []
    const totalCountOfPage = 0
    return {
      products,
      totalCountOfPage
    }
  }
}

export function useProductsGroup(
  page: number,
  functionLoading: Promise<ResponseGetProductsGroup>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['productsGroup', page, search, isActive],
    () => functionLoading
  )
}
