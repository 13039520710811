/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type Control = {
  id: number
  created_at: string
  patients_social_name: Array<string>
  status_payment: number
  ticket_number: number
  paid_value: number
  result_status: number | null
  generate_invoice: number
  specialists_social_name: Array<string>
  total_value_invoice: number
  invoices: Array<{
    id: number
    value: number
    tax_unit: {
      id: number
      name: string
    }
    identifier: string
    file: string
    event_date: string
  }>
}

export type ResponseGetTaxControl = {
  taxControl: Control[]
  totalCountOfPage: number
}

interface GetReturnApiResponse {
  count: number
  results: Array<Control>
}

export const getTaxControl = async (
  page: number,
  search: string = '',
  startAt: string = '',
  endAt: string = '',
  specialistId: String[] | any = [],
  generateInvoice: String[] | any = [],
  paymentStatus: String[] | any = []
): Promise<ResponseGetTaxControl> => {
  try {
    const { data } = await apiAuth.get<GetReturnApiResponse>(
      `clinics/tickets/invoices/`,
      {
        params: {
          page,
          keyword: search,
          startDate: startAt,
          finishDate: endAt,
          specialistId: specialistId.join(','),
          generate_invoice: generateInvoice.join(','),
          status_payment: paymentStatus.join(',')
        }
      }
    )

    const totalCountOfPage = data?.count || 0
    const taxControl: Control[] = data?.results || []

    return {
      taxControl,
      totalCountOfPage
    }
  } catch (error: any) {
    const taxControl: Control[] = []
    const totalCountOfPage = 0
    return {
      taxControl,
      totalCountOfPage
    }
  }
}

export function useTaxControl(
  page: number,
  functionLoading: Promise<ResponseGetTaxControl>,
  search: string,
  startAt: string = '',
  endAt: string = '',
  specialistId: String[] | String = [],
  generateInvoice: String[] | String = [],
  statusPayment: String[] | String = [],
  options?: {}
) {
  return useQuery(
    [
      'taxControl',
      page,
      search,
      startAt,
      endAt,
      specialistId,
      generateInvoice,
      statusPayment
    ],
    () => functionLoading
  )
}
