/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type UnitTax = {
  name: string
  description: string
  id: number
  document_type: number
  document_number: string
  is_active: boolean
}

export type ResponseGetCost = {
  units: UnitTax[]
  totalCountOfPage: number
}

export const getUnitTax = async (
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetCost> => {
  try {
    const { data } = await apiAuth.get(`clinics/tax-units/`, {
      params: {
        page,
        keyword: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const units: UnitTax[] = data.results

    return {
      units,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const units: UnitTax[] = []
    const totalCountOfPage = 0
    return {
      units,
      totalCountOfPage
    }
  }
}

export function useUnitTax(
  page: number,
  functionLoading: Promise<ResponseGetCost>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['unitTax', page, search, isActive], () => functionLoading)
}
