/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Checkbox as ChakraCheckbox
} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from 'react'
import { useQuery } from 'react-query'
import { FiEdit } from 'react-icons/fi'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getExams, useExams } from '../../hooks/exams/useExams'
import { useAuth } from '../../hooks/auth'
import { LayoutList } from '../../layouts/List'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { PermissionComponent } from '../../components/Permissions'

// debounce
let timer: any = null

export function ExamRequest() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageExamRequest')
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]

  const {
    data: getRecipesData,
    isFetching,
    refetch,
    isLoading
  } = useQuery(
    ['exams', String(clinicId), currentPage, searchState, isActive],
    () => getExams(String(clinicId), currentPage, searchState, isActive)
  )

  useEffect(() => {
    refetch()
  }, [])

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  return (
    <LayoutList
      title={t('title')}
      urlNew="/settings/exam-request/create"
      refetch={refetch}
      placeholder={t('search')}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="EXAMS"
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getRecipesData?.exams.map((exam) => (
                  <Tr key={exam.id} maxHeight="40px">
                    <Td>
                      <Text fontSize="sm">{exam.name}</Text>
                    </Td>

                    <Td>
                      <Text fontSize="sm">
                        {exam.status === 1 && `${t('status.1')}`}
                        {exam.status === 2 && `${t('status.2')}`}
                      </Text>
                    </Td>

                    <Td>
                      <PermissionComponent spiCode="EXAMS" ipCode="EDIT">
                        <Link
                          to={{
                            pathname: `/settings/exam-request/update/${exam.id}`,
                            state: exam
                          }}
                        >
                          <Tooltip
                            label="Editar"
                            aria-label="editar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box ml="1rem" w="24px">
                              <FiEdit
                                color="#9699B0"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </Link>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {getRecipesData?.totalCountOfPage === 0 && !isLoading && <NotFound />}
      </Box>
      {!(getRecipesData?.totalCountOfPage === 0 && !isLoading) && (
        <Pagination
          totalCountOfRegisters={getRecipesData?.totalCountOfPage || 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </LayoutList>
  )
}
