/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Text,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { addDays, format, subMonths } from 'date-fns'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { FiEdit } from 'react-icons/fi'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { CardContainer } from '../../components/molecules/CardContainer'

import { SelectComponent } from '../../components/Form/SelectComponent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { useAuth } from '../../hooks/auth'
import { useModal } from '../../hooks/useModal'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { PermissionComponent } from '../../components/Permissions'

type FormData = {
  is_active: boolean
  description: string
  minimumInventory: string
}

type Group = {
  id: number
  name: string
  stock_type: {
    id: number
    type: 'Insumo' | 'Consumo' | 'Revenda'
  }
}

type SelectType = {
  value: number
  label: string
} | null

type Unit = {
  id: number
  index: number
  symbol: string
  unit_en: string
  unit_es: string
  unit_ptbr: string
}

interface PropsParams {
  id: string
}

type Movement = {
  id: number
  type: number
  type_movement: number
  created_by_system: boolean
  attendance: {
    id: number
    guide: number
    patient_name: string
    scheduled_arrival_time: string
    specialist_name: string
    attendances_items: Array<{
      id: number
      service_name: string
    }>
  } | null
  posting: {
    id: number
    name: string
    share_number: number
    total_of_postings: number
    code: number
  } | null
  stock_item: {
    id: number
    name: string
    stock_type: {
      id: number
      type: 'Insumo' | 'Consumo' | 'Revenda'
      description: string | null
      index: number
    }
    stock_groups: Array<Group>
    stock_unit: Unit
    description: string | null
    minimun_quantity: number
    balance_units: number
    balance_value: number
    total_units_in: number
    total_units_out: number
  }
  movement_datetime: string
  units: number
  unit_value: number
  balance_units: number
  balance_value: number
  description: null | string
  created_at: string
  average_cost: number
  event_date: string
  created_by: {
    id: number
    name: string
    social_name: string
  } | null
  updated_by: {
    id: number
    name: string
    social_name: string
  } | null
  updated_at: string | null
}

type Movements = {
  movements: Array<Movement>
  totalCountOfRegisters: number
}

type ResponseMovements = {
  results: Array<Movement>
  count: number
}

export function MovementsItem() {
  const [t] = useTranslation('pageProductsItems')
  const { currency, clinicId } = useAuth()
  const { LocaleApp } = useModal()
  const [language] = useState<string | null>(() => {
    const languageDefault = localStorage.getItem('i18nextLng')

    return languageDefault
  })
  const toast = useToast()
  const history = useHistory()
  const dateDefault = new Date()
  const minDate = subMonths(dateDefault, 3)
  const maxDate = addDays(dateDefault, 1)
  const outputMaxDate = addDays(dateDefault, 0)
  const { state }: any = history.location
  const { id } = useParams<PropsParams>()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  const optionsInputs = [{ value: 1, label: `${t('pageMovements.moves.1')}` }]

  const optionsOutputs = [
    { value: 3, label: `${t('pageMovements.moves.3')}` },
    { value: 4, label: `${t('pageMovements.moves.4')}` },
    { value: 5, label: `${t('pageMovements.moves.5')}` }
  ]

  // type movement + 1
  const [movements, setMovements] = useState<Movements | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [tabIndex, setTabIndex] = useState(0)
  const [date, setDate] = useState(
    format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp })
  )
  const [quantity, setQuantity] = useState('')
  const [valueUnit, setValueUnit] = useState('')
  const [selectInput, setSelectInput] = useState<SelectType>(optionsInputs[0])
  const [selectOutput, setSelectOutput] = useState<SelectType>(null)
  const [selectedPosting, setSelectedPosting] = useState<SelectType>(null)
  const [selectedAttendance, setSelectedAttendance] = useState<SelectType>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMoves, setIsLoadingMoves] = useState(false)

  // states edit movements
  const [movementId, setMovementId] = useState(0)
  const [saveEventMove, setSaveEventMove] = useState('')
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)
  const [typeEdit, setTypeEdit] = useState(0)
  const [maxLengthEditDateMovement, setMaxLengthEditDateMovement] = useState('')

  useEffect(() => {
    setIsLoadingMoves(true)
    apiAuth
      .get<ResponseMovements>(`clinics/stock-items/${id}/movements/`, {
        params: { page: currentPage, fields: 'id,type,type_movement,stock_item,movement_datetime,units,unit_value,event_date,attendance,posting,created_by_system' }
      })
      .then(({ data }) => {
        if (data?.count > 0) {
          setMovements({
            movements: data?.results,
            totalCountOfRegisters: data?.count
          })
        }
      })
      .finally(() => {
        setIsLoadingMoves(false)
      })

      apiAuth.get(
        `clinics/stock-items-cm/${id}/last-movement-cm/`
      ).then(({ data }) => {
        const lastMovementDate: String = data?.movement_datetime || ''
        if (lastMovementDate?.length > 0) {
          setMaxLengthEditDateMovement(lastMovementDate?.substring(0, 10))
        }
      })
  }, [id, currentPage])

  const updateMovementsList = async () => {
    const { data } = await apiAuth.get<ResponseMovements>(
      `clinics/stock-items/${id}/movements/`, {
        params: { page: currentPage, fields: 'id,type,type_movement,stock_item,movement_datetime,units,unit_value,event_date,attendance,posting,created_by_system' }
      }
    )

    setMovements({
      movements: data?.results,
      totalCountOfRegisters: data?.count
    })
  }

  const getLastMovementCM = async () => {
    const { data: lastMovementLineControl } = await apiAuth.get(
      `clinics/stock-items-cm/${id}/last-movement-cm/`
    )

    const lastMovementDate: String = lastMovementLineControl?.movement_datetime || ''
    if (lastMovementDate?.length > 0) {
      setMaxLengthEditDateMovement(lastMovementDate?.substring(0, 10))
    }
  }

  useEffect(() => {
    if (selectInput?.value === 2) {
      if (movements !== null) {
        const initialValue = 0
        const value = formatBrOrDollarDefault(
          movements?.movements[initialValue].average_cost.toFixed(2)
        )

        setValueUnit(value)
      } else {
        setValueUnit('')
      }
    }

    return () => {}
  }, [selectInput])

  useEffect(() => {
    if (tabIndex === 0) {
      setSelectOutput(null)
      setSelectInput(optionsInputs[0])
      setQuantity('')
      setDate(format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp }))
    }

    if (tabIndex === 1) {
      setSelectInput(optionsInputs[0])
      setQuantity('')
      setValueUnit('')
      setDate(format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp }))
    }

    if (tabIndex === 2) {
      setSelectOutput(null)
      setSelectInput(optionsInputs[0])
      setQuantity('')
      setValueUnit('')
      setDate(format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp }))
    }

    return () => {}
  }, [tabIndex])

  // return hours format
  const hoursFormat = (myDate: string) => {
    if (myDate === null) {
      return ''
    }

    const formatDate = myDate.substring(11, 16)
    return formatDate
  }

  const handleMovementsItem = async () => {
    // validations inputs
    if (tabIndex === 0 && selectInput === null) {
      toast({
        title: 'Selecione o tipo de entrada',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    if (tabIndex === 0 && quantity === '') {
      toast({
        title: 'A quantidade de movimentação é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    if (tabIndex === 0 && valueUnit === '') {
      toast({
        title: 'O preço da unidade menor é obrigatório',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    // validations output
    if (tabIndex === 1 && selectOutput === null) {
      toast({
        title: 'Selecione o tipo de saída',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    if (tabIndex === 1 && quantity === '') {
      toast({
        title: 'A quantidade de movimentação é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    if (tabIndex === 2 && quantity === '') {
      toast({
        title: 'A quantidade de movimentação é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    if (tabIndex === 2 && valueUnit === '') {
      toast({
        title: 'O preço da unidade menor é obrigatório',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    if (tabIndex === 2 && date === '') {
      toast({
        title: 'A data é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoading(false)
      return
    }

    const payload = {
      type: tabIndex + 1,
      type_movement: selectOutput?.value || selectInput?.value || undefined,
      stock_item: id,
      unit_value: Number(remaskCaractersAll(valueUnit)) / 100 || undefined,
      event_date: format(new Date(), "yyyy-MM-dd'T'HH':'mm':'ss", {
        locale: LocaleApp
      }),
      movement_datetime: `${date}${format(new Date(), "'T'HH':'mm':'ss")}`,
      units:
        quantity === ''
          ? movements?.movements?.[0]?.balance_units
          : Number(quantity),
      posting: selectedPosting?.value
        ? { id: selectedPosting?.value }
        : undefined,
      attendance: selectedAttendance?.value
        ? { id: selectedAttendance?.value }
        : undefined
    }

    if (movements?.movements !== undefined && movements?.movements?.length > 0) {
      try {
        const { data } = await apiAuth.post(
          `clinics/stock-items/${id}/movements/`,
          payload
        )

        if (data?.id !== undefined) {
          toast({
            title: 'Movimentação adicionada',
            description: 'Atualizando dados da lista',
            status: 'success',
            position: 'top-right',
            duration: 2000
          })

          await updateMovementsList()

          setSelectInput(optionsInputs[0])
          setSelectOutput(null)
          setQuantity('')
          setValueUnit('')
          setDate(
            format(new Date(), 'yyyy-MM-dd', {
              locale: LocaleApp
            })
          )
          setSelectedPosting(null)
          setSelectedAttendance(null)
        }
      } catch (error: any) {
        toast({
          title: 'Error',
          description: `${error?.response?.data?.detail}`,
          status: 'error',
          position: 'top-right',
          duration: 2000
        })
      }
    } else {
      apiAuth.post(`clinics/stock-items/${id}/movements/`, payload)

      setTimeout(() => {
        toast({
          title: 'Movimentação adicionada',
          description: 'Atualizando dados da lista',
          status: 'success',
          position: 'top-right',
          duration: 2000
        })

        updateMovementsList()

        setSelectInput(optionsInputs[0])
        setSelectOutput(null)
        setQuantity('')
        setValueUnit('')
        setDate(
          format(new Date(), 'yyyy-MM-dd', {
            locale: LocaleApp
          })
        )
        setSelectedPosting(null)
        setSelectedAttendance(null)
      }, 1500)


    }



    setIsLoading(false)
    getLastMovementCM()
  }

  // function get vincule by code output
  const getBillsToPay = async (code: string): Promise<any> => {
    if (code === '') {
      return []
    }

    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/postings/`, {
        params: {
          code
        }
      })

      const postings = data?.results?.map((post: any) => {
        return {
          value: post?.id,
          label: `${post?.code} - ${post?.name} - ${post?.share_number} ${t(
            'of'
          )} ${post?.total_of_postings}`
        }
      })

      return postings
    } catch (error: any) {
      const postings: [] = []
      return postings
    }
  }

  // function get vincule by code output
  const getAttendance = async (search: string): Promise<any> => {
    if (search === '') {
      return []
    }

    if (search?.length < 4) {
      return []
    }

    try {
      const { data } = await apiAuth.get(`clinics/attendances/`, {
        params: {
          keyword: search,
          page: 1,
          noPaginate: 1,
          is_active: true
        }
      })

      const attendance = data?.map((attend: any) => {
        return {
          value: attend?.id,
          label: `${attend?.guide} - ${attend?.patient_name} - ${
            attend?.specialist_name
          } - ${format(new Date(attend?.scheduled_arrival_time), 'dd/MM/yyyy', {
            locale: LocaleApp
          })} ${hoursFormat(attend?.scheduled_arrival_time)}`
        }
      })

      return attendance
    } catch (error: any) {
      const attendance: [] = []
      return attendance
    }
  }

  // edit movement by id
  const handleMovementsItemEdit = async () => {
    setIsLoadingEdit(true)

    // validations inputs
    if (typeEdit === 1 && selectInput === null) {
      toast({
        title: 'Selecione o tipo de entrada',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    if (typeEdit === 1 && quantity === '') {
      toast({
        title: 'A quantidade de movimentação é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    if (typeEdit === 1 && valueUnit === '') {
      toast({
        title: 'O preço da unidade menor é obrigatório',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    // validations output
    if (typeEdit === 2 && selectOutput === null) {
      toast({
        title: 'Selecione o tipo de saída',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    if (typeEdit === 2 && quantity === '') {
      toast({
        title: 'A quantidade de movimentação é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    if (typeEdit === 3 && quantity === '') {
      toast({
        title: 'A quantidade de movimentação é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    if (typeEdit === 3 && valueUnit === '') {
      toast({
        title: 'O preço da unidade menor é obrigatório',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    if (typeEdit === 3 && date === '') {
      toast({
        title: 'A data é obrigatória',
        duration: 2000,
        status: 'error',
        position: 'top-right'
      })

      setIsLoadingEdit(false)
      return
    }

    const payload = {
      type: typeEdit,
      type_movement: selectOutput?.value || selectInput?.value || undefined,
      unit_value: Number(remaskCaractersAll(valueUnit)) / 100 || undefined,
      event_date: saveEventMove,
      movement_datetime: `${date}${saveEventMove?.substring(10, saveEventMove.length)}`,
      stock_item: id,
      units:
        quantity === ''
          ? movements?.movements?.[0]?.balance_units
          : Number(quantity),
      posting: selectedPosting?.value
        ? { id: selectedPosting?.value }
        : undefined,
      attendance: selectedAttendance?.value
        ? { id: selectedAttendance?.value }
        : undefined
    }
    try {
      const { data } = await apiAuth.put(
        `clinics/stock-items/${id}/movements/${movementId}/`,
        payload
      )

      if (data?.id !== undefined) {
        toast({
          title: 'Movimentação editada com sucesso!',
          description: 'Atualizando dados da lista',
          status: 'success',
          position: 'top-right',
          duration: 2000
        })

        setIsOpenEdit(false)

        await updateMovementsList()

        setIsOpenEdit(false)
        setSelectInput(optionsInputs[0])
        setSelectOutput(null)
        setQuantity('')
        setValueUnit('')
        setDate(
          format(new Date(), 'yyyy-MM-dd', {
            locale: LocaleApp
          })
        )
        setSelectedPosting(null)
        setSelectedAttendance(null)
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description: `${error?.response?.data?.detail}`,
        status: 'error',
        position: 'top-right',
        duration: 2000
      })
    }

    setIsLoadingEdit(false)
  }

  return (
    <>
      <LayoutDefault
        title={`${t('pageMovements.title')} (${state?.name})`}
        urlBack="/items-products"
      >
        <Stack w="100%" direction="column" justifyContent="space-around">
          <CardContainer title={t('pageMovements.c1.title')}>
            <Tabs
              onChange={(event) => setTabIndex(event)}
              defaultIndex={tabIndex}
              isFitted
              variant="soft-rounded"
              colorScheme="blue"
              w="100%"
            >
              <TabList>
                <Tab>{t('pageMovements.c1.input')}</Tab>
                <Tab>{t('pageMovements.c1.output')}</Tab>
                <Tab>{t('pageMovements.c1.cost')}</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Stack w="100%" spacing="6">
                    <Stack direction="row">
                      <Input
                        name="date"
                        min={format(minDate, 'yyyy-MM-dd')}
                        max={format(maxDate, 'yyyy-MM-dd')}
                        isDisabled={isOpenEdit}
                        isRequired
                        type="date"
                        label={t('pageMovements.c1.i2')}
                        value={date}
                        onChange={(event) => setDate(event.target.value)}
                      />
                      <TextFieldControlled
                        name="quantity"
                        isRequired
                        isDisabled={isOpenEdit}
                        label={t('pageMovements.c1.i3')}
                        textAlign="left"
                        mask="number"
                        placeholder={t('pageMovements.placeholder.quantity')}
                        initialValue={quantity}
                        inputOnChange={(event) =>
                          setQuantity(event.currentTarget.value)
                        }
                      />
                      <TextFieldControlled
                        name="valueUnit"
                        isRequired
                        isDisabled={isOpenEdit}
                        label={t('pageMovements.c1.i4')}
                        mask={
                          currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                        }
                        initialValue={valueUnit}
                        inputOnChange={(event) =>
                          setValueUnit(event.currentTarget.value)
                        }
                      />
                      <SelectComponentAsync
                        name="billsToPayId"
                        title={t('pageMovements.c1.i1')}
                        isDisabled={isOpenEdit}
                        loadOptions={(code: string) => getBillsToPay(code)}
                        placeholder={t('pageMovements.placeholder.posting')}
                        value={selectedPosting}
                        onChange={(event: any) => {
                          setSelectedPosting(event)
                        }}
                      />
                    </Stack>
                    <Flex w="100%" justifyContent="flex-end">
                      <PermissionComponent ipCode="CREATE" spiCode="PRODUCTS">
                        <Button
                          isLoading={isLoading}
                          isDisabled={isOpenEdit}
                          size="sm"
                          color="white"
                          bg="blue.300"
                          onClick={() => {
                            setIsLoading(true)
                            handleMovementsItem()
                          }}
                        >
                          {t('pageMovements.addMove')}
                        </Button>
                      </PermissionComponent>
                    </Flex>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack w="100%" spacing="6">
                    <Stack direction="row">
                      <SelectComponent
                        name="typeOutput"
                        isDisabled={isOpenEdit}
                        isRequired
                        title={t('pageMovements.c1.i5')}
                        options={optionsOutputs}
                        value={selectOutput}
                        onChange={(event: any) => setSelectOutput(event)}
                        placeholder={t(
                          'pageMovements.placeholder.selectOutput'
                        )}
                      />
                      <Input
                        name="date"
                        isDisabled={isOpenEdit}
                        isRequired
                        min={format(minDate, 'yyyy-MM-dd')}
                        max={format(outputMaxDate, 'yyyy-MM-dd')}
                        type="date"
                        label={t('pageMovements.c1.i2')}
                        value={date}
                        onChange={(event) => setDate(event.target.value)}
                      />
                      <TextFieldControlled
                        name="quantity"
                        isRequired
                        isDisabled={isOpenEdit}
                        label={t('pageMovements.c1.i3')}
                        _hover={{ animationName: 'default' }}
                        textAlign="left"
                        mask="number"
                        placeholder={t('pageMovements.placeholder.quantity')}
                        initialValue={quantity}
                        inputOnChange={(event) =>
                          setQuantity(event.currentTarget.value)
                        }
                      />
                      <SelectComponentAsync
                        name="postingId"
                        title={t('pageMovements.c1.i7')}
                        isDisabled={isOpenEdit}
                        loadOptions={(search: string) => getAttendance(search)}
                        placeholder={t('pageMovements.placeholder.attendance')}
                        value={selectedAttendance}
                        onChange={(event: any) => {
                          setSelectedAttendance(event)
                        }}
                      />
                    </Stack>
                    <Flex w="100%" justifyContent="flex-end">
                      <PermissionComponent ipCode="CREATE" spiCode="PRODUCTS">
                        <Button
                          isLoading={isLoading}
                          isDisabled={isOpenEdit}
                          size="sm"
                          color="white"
                          bg="blue.300"
                          onClick={() => {
                            setIsLoading(true)
                            handleMovementsItem()
                          }}
                        >
                          {t('pageMovements.addMove')}
                        </Button>
                      </PermissionComponent>
                    </Flex>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack w="100%" spacing="6">
                    <Stack direction="row">
                      <Input
                        name="date"
                        isDisabled={isOpenEdit}
                        min={format(minDate, 'yyyy-MM-dd')}
                        max={format(maxDate, 'yyyy-MM-dd')}
                        isRequired
                        type="date"
                        label={t('pageMovements.c1.i2')}
                        value={date}
                        onChange={(event) => setDate(event.target.value)}
                      />
                      <TextFieldControlled
                        name="quantity"
                        isRequired
                        isDisabled={isOpenEdit}
                        label={t('pageMovements.c1.i3')}
                        textAlign="left"
                        mask="number"
                        placeholder={t('pageMovements.placeholder.quantity')}
                        initialValue={quantity}
                        inputOnChange={(event) =>
                          setQuantity(event.currentTarget.value)
                        }
                      />
                      <TextFieldControlled
                        name="valueUnit"
                        isDisabled={selectInput?.value === 2}
                        isRequired
                        label={t('pageMovements.c1.i4')}
                        mask={
                          currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                        }
                        initialValue={valueUnit}
                        inputOnChange={(event) =>
                          setValueUnit(event.currentTarget.value)
                        }
                      />
                    </Stack>
                    <Flex w="100%" justifyContent="flex-end">
                      <PermissionComponent ipCode="CREATE" spiCode="PRODUCTS">
                        <Button
                          isLoading={isLoading}
                          isDisabled={isOpenEdit}
                          size="sm"
                          color="white"
                          bg="blue.300"
                          onClick={() => {
                            setIsLoading(true)
                            handleMovementsItem()
                          }}
                        >
                          {t('pageMovements.addMove')}
                        </Button>
                      </PermissionComponent>
                    </Flex>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardContainer>
          <CardContainer title={t('pageMovements.c2.title')}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>{t('pageMovements.c2.move')}</Th>
                  <Th>{t('pageMovements.c2.eventDate')}</Th>
                  <Th>{t('pageMovements.c2.movementDate')}</Th>
                  <Th>{t('pageMovements.c2.units')}</Th>
                  <Th>{t('pageMovements.c2.amountUnit')}</Th>
                  {/* <Th>{t('pageMovements.c2.stock')}</Th>
                  <Th>{t('pageMovements.c2.quantity')}</Th>
                  <Th>{t('pageMovements.c2.cost')}</Th> */}
                  <Th>{t('pageMovements.c2.vincule')}</Th>
                  <Th>{t('pageMovements.c2.actions')}</Th>
                </Tr>
              </Thead>
              {movements?.movements !== undefined && !isLoadingMoves && (
                <Tbody>
                  {movements?.movements?.map((item) => (
                    <Tr key={item?.id}>
                      {item?.type === 1 && (
                        <Td>
                          <Flex color="green.500">
                            <Heading size="xs" noOfLines={1}>
                              {t('pageMovements.c1.input')}
                            </Heading>
                          </Flex>
                        </Td>
                      )}
                      {item?.type === 2 && (
                        <Td>
                          <Flex color="red.500">
                            <Heading size="xs" noOfLines={1}>
                              {t('pageMovements.c1.output')}
                            </Heading>
                          </Flex>
                        </Td>
                      )}
                      {item?.type === 3 && item?.created_by_system === false && (
                        <Td>
                          <Flex color="yellow.500">
                            <Heading size="xs" noOfLines={1}>
                              {t('pageMovements.c1.cost')}
                            </Heading>
                          </Flex>
                        </Td>
                      )}
                      {item?.type === 3 && item?.created_by_system === true && (
                        <Td bg="red.500">
                          <Flex color="white">
                            <Heading size="xs" noOfLines={1}>
                              {t('pageMovements.controlLine')}
                            </Heading>
                          </Flex>
                        </Td>
                      )}
                      <Td>
                        {format(
                          new Date(item?.event_date || new Date()),
                          'dd/MM/yyyy',
                          {
                            locale: LocaleApp
                          }
                        )}
                      </Td>
                      <Td>
                        {format(
                          new Date(item.movement_datetime),
                          'dd/MM/yyyy',
                          {
                            locale: LocaleApp
                          }
                        )}
                      </Td>
                      <Td textAlign="left">{item?.units || '0'}</Td>
                      <Td textAlign="left">
                        {item?.type !== 2 ? formatBrOrDollarDefault(
                          item?.unit_value?.toFixed(2)
                        ) || '0' : '---'}
                      </Td>
                      {/* <Td textAlign="right">{item?.balance_units || '0'}</Td>
                      <Td textAlign="right">
                        {formatBrOrDollarDefault(
                          item?.balance_value?.toFixed(2)
                        ) || '0'}
                      </Td>
                      <Td textAlign="right">
                        {formatBrOrDollarDefault(
                          item?.average_cost?.toFixed(2)
                        ) || '0'}
                      </Td> */}
                      <Td>
                        {item?.attendance && (
                          <Tooltip
                            placement="left"
                            label={`${item?.attendance?.guide} - ${
                              item?.attendance?.patient_name
                            } - ${item?.attendance?.specialist_name} - ${format(
                              new Date(
                                item?.attendance?.scheduled_arrival_time
                              ),
                              'dd/MM/yyyy',
                              {
                                locale: LocaleApp
                              }
                            )} ${hoursFormat(
                              item?.attendance?.scheduled_arrival_time
                            )}`}
                            aria-label={`${item?.attendance?.guide} - ${
                              item?.attendance?.patient_name
                            } - ${item?.attendance?.specialist_name} - ${format(
                              new Date(
                                item?.attendance?.scheduled_arrival_time
                              ),
                              'dd/MM/yyyy',
                              {
                                locale: LocaleApp
                              }
                            )} ${hoursFormat(
                              item?.attendance?.scheduled_arrival_time
                            )}`}
                          >
                            <Box>
                              <Text _hover={{ cursor: 'default' }}>
                                {item?.attendance?.guide}
                              </Text>
                            </Box>
                          </Tooltip>
                        )}
                        {item?.posting && (
                          <Tooltip
                            placement="left"
                            label={`${item?.posting?.code} - ${
                              item?.posting?.name
                            } - ${item?.posting?.share_number} ${t('of')} ${
                              item?.posting?.total_of_postings
                            }`}
                            aria-label={`${item?.posting?.code} - ${
                              item?.posting?.name
                            } - ${item?.posting?.share_number} ${t('of')} ${
                              item?.posting?.total_of_postings
                            }`}
                          >
                            <Box>
                              <Text _hover={{ cursor: 'default' }}>
                                {item.posting?.code}
                              </Text>
                            </Box>
                          </Tooltip>
                        )}
                      </Td>
                      <Td>
                        <PermissionComponent ipCode="EDIT" spiCode="PRODUCTS">
                          {!item?.created_by_system && (
                            <Tooltip label="Editar" aria-label="Editar">
                              <Box
                                onClick={() => {
                                  if (item?.type_movement) {
                                    optionsOutputs?.forEach((op) => {
                                      if (op.value === item?.type_movement) {
                                        setSelectOutput(op)
                                      }
                                    })
                                  }
                                  setDate(
                                    item?.movement_datetime?.substring(0, 10)
                                  )
                                  setValueUnit(
                                    formatBrOrDollarDefault(
                                      item?.unit_value.toFixed(2) || ''
                                    )
                                  )
                                  if (item?.attendance) {
                                    setSelectedAttendance({
                                      label: `${item?.attendance?.guide} - ${
                                        item?.attendance?.patient_name
                                      } - ${
                                        item?.attendance?.specialist_name
                                      } - ${format(
                                        new Date(
                                          item?.attendance?.scheduled_arrival_time
                                        ),
                                        'dd/MM/yyyy',
                                        {
                                          locale: LocaleApp
                                        }
                                      )} ${hoursFormat(
                                        item?.attendance?.scheduled_arrival_time
                                      )}`,
                                      value: item?.attendance?.id
                                    })
                                  }
                                  if (item?.posting) {
                                    setSelectedPosting({
                                      value: item?.posting?.id,
                                      label: `${item?.posting?.code} - ${
                                        item?.posting?.name
                                      } - ${item?.posting?.share_number} ${t(
                                        'of'
                                      )} ${item?.posting?.total_of_postings}`
                                    })
                                  }
                                  setQuantity(String(item?.units) || '')
                                  setTypeEdit(item?.type)
                                  setMovementId(item?.id)
                                  setSaveEventMove(item?.event_date)
                                  setIsOpenEdit(true)
                                }}
                              >
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          )}
                        </PermissionComponent>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
            {!movements && !isLoadingMoves && <NotFound />}
            {isLoadingMoves && (
              <Flex p="4" w="100%" justifyContent="center" alignItems="center">
                <Spinner color="blue.300" />
              </Flex>
            )}
            {!isLoadingMoves && (
              <Pagination
                totalCountOfRegisters={movements?.totalCountOfRegisters || 0}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
          </CardContainer>
          <Flex w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalArrivedTime
        title="Edição de movimento"
        isOpen={isOpenEdit}
        onClose={() => {
          setIsOpenEdit(false)
          setSelectInput(optionsInputs[0])
          setDate(
            format(new Date(), 'yyyy-MM-dd', {
              locale: LocaleApp
            })
          )
          setSelectOutput(null)
          setQuantity('')
          setValueUnit('')
          setSelectedPosting(null)
          setSelectedAttendance(null)
        }}
        onUpdate={async () => {
          await handleMovementsItemEdit()
        }}
        isLarge
        isLoading={isLoadingEdit}
      >
        {typeEdit === 1 && (
          <Stack w="100%" h="200px" spacing="6">
            <Stack direction="row">
              <Input
                name="date"
                min={format(minDate, 'yyyy-MM-dd')}
                max={maxLengthEditDateMovement || format(maxDate, 'yyyy-MM-dd')}
                isRequired
                type="date"
                label={t('pageMovements.c1.i2')}
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
              <TextFieldControlled
                name="quantity"
                isRequired
                label={t('pageMovements.c1.i3')}
                textAlign="left"
                mask="number"
                placeholder={t('pageMovements.placeholder.quantity')}
                initialValue={quantity}
                inputOnChange={(event) =>
                  setQuantity(event.currentTarget.value)
                }
              />
              <TextFieldControlled
                name="valueUnit"
                isDisabled={selectInput?.value === 2}
                isRequired
                label={t('pageMovements.c1.i4')}
                mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                initialValue={valueUnit}
                inputOnChange={(event) =>
                  setValueUnit(event.currentTarget.value)
                }
              />
              <SelectComponentAsync
                name="billsToPayId"
                title={t('pageMovements.c1.i1')}
                loadOptions={(code: string) => getBillsToPay(code)}
                placeholder={t('pageMovements.placeholder.posting')}
                value={selectedPosting}
                onChange={(event: any) => {
                  setSelectedPosting(event)
                }}
              />
            </Stack>
          </Stack>
        )}
        {typeEdit === 2 && (
          <Stack w="100%" h="200px" spacing="6">
            <Stack direction="row">
              <SelectComponent
                name="typeOutput"
                isRequired
                title={t('pageMovements.c1.i5')}
                options={optionsOutputs}
                value={selectOutput}
                onChange={(event: any) => setSelectOutput(event)}
                placeholder={t('pageMovements.placeholder.selectOutput')}
              />
              <Input
                name="date"
                isRequired
                min={format(minDate, 'yyyy-MM-dd')}
                max={maxLengthEditDateMovement || format(outputMaxDate, 'yyyy-MM-dd')}
                type="date"
                label={t('pageMovements.c1.i2')}
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
              <TextFieldControlled
                name="quantity"
                isRequired
                label={t('pageMovements.c1.i3')}
                textAlign="left"
                mask="number"
                placeholder={t('pageMovements.placeholder.quantity')}
                initialValue={quantity}
                inputOnChange={(event) =>
                  setQuantity(event.currentTarget.value)
                }
              />
              <SelectComponentAsync
                name="postingId"
                title={t('pageMovements.c1.i7')}
                loadOptions={(search: string) => getAttendance(search)}
                placeholder={t('pageMovements.placeholder.attendance')}
                value={selectedAttendance}
                onChange={(event: any) => {
                  setSelectedAttendance(event)
                }}
              />
            </Stack>
          </Stack>
        )}
        {typeEdit === 3 && (
          <Stack w="100%" h="200px" spacing="6">
            <Stack direction="row">
              <Input
                name="date"
                min={format(minDate, 'yyyy-MM-dd')}
                max={maxLengthEditDateMovement || format(maxDate, 'yyyy-MM-dd')}
                isRequired
                type="date"
                label={t('pageMovements.c1.i2')}
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
              <TextFieldControlled
                name="quantity"
                isRequired
                label={t('pageMovements.c1.i3')}
                textAlign="left"
                mask="number"
                placeholder={t('pageMovements.placeholder.quantity')}
                initialValue={quantity}
                inputOnChange={(event) =>
                  setQuantity(event.currentTarget.value)
                }
              />
              <TextFieldControlled
                name="valueUnit"
                isDisabled={selectInput?.value === 2}
                isRequired
                label={t('pageMovements.c1.i4')}
                mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                initialValue={valueUnit}
                inputOnChange={(event) =>
                  setValueUnit(event.currentTarget.value)
                }
              />
            </Stack>
          </Stack>
        )}
      </ModalArrivedTime>
    </>
  )
}
