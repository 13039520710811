/* eslint-disable camelcase */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-destructuring */
import React, { useState } from 'react'
import { mask } from '../../utils/mask'

type SessionData = {
  duration: number | string
  time_to_be_there: any
  session_number: any
}

type DocumentData = {
  id: number
  document: any
  actionDoc: any
  checkColect?: any
}

type TagsData = {
  value: number
  label: string
}

type DocsData = {
  value: number
  label: string
}

export const useLogicServicer = () => {
  const [nameService, setNameService] = useState('')
  const [daysToComeBack, setDaysToComeBack] = useState('')
  const [dependencies, setDependencies] = useState('')
  const [price, setPrice] = useState('')
  const [coast, setCoast] = useState('')
  const [daysToMaturity, setDaysToMaturity] = useState('')
  const [serviceSessions, setServiceSessios] = useState<SessionData[] | any>([
    {
      duration: '',
      time_to_be_there: '',
      session_number: 1
    }
  ])
  const [documents, setDocuments] = useState<DocumentData[] | any>([
    { id: 1, document: {}, actionDoc: {}, checkColect: '' }
  ])
  const [tagsSelect, setTagsSelect] = useState<TagsData | null>(null)
  const [docsSelect, setDocsSelect] = useState<DocsData[]>([])
  const [getValuesSelectEdit, setGetValuesSelectEdit] = useState(false)
  const [getValuesDocSelectEdit, setGetValuesDocSelectEdit] = useState(false)

  // functions para campos de sessao
  function handleAddFieldServicesSessions() {
    const values: any = [...serviceSessions]
    const legthAux = values.length - 1

    // pega o valor do ultimo
    setServiceSessios([
      ...serviceSessions,
      {
        duration: values[legthAux].duration,
        time_to_be_there: values[legthAux].time_to_be_there,
        session_number: values[legthAux].session_number + 1
      }
    ])
  }

  function onRemoveFieldServicesSessions(index: number) {
    const values = [...serviceSessions]
    values.splice(index, 1)

    // reordenando o numero das sessoes
    const valuesNewNumber = values.map((value, i) => {
      return {
        duration: value.duration,
        time_to_be_there: value.time_to_be_there,
        session_number: i + 1
      }
    })

    setServiceSessios(valuesNewNumber)
  }

  // function get tags value
  const getEventMultiSelect = (event: any) => {
    setTagsSelect(event)
  }

  // function get docs value
  const getEventMultiSelectDocs = (event: any) => {
    setDocsSelect(event)
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const values: any = [...serviceSessions]
    values[index][event.target.name] = mask(event.target.value, '999')

    setServiceSessios(values)
  }

  // function fields documents
  function handleAddFieldDocumentsSessions() {
    const values: any = [...documents]
    const legthAux = values.length - 1

    setDocuments([
      ...documents,
      {
        id: values[legthAux].id + 1,
        document: {},
        actionDoc: {},
        checkColect: ''
      }
    ])
  }

  function onRemoveFieldDocumentsSessions(index: number) {
    const values = [...documents]
    values.splice(index, 1)

    setDocuments(values)
  }

  function handleSelectOptionDocument(e: any, index: number, name: string) {
    const values: any = [...documents]
    values[index][name] = e

    setServiceSessios(values)
  }

  function handleCheckboxDocument(e: any, index: number, name: string) {
    const values: any = [...documents]
    values[index][name] = e[0] || ''
    setServiceSessios(values)
  }

  function handleUploadDocument(e: any, index: number) {
    const values: any = [...documents]
    values[index].document = e[0] || ''

    console.log(values)
    setServiceSessios(values)
  }

  return {
    serviceSessions,
    handleAddFieldServicesSessions,
    handleInputChange,
    onRemoveFieldServicesSessions,
    documents,
    handleAddFieldDocumentsSessions,
    getEventMultiSelectDocs,
    onRemoveFieldDocumentsSessions,
    handleSelectOptionDocument,
    handleCheckboxDocument,
    setGetValuesDocSelectEdit,
    getValuesDocSelectEdit,
    handleUploadDocument,
    setServiceSessios,
    setNameService,
    nameService,
    setPrice,
    daysToComeBack,
    daysToMaturity,
    setDaysToComeBack,
    setDaysToMaturity,
    getEventMultiSelect,
    tagsSelect,
    setTagsSelect,
    price,
    setGetValuesSelectEdit,
    setDocsSelect,
    docsSelect,
    getValuesSelectEdit,
    dependencies,
    setDependencies,
    coast,
    setCoast
  }
}
