/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type FormsCreateData = {
  name: string
  id: string
  description: string
  status: number
}

export type ResponseGetFormsCreateData = {
  forms: FormsCreateData[]
  totalCountOfPage: number
}

export const getFormsCreate = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetFormsCreateData> => {
  try {
    const { data } = await apiAuth.get(`clinics/forms/`, {
      params: {
        page,
        name: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const forms: FormsCreateData[] = data.results

    return {
      forms,
      totalCountOfPage
    }
  } catch (error: any) {
    const forms: FormsCreateData[] = []
    const totalCountOfPage = 0
    return {
      forms,
      totalCountOfPage
    }
  }
}

export function useFormsCreate(
  page: number,
  functionLoading: Promise<ResponseGetFormsCreateData>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['createForms', page, search, isActive],
    () => functionLoading
  )
}
